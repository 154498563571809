import { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from 'hooks';
import { useToasts } from 'react-toast-notifications';

export const initialState = {
  user: null,
  isAuthenticated:
    !!localStorage.getItem('token') && !!localStorage.getItem('viewerId'),
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'signin':
      return { ...state, user: action.payload, isAuthenticated: true };
    case 'signout':
      return { ...state, user: null, isAuthenticated: false };
    default:
      return state;
  }
};

export const AccountContext = createContext();
export const AccountDispatchContext = createContext();

export const AccountProvider = ({ children }) => {
  const { addToast } = useToasts();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getUser] = useLazyQuery({
    url: `/users/${localStorage.getItem('viewerId')}`,
    onCompleted: (data) => dispatch({ type: 'signin', payload: data }),
    onError: (err) => {
      if (err === 'Failed to fetch') {
        addToast('No se ha podido conectar con el servidor', {
          appearance: 'error',
          autoDismiss: false,
        });
      }
    },
  });

  useEffect(() => {
    if (state.isAuthenticated && !state.user) {
      getUser();
    }
  }, [state]);

  return (
    <AccountContext.Provider value={state}>
      <AccountDispatchContext.Provider value={dispatch}>
        {children}
      </AccountDispatchContext.Provider>
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};

export const useAccountDispatch = () => {
  const context = useContext(AccountDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAccountDispatch must be used within an AccountProvider',
    );
  }
  return context;
};

import { useEffect, useRef, useState, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHotkeys } from 'react-hotkeys-hook';
import { useQuery } from 'hooks';
import { CircularProgress } from 'components';
import { CheckIcon, XIcon } from '@primer/octicons-react';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

export default function LabelPicker({ value, onChange }) {
  const { addToast } = useToasts();
  const [open, setOpen] = useState(false);
  const [search, setSeach] = useState('');
  const [debouncedSearch] = useDebounce(search, 250);
  const searchInput = useRef();

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [addToast],
  );

  const { data, loading, error } = useQuery({
    url: '/labels',
    variables: {
      page: 1,
      limit: 100,
      sortBy: 'name:asc',
      name:
        debouncedSearch.trim().length > 0 ? debouncedSearch.trim() : undefined,
    },
    skip: open === false,
    onError,
  });

  useHotkeys('esc', () => setOpen(false));

  useEffect(() => {
    if (open && searchInput.current) {
      searchInput.current.focus();
    }
  }, [open, searchInput.current]);

  if (error) return null;

  return (
    <details
      open={open}
      className="details-reset details-overlay position-relative"
    >
      <summary
        role="button"
        className="btn btn-block text-left text-normal"
        aria-haspopup="menu"
        onClick={(event) => {
          event.preventDefault();
          setOpen((prev) => !prev);
        }}
      >
        <div className="d-flex flex-items-center">
          {value && (
            <span
              className="ColorSwatch f5 m-0 mr-2 flex-shrink-0"
              style={{ marginTop: 3, backgroundColor: value.color }}
            />
          )}
          <div className="flex-1 lh-condensed css-truncate min-width-0">
            <div className="css-truncate-overflow">
              {value?.name || 'Ninguna'}
            </div>
            {value?.description && (
              <div className="css-truncate-overflow color-fg-muted note m-0">
                {value.description}
              </div>
            )}
          </div>{' '}
          <span className="dropdown-caret hide-sm" />
        </div>
      </summary>
      <div role="menu" className="SelectMenu SelectMenu--hasFilter">
        <div className="SelectMenu-modal">
          <header className="SelectMenu-header">
            <span className="SelectMenu-title">Seleccionar etiqueta</span>
            <button
              type="button"
              className="SelectMenu-closeButton"
              onClick={() => setOpen(false)}
            >
              <XIcon />
            </button>
          </header>
          <div className="SelectMenu-filter">
            <input
              ref={searchInput}
              type="search"
              className="SelectMenu-input form-control"
              placeholder="Filtrar etiquetas"
              aria-label="Filtrar etiquetas"
              value={search}
              onChange={({ target }) => setSeach(target.value)}
              autoComplete="off"
              spellCheck={false}
              autoFocus
            />
          </div>
          <div className="SelectMenu-list select-menu-list">
            {!loading && (
              <div
                role="menuitemradio"
                className="SelectMenu-item d-flex flex-items-start"
                aria-checked={value === undefined}
                onClick={() => {
                  onChange(undefined);
                  setOpen(false);
                }}
              >
                <CheckIcon className="SelectMenu-icon SelectMenu-icon--check" />
                <div className="lh-condensed css-truncate min-width-0">
                  <div className="css-truncate-overflow text-bold">Ninguna</div>
                </div>
              </div>
            )}
            {loading || !data ? (
              <div className="blankspace d-flex flex-items-center flex-justify-center p-4">
                <CircularProgress />
              </div>
            ) : (
              data.results
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((label) => (
                  <div
                    key={label.id}
                    role="menuitemradio"
                    className="SelectMenu-item d-flex flex-items-start"
                    aria-checked={value?.id === label.id}
                    onClick={() => {
                      onChange(label);
                      setOpen(false);
                    }}
                  >
                    <CheckIcon className="SelectMenu-icon SelectMenu-icon--check" />
                    <span
                      className="ColorSwatch f5 mr-2 flex-shrink-0"
                      style={{ marginTop: 3, backgroundColor: label.color }}
                    />
                    <div className="lh-condensed css-truncate min-width-0">
                      <div className="css-truncate-overflow text-bold">
                        {label.name}
                      </div>
                      {label.description && (
                        <div className="css-truncate-overflow color-fg-muted mt-1">
                          {label.description}
                        </div>
                      )}
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
    </details>
  );
}

LabelPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

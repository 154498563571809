import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { UserAvatar } from 'components';
import { PencilIcon } from '@primer/octicons-react';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'hooks';

export default function ProfileSettingsRoute({ user, refetch }) {
  const { addToast } = useToasts();
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [uploading, setUploading] = useState(false);

  const onError = useCallback((error) =>
    addToast(error, { appearance: 'error', autoDismiss: true }),
  );

  const onCompleted = useCallback(() => {
    setUploading(false);
    addToast('Perfil actualizado', {
      appearance: 'success',
      autoDismiss: true,
    });
    refetch();
  });

  const [updateUser, { loading }] = useMutation({
    url: `/users/${user.id}`,
    options: { method: 'PATCH' },
    onCompleted,
    onError,
  });

  const [generateUploadUrl] = useMutation({
    url: '/upload',
    options: { method: 'POST' },
    onError,
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    updateUser({
      name,
      email,
    });
  };

  const handleFileChage = async (event) => {
    event.preventDefault();
    setUploading(true);
    const file = event.target.files[0];
    const uploadUrl = await generateUploadUrl({ contentType: file.type });
    const formData = new FormData();
    formData.append('Content-Type', file.type);
    Object.entries(uploadUrl.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file);
    try {
      const res = await fetch(uploadUrl.url, {
        method: 'POST',
        body: formData,
      });
      const location = res.headers.get('Location');
      updateUser({
        avatarURL: decodeURIComponent(location),
      });
    } catch (err) {
      addToast('Error al subir el archivo', {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className="container-md">
          <div className="Subhead hx_Subhead--responsive">
            <h2 className="Subhead-heading">Perfil público</h2>
          </div>
          <div className="clearfix gutter d-flex flex-shrink-0 flex-column-reverse flex-md-row">
            <div className="col-12 col-md-8">
              <div className="form-group mt-0">
                <div className="form-group-header">
                  <label htmlFor="full-name">Nombre y apellidos</label>
                </div>
                <div className="form-group-body">
                  <input
                    type="text"
                    className="form-control"
                    id="full-name"
                    placeholder="Nombre y apellidos"
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                    autoComplete="off"
                    spellCheck={false}
                    disabled={loading}
                    required
                  />
                  <div className="note">
                    Su nombre puede aparecer en Wallet en las organizaciones o
                    equipos a los que pertenezca.
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-header">
                  <label htmlFor="email">Dirección de correo</label>
                </div>
                <div className="form-group-body">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Dirección de correo"
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                    autoComplete="off"
                    spellCheck={false}
                    disabled={loading}
                    required
                  />
                  <div className="note">
                    Usaremos esta dirección para enviarle notificaciones o
                    solicitudes por correo electrónico.
                  </div>
                </div>
              </div>
              <p className="">
                <button
                  type="submit"
                  className="btn-primary btn"
                  disabled={
                    loading || (name === user.name && email === user.email)
                  }
                >
                  {loading ? 'Actualizando perfil…' : 'Actualizar perfil'}
                </button>
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group mt-0">
                <div className="form-group-header">
                  <label htmlFor="profile-picture">Foto de perfil</label>
                </div>
                <div className="form-group-body">
                  <div className="avatar-upload">
                    <details className="dropdown details-reset details-overlay">
                      <summary role="button" aria-haspopup="menu">
                        <UserAvatar
                          url={user.avatarURL}
                          username={user.name}
                          size="settings"
                          hideOnlineIndicator
                        />
                        <div className="position-absolute color-bg-default rounded-2 color-fg-default px-2 py-1 left-0 bottom-0 ml-2 mb-2 border">
                          <PencilIcon /> Editar
                        </div>
                      </summary>
                      <div
                        role="menu"
                        className="dropdown-menu dropdown-menu-se"
                      >
                        <label
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                          role="menuitem"
                          htmlFor="avatar_upload"
                          className="dropdown-item text-normal"
                          style={{ cursor: 'pointer' }}
                        >
                          {uploading
                            ? 'Subiendo foto…'
                            : 'Subir foto de perfil…'}
                        </label>
                        {user.avatarURL && (
                          <button
                            type="button"
                            className="btn-link dropdown-item"
                            disabled={uploading || loading}
                            onClick={() => updateUser({ avatarURL: null })}
                          >
                            {loading ? 'Eliminando foto…' : 'Eliminar foto'}
                          </button>
                        )}
                      </div>
                    </details>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <input
        type="file"
        id="avatar_upload"
        className="manual-file-chooser width-full ml-0"
        accept="image/*"
        onChange={handleFileChage}
        disabled={uploading || loading}
        hidden
      />
    </>
  );
}

ProfileSettingsRoute.propTypes = {
  user: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

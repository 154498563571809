import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { SearchIcon } from '@primer/octicons-react';
import { useToasts } from 'react-toast-notifications';
import { useQuery } from 'hooks';
import { Pagination, TeamListItem } from 'components';
import { Helmet } from 'react-helmet-async';
import { useDebounce } from 'use-debounce';

export default function TeamListRoute() {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 250);
  const { addToast } = useToasts();
  const [page, setPage] = useState(1);
  const [sortFilter] = useState('name:asc');

  useEffect(() => {
    setPage(1);
  }, [debouncedSearch]);

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [addToast],
  );

  const { data, loading, error } = useQuery({
    url: '/teams',
    variables: {
      page,
      limit: 20,
      sortBy: sortFilter,
      name: debouncedSearch.trim().length ? debouncedSearch.trim() : undefined,
    },
    onError,
  });

  if (error) {
    return null;
  }

  return (
    <div className="flex-1 py-3">
      <Helmet>
        <title>Equipos • {process.env.REACT_APP_NAME} Wallet</title>
      </Helmet>
      <div className="container-lg p-responsive">
        <div className="subnav d-flex mb-3 flex-row">
          <div className="flex-auto min-width-0">
            <div className="subnav-search ml-0">
              <SearchIcon className="subnav-search-icon" />
              <input
                type="search"
                className="form-control subnav-search-input subnav-search-input-wide width-full"
                placeholder="Buscar equipos por su nombre…"
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                spellCheck={false}
                autoFocus
              />
            </div>
          </div>
          <div className="ml-2">
            <Link to="/teams/new" className="btn btn-primary">
              Nuevo <span className="d-none d-sm-inline-block">equipo</span>
            </Link>
          </div>
        </div>
        <div className="Box mt-3 hx_Box--firstRowRounded0">
          <div className="Box-header d-flex flex-justify-between">
            <h3 className="Box-title">Equipos</h3>
          </div>
          <div className="team-list">
            {!loading && !data?.results?.length && (
              <div className="bg-primary blankslate blankslate-spacious text-center position-relative rounded-2">
                <img
                  src="https://ghicons.github.com/assets/images/blue/png/Code%20hosting.png"
                  alt="Workplace icon"
                  className="d-block mb-3 mx-auto grayscale"
                  draggable={false}
                />
                <h3 className="mb-1 color-fg-muted">
                  No hay resultados que coincidan con su búsqueda
                </h3>
                <p className="container-sm color-fg-muted">
                  Los equipos son grupos de miembros de la organización que
                  reflejan la estructura de su empresa o grupos con permisos de
                  acceso.
                </p>
              </div>
            )}
            {Array.from(loading ? new Array(5) : data.results).map(
              (team, index) => (
                <TeamListItem
                  key={loading ? `team-${index}` : team.id}
                  loading={loading}
                  team={team}
                />
              ),
            )}
          </div>
        </div>
        {!loading && data && (
          <Pagination
            pageInfo={{ currentPage: data.page, pageCount: data.totalPages }}
            onPageChange={setPage}
          />
        )}
      </div>
    </div>
  );
}

/* eslint-disable no-continue */
import { useMemo } from 'react';
import PropTypes from 'prop-types';

export default function Pagination({
  pageInfo,
  onPageChange,
  marginPageCount = 1,
  surroundingPageCount = 2,
}) {
  const { currentPage, pageCount } = pageInfo;
  const hasNextPage = currentPage < pageCount;
  const hasPreviousPage = currentPage > 1;

  const RANGE_DISPLAYED = useMemo(
    () => surroundingPageCount * 2 + 1,
    [surroundingPageCount],
  );
  const MARGIN_DISPLAYED = useMemo(
    () => marginPageCount + 1,
    [marginPageCount],
  );

  const getPageElement = (index) => {
    const page = index + 1;
    return page === currentPage ? (
      <span key={page} className="mx-1" aria-current="page">
        {page}
      </span>
    ) : (
      <span
        key={page}
        role="button"
        aria-label={`Page ${page}`}
        className="mx-1"
        onClick={() => onPageChange(page)}
      >
        {page}
      </span>
    );
  };

  const getBreakElement = (index) => (
    <span key={`gap-${index}`} className="gap">
      …
    </span>
  );

  const paginate = () => {
    const items = [];
    if (pageCount < RANGE_DISPLAYED) {
      for (let index = 0; index < pageCount; index += 1) {
        items.push(getPageElement(index));
      }
    } else {
      let leftSide = RANGE_DISPLAYED / 2;
      let rightSide = RANGE_DISPLAYED - leftSide;
      if (currentPage > pageCount - RANGE_DISPLAYED / 2) {
        rightSide = pageCount - currentPage;
        leftSide = RANGE_DISPLAYED - rightSide;
      } else if (currentPage < RANGE_DISPLAYED / 2) {
        leftSide = currentPage;
        rightSide = RANGE_DISPLAYED - leftSide;
      }
      let index;
      let page;
      let breakView;

      for (index = 0; index < pageCount; index += 1) {
        page = index + 1;

        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (page <= MARGIN_DISPLAYED) {
          items.push(getPageElement(index));
          continue;
        }

        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (page > pageCount - MARGIN_DISPLAYED) {
          items.push(getPageElement(index));
          continue;
        }

        // If the page index is near the selected page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (page >= currentPage - leftSide && page <= currentPage + rightSide) {
          items.push(getPageElement(index));
          continue;
        }

        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (items[items.length - 1] !== breakView) {
          breakView = getBreakElement();
          items.push(breakView);
        }
      }
    }

    return items;
  };

  if (pageCount < 2) return null;

  return (
    <div className="paginate-container d-flex flex-justify-center">
      <div role="navigation" className="pagination" aria-label="Paginación">
        <span
          role="button"
          className="previous_page mr-1"
          aria-disabled={!hasPreviousPage}
          onClick={
            hasPreviousPage ? () => onPageChange(currentPage - 1) : undefined
          }
        >
          Anterior
        </span>
        {paginate()}
        <span
          role="button"
          className="next_page ml-1"
          aria-disabled={!hasNextPage}
          onClick={
            hasNextPage ? () => onPageChange(currentPage + 1) : undefined
          }
        >
          Siguiente
        </span>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  pageInfo: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  marginPageCount: PropTypes.number,
  surroundingPageCount: PropTypes.number,
};

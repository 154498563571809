/* eslint-disable indent */
import { useCallback, useMemo } from 'react';
import { useQuery } from 'hooks';
import { Helmet } from 'react-helmet-async';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useAccount } from 'context/account';
import { CircularProgress } from 'components';
import moment from 'moment';
import {
  BriefcaseIcon,
  GearIcon,
  KeyIcon,
  PeopleIcon,
  TagIcon,
} from '@primer/octicons-react';

import OrganizationCredentialsRoute from './credentials.route';
import OrganizationLabelsRoute from './labels.route';
import OrganizationSettingsRoute from './settings.route';
import OrganizationAccessRoute from './access.route';

export default function OrganizationViewRoute() {
  const routeParams = useParams();
  const { addToast } = useToasts();
  const { user: viewer } = useAccount();

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [addToast],
  );

  const { data, loading, error, refetch } = useQuery({
    url: `/organizations/${routeParams.organizationId}`,
    skip: !routeParams.organizationId,
    onError,
  });

  const membersLength = useMemo(() => {
    if (data?.organization) {
      return [
        ...data.organization.users.map((u) => u.id),
        ...data.organization.teams.flatMap((team) =>
          team.members.map((m) => m.id),
        ),
      ].reduce((acc, id) => [...acc.filter((i) => i !== id), id], []).length;
    }
    return 0;
  }, [data?.organization?.users, data?.organization?.teams]);

  if (loading) {
    return (
      <div className="d-flex flex-1 py-3">
        <div className="d-flex flex-items-center flex-justify-center flex-1 container-lg p-responsive">
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (error) {
    return null;
  }

  return (
    <div className="flex-1">
      <Helmet>
        <title>{`${data.organization.name} • ${process.env.REACT_APP_NAME} Wallet`}</title>
      </Helmet>
      <div className="color-bg-default pt-4 border-bottom">
        <div className="container-lg p-responsive">
          <div className="d-flex flex-items-center mb-2">
            <div className="d-flex flex-items-center mr-3 color-fg-muted">
              <BriefcaseIcon size="medium" />
            </div>
            <div className="flex-auto min-width-0 width-fit mr-3">
              <h3 className="f3">{data.organization.name}</h3>
              <p className="f6 color-fg-muted mb-0">
                {data.organization.description || 'Sin descripción'} • Creado{' '}
                {data.organization.author &&
                  `por ${data.organization.author.name}`}{' '}
                {moment(data.organization.createdAt).fromNow()}
              </p>
            </div>
            <ul
              className="pagehead-actions flex-shrink-0 d-none d-md-inline"
              style={{ padding: '2px 0' }}
            ></ul>
          </div>
          <nav
            className="overflow-hidden UnderlineNav"
            style={{ marginBottom: -1 }}
          >
            <ul className="UnderlineNav-body list-style-none">
              <li className="d-flex">
                <NavLink
                  exact
                  to={`/organizations/${data.organization.id}`}
                  className="UnderlineNav-item hx_underlinenav-item no-wrap"
                >
                  <KeyIcon className="UnderlineNav-octicon d-none d-sm-inline" />
                  <span>Credenciales</span>
                  <span title={data.credentialsCount} className="Counter">
                    {data.credentialsCount}
                  </span>
                </NavLink>
              </li>
              <li className="d-flex">
                <NavLink
                  to={`/organizations/${data.organization.id}/labels`}
                  className="UnderlineNav-item hx_underlinenav-item no-wrap"
                >
                  <TagIcon className="UnderlineNav-octicon d-none d-sm-inline" />
                  <span>Etiquetas</span>
                  <span title={data.labelsCount} className="Counter">
                    {data.labelsCount}
                  </span>
                </NavLink>
              </li>
              <li className="d-flex">
                <NavLink
                  to={`/organizations/${data.organization.id}/access`}
                  className="UnderlineNav-item hx_underlinenav-item no-wrap"
                >
                  <PeopleIcon className="UnderlineNav-octicon d-none d-sm-inline" />
                  <span>Miembros</span>
                  <span title={membersLength} className="Counter">
                    {membersLength}
                  </span>
                </NavLink>
              </li>
              {(viewer.role === 'admin' ||
                data.organization.author?.id === viewer.id) && (
                <li className="d-flex">
                  <NavLink
                    to={`/organizations/${data.organization.id}/settings`}
                    className="UnderlineNav-item hx_underlinenav-item no-wrap"
                  >
                    <GearIcon className="UnderlineNav-octicon d-none d-sm-inline" />
                    <span>Ajustes</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <div className="py-3">
        <div className="container-lg p-responsive">
          <Switch>
            <Route
              exact
              path="/organizations/:organizationId"
              render={(props) => (
                <OrganizationCredentialsRoute
                  {...props}
                  organization={data.organization}
                />
              )}
            />
            <Route
              path="/organizations/:organizationId/labels"
              render={(props) => (
                <OrganizationLabelsRoute
                  {...props}
                  organization={data.organization}
                />
              )}
            />
            <Route
              path="/organizations/:organizationId/access"
              render={(props) => (
                <OrganizationAccessRoute
                  {...props}
                  organization={data.organization}
                  refetch={refetch}
                />
              )}
            />
            {(viewer.role === 'admin' ||
              data.organization.author?.id === viewer.id) && (
              <Route
                path="/organizations/:organizationId/settings"
                render={(props) => (
                  <OrganizationSettingsRoute
                    {...props}
                    organization={data.organization}
                    refetch={refetch}
                  />
                )}
              />
            )}
          </Switch>
        </div>
      </div>
    </div>
  );
}

import { Route, Switch } from 'react-router-dom';

import TeamListRoute from './list.route';
import CreateTeamRoute from './create.route';
import TeamViewRoute from './view.route';

export default function TeamRoutes() {
  return (
    <Switch>
      <Route exact path="/teams" component={TeamListRoute} />
      <Route path="/teams/new" component={CreateTeamRoute} />
      <Route path="/teams/:teamId" component={TeamViewRoute} />
    </Switch>
  );
}

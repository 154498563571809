import { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const initialState = {
  isDarkMode: localStorage.getItem('theme')
    ? localStorage.getItem('theme') === 'dark'
    : false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'light':
      return { ...state, isDarkMode: false };
    case 'dark':
      return { ...state, isDarkMode: true };
    default:
      return state;
  }
};

export const ThemeContext = createContext();
export const ThemeDispatchContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ThemeContext.Provider value={state}>
      <ThemeDispatchContext.Provider value={dispatch}>
        {children}
      </ThemeDispatchContext.Provider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within an ThemeProvider');
  }
  return context;
};

export const useThemeDispatch = () => {
  const context = useContext(ThemeDispatchContext);
  if (context === undefined) {
    throw new Error('useThemeDispatch must be used within an ThemeProvider');
  }
  return context;
};

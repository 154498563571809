import { SearchIcon } from '@primer/octicons-react';
import {
  OrganizationListItem,
  OrganizationSortFilter,
  Pagination,
} from 'components';
import { useQuery } from 'hooks';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useDebounce } from 'use-debounce/lib';
import PropTypes from 'prop-types';

export default function TeamOrganizationsRoute({ team }) {
  const { addToast } = useToasts();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 250);
  const [page, setPage] = useState(1);
  const [sortFilter, setSortFilter] = useState('name:asc');

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [addToast],
  );

  const { data, loading, error } = useQuery({
    url: '/organizations',
    variables: {
      page,
      limit: 20,
      sortBy: sortFilter,
      name: debouncedSearch.trim().length ? debouncedSearch.trim() : undefined,
      teamId: team.id,
    },
    onError,
  });

  if (error) {
    return null;
  }

  return (
    <div>
      <div className="subnav d-flex mb-3 flex-row">
        <div className="flex-auto min-width-0">
          <div className="subnav-search ml-0">
            <SearchIcon className="subnav-search-icon" />
            <input
              type="search"
              className="form-control subnav-search-input subnav-search-input-wide width-full"
              placeholder="Buscar organizaciones por su nombre…"
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              spellCheck={false}
              autoFocus
            />
          </div>
        </div>
        <div className="ml-2">
          <Link to="/organizations/new" className="btn btn-primary">
            Nueva <span className="d-none d-sm-inline-block">organización</span>
          </Link>
        </div>
      </div>
      <div className="Box mt-3 hx_Box--firstRowRounded0">
        <div className="Box-header d-flex flex-justify-between">
          <h3 className="Box-title">Organizaciones</h3>
          <div className="table-list-header-toggle no-wrap d-flex flex-auto flex-justify-end">
            <OrganizationSortFilter
              value={sortFilter}
              onChange={setSortFilter}
            />
          </div>
        </div>
        <div className="organization-list">
          {!loading && !data?.results?.length && (
            <div className="bg-primary blankslate blankslate-spacious text-center position-relative rounded-2">
              <img
                src="https://ghicons.github.com/assets/images/blue/png/Code%20hosting.png"
                alt="Workplace icon"
                className="d-block mb-3 mx-auto grayscale"
                draggable={false}
              />
              <h3 className="mb-1 color-fg-muted">
                No hay resultados que coincidan con su búsqueda
              </h3>
              <p className="container-sm color-fg-muted">
                Las organizaciones son espacios de trabajo en los que puede
                almacenar de forma segura las credenciales así como detalles de
                contacto del cliente. Usted decide quién puede o no ver estos
                datos.
              </p>
            </div>
          )}
          {Array.from(loading ? new Array(5) : data.results).map(
            (organization, index) => (
              <OrganizationListItem
                key={loading ? `organization-${index}` : organization.id}
                loading={loading}
                organization={organization}
              />
            ),
          )}
        </div>
      </div>
      {!loading && data && (
        <Pagination
          pageInfo={{ currentPage: data.page, pageCount: data.totalPages }}
          onPageChange={setPage}
        />
      )}
    </div>
  );
}

TeamOrganizationsRoute.propTypes = {
  team: PropTypes.object.isRequired,
};

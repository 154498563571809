import { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchIcon } from '@primer/octicons-react';
import { useQuery } from 'hooks';
import {
  Pagination,
  OrganizationListItem,
  OrganizationSortFilter,
} from 'components';
import { useToasts } from 'react-toast-notifications';
import { Helmet } from 'react-helmet-async';
import { useDebounce } from 'use-debounce';

export default function OrganizationListRoute() {
  const { addToast } = useToasts();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 250);
  const [page, setPage] = useState(1);
  const [sortFilter, setSortFilter] = useState('name:asc');
  // eslint-disable-next-line no-unused-vars
  const [belongFilter, setBelongFilter] = useState(true);
  const searchInputRef = useRef(null);

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [addToast],
  );

  const { data, loading, error } = useQuery({
    url: '/organizations',
    variables: {
      page,
      limit: 20,
      sortBy: sortFilter,
      name: debouncedSearch.trim().length ? debouncedSearch.trim() : undefined,
      viewerHasAccess: belongFilter,
    },
    onError,
  });

  if (error) {
    return null;
  }

  return (
    <div className="flex-1 py-3">
      <Helmet>
        <title>Organizaciones • {process.env.REACT_APP_NAME} Wallet</title>
      </Helmet>
      <div className="container-lg p-responsive">
        <div className="subnav d-flex flex-md-row flex-column mb-3">
          <div className="d-flex flex-justify-between">
            {/* <nav className="subnav-links float-left d-flex no-wrap">
              <button
                type="button"
                className="subnav-item"
                // aria-label="Mostar organizaciones a las que tengo acceso"
                aria-current={belongFilter ? 'page' : null}
                onClick={() => {
                  setBelongFilter(true);
                  searchInputRef.current.focus();
                }}
              >
                Pertenezco
              </button>
              <button
                type="button"
                className="subnav-item"
                // aria-label="Mostrar organizaciones a las que no tengo acceso"
                aria-current={!belongFilter ? 'page' : null}
                onClick={() => {
                  setBelongFilter(false);
                  searchInputRef.current.focus();
                }}
              >
                No pertenezco
              </button>
            </nav> */}
            <div className="position-relative d-md-none d-inline-block">
              <Link to="/organizations/new" className="btn btn-primary">
                Nueva{' '}
                <span className="d-none d-sm-inline-block">organización</span>
              </Link>
            </div>
          </div>
          <div className="flex-auto min-width-0 pl-0 mt-md-0 mt-3">
            <div className="subnav-search ml-0">
              <SearchIcon className="subnav-search-icon" />
              <input
                ref={searchInputRef}
                type="search"
                className="form-control subnav-search-input subnav-search-input-wide width-full"
                placeholder="Buscar organizaciones por su nombre…"
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                spellCheck={false}
                autoFocus
              />
            </div>
          </div>
          <div className="ml-2 d-md-block d-none">
            <Link to="/organizations/new" className="btn btn-primary">
              Nueva{' '}
              <span className="d-none d-sm-inline-block">organización</span>
            </Link>
          </div>
        </div>
        <div className="Box mt-3 hx_Box--firstRowRounded0">
          <div className="Box-header d-flex flex-justify-between">
            <h3 className="Box-title">Organizaciones</h3>
            <div className="table-list-header-toggle no-wrap d-flex flex-auto flex-justify-end">
              <OrganizationSortFilter
                value={sortFilter}
                onChange={setSortFilter}
              />
            </div>
          </div>
          <div className="organization-list">
            {!loading && !data?.results?.length && (
              <div className="bg-primary blankslate blankslate-spacious text-center position-relative rounded-2">
                <img
                  src="https://ghicons.github.com/assets/images/blue/png/Code%20hosting.png"
                  alt="Workplace icon"
                  className="d-block mb-3 mx-auto grayscale"
                  draggable={false}
                />
                <h3 className="mb-1 color-fg-muted">
                  No hay resultados que coincidan con su búsqueda
                </h3>
                <p className="container-sm color-fg-muted">
                  Las organizaciones son espacios de trabajo en los que puede
                  almacenar de forma segura las credenciales así como detalles
                  de contacto del cliente. Usted decide quién puede o no ver
                  estos datos.
                </p>
              </div>
            )}
            {Array.from(loading ? new Array(5) : data.results).map(
              (organization, index) => (
                <OrganizationListItem
                  key={loading ? `organization-${index}` : organization.id}
                  loading={loading}
                  organization={organization}
                />
              ),
            )}
          </div>
        </div>
        {!loading && data && (
          <Pagination
            pageInfo={{ currentPage: data.page, pageCount: data.totalPages }}
            onPageChange={setPage}
          />
        )}
      </div>
    </div>
  );
}

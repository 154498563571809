import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { XIcon, CheckIcon } from '@primer/octicons-react';
import PropTypes from 'prop-types';

const sortFilters = [
  { name: 'Alfabéticamente', sort: 'login:asc' },
  { name: 'Invertir alfabéticamente', sort: 'login:desc' },
  { name: 'Más reciente', sort: 'createdAt:desc' },
  { name: 'Más antiguo', sort: 'createdAt:asc' },
];

export default function CredentialSortFilter({ value, onChange }) {
  const [open, setOpen] = useState(false);

  useHotkeys('esc', () => setOpen(false));

  return (
    <details
      open={open}
      className="details-reset details-overlay d-inline-block position-relative px-3"
    >
      <summary
        role="button"
        className="btn-link"
        aria-haspopup="menu"
        onClick={(event) => {
          event.preventDefault();
          setOpen((prev) => !prev);
        }}
      >
        Ordenar <span className="dropdown-caret hide-sm" />
      </summary>
      <div role="menu" className="SelectMenu SelectMenu--hasFilter right-lg-0">
        <div className="SelectMenu-modal">
          <header className="SelectMenu-header">
            <span className="SelectMenu-title">Ordenar por</span>
            <button
              type="button"
              className="SelectMenu-closeButton"
              onClick={() => setOpen(false)}
            >
              <XIcon />
            </button>
          </header>
          <div className="SelectMenu-list select-menu-list">
            {sortFilters.map((filter) => {
              const isSelected = value === filter.sort;
              return (
                <div
                  key={filter.sort}
                  className="SelectMenu-item"
                  role="menuitemradio"
                  aria-checked={isSelected}
                  onClick={() => {
                    onChange(filter.sort);
                    setOpen(false);
                  }}
                >
                  <CheckIcon
                    className="SelectMenu-icon SelectMenu-icon--check"
                    width={12}
                    height={16}
                  />
                  <strong>{filter.name}</strong>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </details>
  );
}

CredentialSortFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

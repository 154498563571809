import { configureRefreshFetch, fetchJSON } from 'refresh-fetch';

const retrieveToken = () => localStorage.getItem('token');
const retrieveRefreshToken = () => localStorage.getItem('refreshToken');

const saveToken = (tokens) => {
  localStorage.setItem('token', tokens.access.token);
  localStorage.setItem('tokenExpires', tokens.access.expires);
  localStorage.setItem('refreshToken', tokens.refresh.token);
  localStorage.setItem('refreshTokenExpires', tokens.refresh.expires);
};

const clearToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpires');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshTokenExpires');
  localStorage.removeItem('viewerId');
  window.location.reload();
};

const fetchJSONWithToken = (url, options = {}) => {
  const token = retrieveToken();
  return fetchJSON(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: token !== null ? `Bearer ${token}` : undefined,
    },
  });
};

const shouldRefreshToken = (error) =>
  error.response?.status === 401 &&
  error.body?.message === 'Please authenticate';

const refreshToken = () =>
  fetchJSONWithToken(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refreshToken: retrieveRefreshToken() }),
  })
    .then((response) => saveToken(response.body))
    .catch((error) => {
      clearToken();
      throw error;
    });

const fetch = configureRefreshFetch({
  // Pass fetch function you want to wrap, it should already be adding
  // token to the request
  fetch: fetchJSONWithToken,
  // shouldRefreshToken is called when API fetch fails and it should decide
  // whether the response error means we need to refresh token
  shouldRefreshToken,
  // refreshToken should call the refresh token API, save the refreshed
  // token and return promise -- resolving it when everything goes fine,
  // rejecting it when refreshing fails for some reason
  refreshToken,
});

export { fetch };

import { useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useMutation, useQuery } from 'hooks';
import { useToasts } from 'react-toast-notifications';
import {
  CircularProgress,
  CredentialLabelFilter,
  CredentialListItem,
  CredentialSortFilter,
  LabelPicker,
  Pagination,
} from 'components';
import {
  EyeClosedIcon,
  EyeIcon,
  SearchIcon,
  ShieldCheckIcon,
  ShieldLockIcon,
} from '@primer/octicons-react';
import { Collapse } from '@material-ui/core';
import generator from 'generate-password';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

export default function OrganizationCredentialsRoute({ organization }) {
  const { addToast } = useToasts();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 250);
  const [labelFilter, setLabelFilter] = useState(undefined);
  const [sortFilter, setSortFilter] = useState('login:asc');
  const [openCreateCredential, setOpenCreateCredential] = useState(false);
  const loginInput = useRef();
  const passwordInput = useRef();
  const [label, setLabel] = useState(undefined);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isSecurePassword, setIsSecurePassword] = useState(false);
  const [previewPassword, setPreviewPassword] = useState(false);
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [page, setPage] = useState(1);

  useHotkeys('n', () => setOpenCreateCredential(true));

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [addToast],
  );

  useEffect(() => {
    setPage(1);
  }, [debouncedSearch, label]);

  const {
    data,
    loading,
    refetch: refetchCredentials,
  } = useQuery({
    url: '/credentials',
    variables: {
      organization: organization.id,
      search:
        debouncedSearch.trim().length > 0 ? debouncedSearch.trim() : undefined,
      label: labelFilter,
      page,
      limit: 50,
      sortBy: sortFilter,
    },
    skip: !organization,
    onError,
  });

  // const onFetchLabels = useCallback((res) => {
  //   if (res && res.results && res.results.length) {
  //     setLabel(res.results[0].id);
  //   }
  // }, []);

  // const { data: labelsData, loadingLabels } = useQuery({
  //   url: '/labels',
  //   variables: {
  //     page: 1,
  //     limit: 10000,
  //     sortBy: 'name:asc',
  //   },
  //   onCompleted: onFetchLabels,
  //   onError,
  // });

  const cancelCreateAction = () => {
    setLogin('');
    setPassword('');
    setIsSecurePassword(false);
    setUrl('');
    setDescription('');
    setOpenCreateCredential(false);
  };

  const [createCredential, { loading: creatingCredential }] = useMutation({
    url: '/credentials',
    onCompleted: () => {
      cancelCreateAction();
      addToast('Credencial añadida con éxito', {
        appearance: 'success',
        autoDismiss: true,
      });
      refetchCredentials();
    },
    onError,
  });

  useEffect(() => {
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{15,}$/.test(password)) {
      setIsSecurePassword(true);
    } else {
      setIsSecurePassword(false);
    }
  }, [password]);

  useEffect(() => {
    if (
      url.length > 0 &&
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
        url,
      ) &&
      !(url.includes('http://') || url.includes('https://'))
    ) {
      setUrl((prev) => `https://${prev}`);
    }
  }, [url]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    createCredential({
      organization: organization.id,
      label: label?.id,
      login,
      password,
      url: url.trim().length > 0 ? url.trim() : undefined,
      description:
        description.trim().length > 0 ? description.trim() : undefined,
    });
  };

  const generateSecurePassword = () => {
    const securePassword = generator.generate({
      length: 15,
      numbers: true,
      lowercase: true,
      uppercase: true,
      excludeSimilarCharacters: true,
      symbols: '!@#$%^&',
      strict: true,
    });

    setPassword(securePassword);

    if (passwordInput.current) {
      setTimeout(() => passwordInput.current.focus(), 200);
    }
  };

  return (
    <div className="credentials-content">
      {/* Create credential */}
      <Collapse
        in={openCreateCredential}
        onEntered={() => {
          if (loginInput.current) {
            loginInput.current.focus();
          }
        }}
      >
        <form
          className="px-3 mb-3 color-bg-default border color-border-default rounded"
          onSubmit={handleFormSubmit}
        >
          <div className="d-flex flex-md-row flex-wrap flex-column flex-items-start py-2">
            <dl className="form-group col-md-4 col-12 pr-md-3 pr-0 my-2">
              <dt className="d-flex flex-justify-between flex-items-center">
                <label htmlFor="credential-login" className="f5">
                  Login
                </label>
              </dt>
              <dd>
                <input
                  ref={loginInput}
                  type="text"
                  id="credential-login"
                  name="new-login"
                  className="form-control width-full"
                  placeholder="Usuario o email"
                  value={login}
                  onChange={({ target }) => setLogin(target.value)}
                  autoComplete="new-login"
                  spellCheck={false}
                  required
                />
              </dd>
            </dl>
            <dl className="form-group col-md-4 col-12 pr-md-3 pr-0 my-2">
              <dt className="d-flex flex-justify-between flex-items-center">
                <label htmlFor="credential-password" className="f5">
                  Contraseña
                </label>
              </dt>
              <dd className="d-flex">
                <div className="subnav-search-context">
                  <button
                    type="button"
                    className="btn tooltipped tooltipped-s"
                    aria-label={
                      isSecurePassword
                        ? 'La contraseña ya es segura'
                        : 'Generar contraseña segura'
                    }
                    onClick={generateSecurePassword}
                    disabled={isSecurePassword}
                  >
                    {isSecurePassword ? (
                      <ShieldCheckIcon />
                    ) : (
                      <ShieldLockIcon />
                    )}
                  </button>
                </div>
                <div className="subnav-search width-full">
                  <input
                    ref={passwordInput}
                    type={previewPassword ? 'text' : 'password'}
                    id="credential-password"
                    name="new-password"
                    className="form-control width-full subnav-search-input pl-3 tooltipped tooltipped-n"
                    style={{ paddingRight: 32 }}
                    aria-label={password}
                    placeholder="Contraseña"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                    autoComplete="new-password"
                    spellCheck={false}
                    required
                  />
                  <button
                    type="button"
                    className="btn-octicon color-fg-muted position-absolute tooltipped tooltipped-n"
                    style={{ right: 6, top: 3 }}
                    aria-label={
                      previewPassword
                        ? 'Esconder contraseña'
                        : 'Visualizar contraseña'
                    }
                    onClick={() => setPreviewPassword((prev) => !prev)}
                  >
                    {previewPassword ? <EyeClosedIcon /> : <EyeIcon />}
                  </button>
                </div>
              </dd>
            </dl>
            <dl className="form-group col-md-4 col-12 pr-0 my-2">
              <dt className="d-flex flex-justify-between flex-items-center">
                <label htmlFor="credential-url" className="f5">
                  URL <span className="note">(Opcional)</span>
                </label>
              </dt>
              <dd>
                <input
                  type="text"
                  id="credential-url"
                  name="new-url"
                  className="form-control width-full"
                  placeholder="Enlace a página web"
                  value={url}
                  onChange={({ target }) => setUrl(target.value)}
                  autoComplete="new-url"
                  spellCheck={false}
                />
              </dd>
            </dl>
            <dl className="form-group col-md-4 col-12 pr-md-3 pr-0 my-2">
              <dt className="d-flex flex-justify-between flex-items-center">
                <label htmlFor="credential-description" className="f5">
                  Etiqueta
                </label>
              </dt>
              <dd>
                <LabelPicker value={label} onChange={setLabel} />
              </dd>
            </dl>
            <dl className="form-group col-md-8 col-12 pr-0 my-2">
              <dt className="d-flex flex-justify-between flex-items-center">
                <label htmlFor="credential-description" className="f5">
                  Descripción <span className="note">(Opcional)</span>
                </label>
              </dt>
              <dd>
                <input
                  type="text"
                  id="credential-description"
                  name="new-description"
                  className="form-control width-full"
                  placeholder="Breve descripción"
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                  autoComplete="new-description"
                  spellCheck={false}
                />
              </dd>
            </dl>
          </div>
          <div className="form-actions border-top py-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={creatingCredential}
            >
              {creatingCredential ? 'Guardando…' : 'Guardar'}
            </button>
            <button
              type="button"
              className="btn"
              onClick={cancelCreateAction}
              disabled={creatingCredential}
            >
              Cancelar
            </button>
          </div>
        </form>
      </Collapse>
      {/* Credentials actions */}
      <div className="d-flex flex-justify-between flex-items-end mb-3">
        <div
          role="search"
          className="d-flex flex-justify-start flex-auto width-full my-0"
        >
          <div className="subnav-search width-full ml-0">
            <SearchIcon className="subnav-search-icon" />
            <input
              type="search"
              className="form-control subnav-search-input subnav-search-input-wide width-full"
              placeholder="Buscar credenciales…"
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              spellCheck={false}
              autoFocus
            />
          </div>
        </div>
        <div className="ml-3 d-flex flex-justify-between width-auto">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setOpenCreateCredential((prev) => !prev)}
            disabled={openCreateCredential}
          >
            <span className="d-none d-md-block">Nueva credencial</span>
            <span className="d-block d-md-none">Nueva</span>
          </button>
        </div>
      </div>
      {/* Credentials table */}
      <div className="Box Box--responsive hx_Box--firstRowRounded0">
        <div className="Box-header d-flex flex-justify-between">
          <div className="table-list-filters flex-auto d-flex min-width-0">
            <div className="flex-auto d-none d-lg-block no-wrap">
              <h3 className="Box-title">Credenciales</h3>
            </div>
            <div className="table-list-header-toggle no-wrap d-flex flex-auto flex-justify-between flex-sm-justify-start flex-lg-justify-end">
              <CredentialLabelFilter
                value={labelFilter}
                onChange={setLabelFilter}
              />
              <CredentialSortFilter
                value={sortFilter}
                onChange={setSortFilter}
              />
            </div>
          </div>
        </div>
        <div className="credentials-list">
          {loading && (
            <div className="bg-primary blankslate blankslate-spacious text-center position-relative rounded-2">
              <CircularProgress />
            </div>
          )}
          {!loading && data?.results?.length > 0 && (
            <table className="width-full">
              <thead>
                <tr>
                  <th>Etiqueta</th>
                  <th>Login</th>
                  <th>Contraseña</th>
                  <th>Descripción</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {data.results.map((credential) => (
                  <CredentialListItem
                    key={credential.id}
                    credential={credential}
                    refetchCredentials={refetchCredentials}
                  />
                ))}
              </tbody>
            </table>
          )}
          {!loading && !data?.results?.length && (
            <div className="bg-primary blankslate blankslate-spacious text-center position-relative rounded-2">
              <img
                src="https://ghicons.github.com/assets/images/blue/png/Code%20hosting.png"
                alt="Workplace icon"
                className="d-block mb-3 mx-auto grayscale"
                draggable={false}
              />
              <h3 className="mb-1 color-fg-muted">
                No hay resultados que coincidan con su búsqueda
              </h3>
              <p className="container-sm color-fg-muted">
                Genere y almacene credenciales de forma segura y asigne
                etiquetas que ayuden a identificar de forma más rápida el uso de
                las mismas.
              </p>
            </div>
          )}
        </div>
      </div>
      {!loading && data && (
        <Pagination
          pageInfo={{ currentPage: data.page, pageCount: data.totalPages }}
          onPageChange={setPage}
        />
      )}
    </div>
  );
}

OrganizationCredentialsRoute.propTypes = {
  organization: PropTypes.object.isRequired,
};

import { useAccount } from 'context/account';
import { Route, Switch } from 'react-router-dom';

import DashboardRoute from './dashboard.route';
import CredentialRequestsRoute from './credential-requests.route';

export default function AdminRoutes() {
  const { user: viewer } = useAccount();
  if (viewer.role !== 'admin') return null;
  return (
    <Switch>
      <Route path="/admin" component={DashboardRoute} exact />
      <Route
        path="/admin/credential-requests"
        component={CredentialRequestsRoute}
      />
    </Switch>
  );
}

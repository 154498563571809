import { useAccount } from 'context/account';
import { useMutation } from 'hooks';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

export default function OrganizationCreateRoute() {
  const history = useHistory();
  const { addToast } = useToasts();
  const { user } = useAccount();
  const [name, setName] = useState('');
  const [description, setDesctiption] = useState('');

  const onCreateOrganization = useCallback((organization) => {
    addToast('Organización añadida', {
      appearance: 'success',
      autoDismiss: true,
    });
    history.push(`/organizations/${organization.id}`);
  }, []);

  const onError = useCallback(
    (error) =>
      addToast(error, {
        appearance: 'error',
        autoDismiss: true,
      }),
    [],
  );

  const [createOrganization, { loading }] = useMutation({
    url: '/organizations',
    options: { method: 'POST' },
    onCompleted: onCreateOrganization,
    onError,
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    createOrganization({
      author: user.id,
      name,
      description:
        description.trim().length > 0 ? description.trim() : undefined,
      users: [user.id],
    });
  };

  return (
    <div className="flex-1 py-3">
      <Helmet>
        <title>Organizaciones • {process.env.REACT_APP_NAME} Wallet</title>
      </Helmet>
      <div className="container-lg p-responsive">
        <div className="Box">
          <div className="Box-body">
            <div className="Subhead">
              <div className="Subhead-heading">
                Crear una nueva organización
              </div>
              <div className="Subhead-description">
                Las organizaciones son espacios de trabajo en los que puede
                almacenar de forma segura información relevante con el resto de
                su equipo.
              </div>
            </div>
            <form onSubmit={handleFormSubmit}>
              <dl className="form-group">
                <dt>
                  <label htmlFor="organization-name">
                    Nombre de la organización
                  </label>
                </dt>
                <dd>
                  <input
                    type="text"
                    id="organization-name"
                    className="form-control"
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                    autoComplete="off"
                    spellCheck={false}
                    autoFocus
                    required
                  />
                </dd>
              </dl>
              <dl className="form-group">
                <dt>
                  <label htmlFor="organization-description">
                    Descripción{' '}
                    <span className="note color-fg-muted">(Opcional)</span>
                  </label>
                </dt>
                <dd>
                  <textarea
                    id="organization-description"
                    className="form-control width-full"
                    placeholder="Incluye una breve descripción para ayudar a entender mejor la finalidad de la organización"
                    rows={3}
                    value={description}
                    onChange={({ target }) => setDesctiption(target.value)}
                    autoComplete="off"
                  />
                </dd>
              </dl>
              <div className="form-actions">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Creando organización…' : 'Crear organización'}
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={() => history.goBack()}
                  disabled={loading}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

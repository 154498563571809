/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAccount } from 'context/account';
import { BriefcaseIcon, PeopleIcon, PlusIcon } from '@primer/octicons-react';
import { useQuery } from 'hooks';
import { useDebounce } from 'use-debounce';
import { Skeleton } from '@mui/material';

export default function Sidebar() {
  const { user: viewer } = useAccount();
  const [searchOrganizations, setSearchOrganizations] = useState('');
  const [debouncedSearchOrganizations] = useDebounce(searchOrganizations, 250);
  const [searchTeams, setSearchTeams] = useState('');
  const [deboundecSearchTeams] = useDebounce(searchTeams, 250);

  const { data: organizations, loading: loadingOrganizations } = useQuery({
    url: '/organizations',
    variables: {
      sortBy: 'name:asc',
      limit: 10,
      name:
        debouncedSearchOrganizations.trim().length > 0
          ? debouncedSearchOrganizations.trim()
          : undefined,
    },
  });

  const { data: teams, loading: loadingTeams } = useQuery({
    url: '/teams',
    variables: {
      sortBy: 'name:asc',
      limit: 10,
      name:
        deboundecSearchTeams.trim().length > 0
          ? deboundecSearchTeams.trim()
          : undefined,
    },
  });

  return (
    <aside className="sidebar position-sticky top-0 color-bg-default border-right hide-md hide-sm">
      <div
        className="overflow-y-scroll"
        style={{ maxHeight: 'calc(100vh - 58px)', overflow: 'hidden scroll' }}
      >
        {/* Recent activity */}
        <div className="mt-2 pt-3">
          <h2 className="f5">Actividad reciente</h2>
          <div className="mt-2 mb-4">
            <p className="f6 mt-2">
              Hola{' '}
              <span className="text-bold">{viewer.name.split(' ')[0]}</span>,
              cuando realices acciones en Wallet proporcionaremos enlaces a esa
              actividad aquí.
            </p>
          </div>
        </div>
        {/* Organizations */}
        <div className="mt-3 pt-4 border-top">
          <h2 className="f5 hide-sm hide-md mb-1 d-flex flex-justify-between flex-items-center">
            Organizaciones
            <Link
              to="/organizations/new"
              className="btn btn-sm btn-primary tooltipped tooltipped-n"
              aria-label="Añadir"
            >
              <PlusIcon className="vertical-align-baseline" size={14} /> Añadir
            </Link>
          </h2>
          <div className="my-3" role="search" aria-label="Organizaciones">
            <input
              type="search"
              className="form-control input-contrast input-block mb-3"
              placeholder="Encuentre una organización…"
              aria-label="Encuentre una organización…"
              defaultValue=""
              onChange={({ target }) => setSearchOrganizations(target.value)}
              autoComplete="off"
            />
          </div>
          {loadingOrganizations && (
            <ul className="list-style-none">
              {Array.from(new Array(5)).map((_, i) => (
                <li
                  key={`organization-skeleton-${i}`}
                  className="border-top color-border-muted py-2"
                >
                  <div className="d-flex flex-items-center">
                    <Skeleton width={16} height={16} variant="circular" />
                    <div className="ml-2 flex-1">
                      <Skeleton variant="text" width="100%" />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {!loadingOrganizations && organizations?.results?.length === 0 && (
            <p className="color-fg-muted">
              No hemos encontrado coincidencias, haz click{' '}
              <Link to="/organizations/new" className="Link--primary">
                aquí
              </Link>{' '}
              para crear una organización nueva.
            </p>
          )}
          {!loadingOrganizations && organizations?.results?.length > 0 && (
            <>
              <ul className="list-style-none">
                {organizations.results.map((organization) => (
                  <li
                    key={organization.id}
                    className="border-top color-border-muted"
                    style={{ padding: '12px 0' }}
                  >
                    <div className="width-full" style={{ fontWeight: 500 }}>
                      <Link
                        to={`/organizations/${organization.id}`}
                        className="d-flex flex-justify-between flex-items-center f5 Link--primary no-underline"
                      >
                        <div className="d-inline-flex flex-items-baseline flex-wrap width-fit">
                          <div className="color-fg-muted mr-2">
                            <BriefcaseIcon className="flex-shrink-0" />
                          </div>
                          <span
                            className="css-truncate css-truncate-target"
                            style={{ maxWidth: 260 }}
                          >
                            {organization.name}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
              <Link
                to="/organizations"
                className="width-full text-left btn-link f6 Link--muted text-left mt-2 border-md-0 border-top py-md-0 py-3 px-md-0 px-2"
              >
                Mostrar más
              </Link>
            </>
          )}
        </div>
        <div className="mt-3 pt-4 pb-2 border-top">
          <h2 className="f5 hide-sm hide-md mb-1 d-flex flex-justify-between flex-items-center">
            Equipos
            <Link
              to="/teams/new"
              className="btn btn-sm btn-primary tooltipped tooltipped-n"
              aria-label="Añadir"
            >
              <PlusIcon className="vertical-align-baseline" size={14} /> Añadir
            </Link>
          </h2>
          <div className="my-3" role="search" aria-label="Equipos">
            <input
              type="search"
              className="form-control input-contrast input-block mb-3"
              placeholder="Encuentre un equipo…"
              aria-label="Encuentre un equipo…"
              value={searchTeams}
              onChange={({ target }) => setSearchTeams(target.value)}
              autoComplete="off"
            />
          </div>
          {loadingTeams && (
            <ul className="list-style-none">
              {Array.from(new Array(5)).map((_, i) => (
                <li
                  key={`team-skeleton-${i}`}
                  className="border-top color-border-muted py-2"
                >
                  <div className="d-flex flex-items-center">
                    <Skeleton width={16} height={16} variant="circular" />
                    <div className="ml-2 flex-1">
                      <Skeleton variant="text" width="100%" />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {!loadingTeams && teams?.results?.length > 0 && (
            <>
              <ul className="list-style-none">
                {teams.results.map((team) => (
                  <li
                    key={team.id}
                    className="border-top color-border-muted"
                    style={{ padding: '12px 0' }}
                  >
                    <div className="width-full" style={{ fontWeight: 500 }}>
                      <Link
                        to={`/teams/${team.id}`}
                        className="d-flex flex-justify-between flex-items-center f5 Link--primary no-underline"
                      >
                        <div className="d-inline-flex flex-items-baseline flex-wrap width-fit">
                          <div className="color-fg-muted mr-2">
                            <PeopleIcon className="flex-shrink-0" />
                          </div>
                          <span
                            className="css-truncate css-truncate-target"
                            style={{ maxWidth: 260 }}
                          >
                            {team.name}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
              <Link
                to="/teams"
                className="width-full text-left btn-link f6 Link--muted text-left mt-2 border-md-0 border-top py-md-0 py-3 px-md-0 px-2"
              >
                Mostrar más
              </Link>
            </>
          )}
        </div>
      </div>
    </aside>
  );
}

import { useState } from 'react';
import { useAccount } from 'context/account';
import { Link, NavLink } from 'react-router-dom';
import { QuickSwitcher, UserAvatar } from 'components';
import {
  MoonIcon,
  PlusIcon,
  SearchIcon,
  ShieldLockIcon,
  SunIcon,
  ThreeBarsIcon,
} from '@primer/octicons-react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTheme, useThemeDispatch } from 'context/theme';

export default function Header() {
  const { user: viewer } = useAccount();
  const { isDarkMode } = useTheme();
  const dispatchTheme = useThemeDispatch();
  const [openQuickSwitcher, setOpenQuickSwitcher] = useState(false);
  const [openCreateActions, setOpenCreateActions] = useState(false);
  const [openUserActions, setOpenUserActions] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  useHotkeys('command+k,ctrl+k', () => setOpenQuickSwitcher((prev) => !prev));

  return (
    <header
      className={
        !openMobileMenu
          ? 'Header Details px-3 px-md-4 px-lg-5 flex-wrap flex-md-nowrap border-bottom'
          : 'Header Details px-3 px-md-4 px-lg-5 flex-wrap flex-md-nowrap border-bottom open Details--on'
      }
    >
      {/* Desktop logo */}
      <div
        className="Header-item mt-n1 mb-n1 d-none d-md-flex"
        style={{ marginRight: 139 }}
      >
        <Link
          to="/"
          className="Header-link f4 d-flex flex-items-center"
          aria-label="Página de inicio"
        >
          <span style={{ color: '#ff7700' }}>
            <ShieldLockIcon className="mr-2" size={32} />
          </span>
          <span>
            {process.env.REACT_APP_NAME}
            <span style={{ marginLeft: 1 }}>Wallet</span>
          </span>
        </Link>
      </div>
      <div
        role="none"
        className="d-none d-md-flex mr-4"
        style={{ height: 60, marginTop: -16, marginBottom: -16 }}
      />
      {/* Mobile open menu */}
      <div className="Header-item d-md-none">
        <button
          type="button"
          className="Header-link btn-link"
          onClick={() => setOpenMobileMenu((prev) => !prev)}
        >
          <ThreeBarsIcon />
        </button>
      </div>
      {/* Desktop navigator */}
      <div className="Header-item Header-item--full flex-column flex-md-row width-full flex-order-2 flex-md-order-none mr-0 mr-md-3 mt-3 mt-md-0 Details-content--hidden-not-important d-md-flex">
        <nav
          className="d-flex flex-column flex-md-row flex-self-stretch flex-md-self-auto"
          aria-label="Global"
        >
          <NavLink
            exact
            to="/"
            className="Header-link py-md-3 d-block d-md-none py-2 px-0 px-md-2 border-top border-md-top-0 border-white-fade"
            aria-label="Dashboard"
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/organizations"
            className="Header-link mt-md-n3 mb-md-n3 py-2 py-md-3 px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
            aria-label="Organizaciones a las que perteneces"
          >
            Organizaciones
          </NavLink>
          <NavLink
            to="/teams"
            className="Header-link mt-md-n3 mb-md-n3 py-2 py-md-3 px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
            aria-label="Equipos a los que perteneces"
          >
            Equipos
          </NavLink>
          <NavLink
            to="/users"
            className="Header-link mt-md-n3 mb-md-n3 py-2 py-md-3 px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
            aria-label="Usuarios"
          >
            Usuarios
          </NavLink>
          {viewer.role === 'admin' && (
            <NavLink
              to="/admin"
              className="Header-link mt-md-n3 mb-md-n3 py-2 py-md-3 px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
              aria-label="Administración"
            >
              Administración
            </NavLink>
          )}
        </nav>
      </div>
      {/* Mobile logo */}
      <div className="Header-item Header-item--full flex-justify-center d-md-none position-relative">
        <Link to="/" className="Header-link" aria-label="Página de inicio">
          <span style={{ color: '#ff7700' }}>
            <ShieldLockIcon className="mr-2" size={32} />
          </span>
        </Link>
      </div>
      {/* Search action */}
      <div className="Header-item mr-0 mr-md-3 flex-order-1 flex-md-order-none">
        <button
          type="button"
          className="Header-link btn-link tooltipped tooltipped-s"
          aria-label="Navegación rápida (Ctrl/Cmd+K)"
          onClick={() => setOpenQuickSwitcher(true)}
        >
          <SearchIcon />
        </button>
        <QuickSwitcher
          open={openQuickSwitcher}
          onClose={() => setOpenQuickSwitcher(false)}
        />
      </div>
      {/* Theme action */}
      <div className="Header-item mr-0 mr-md-3 flex-order-1 flex-md-order-none d-none d-md-flex">
        <button
          type="button"
          className="Header-link btn-link tooltipped tooltipped-s"
          aria-label={isDarkMode ? 'Encender las luces' : 'Apagar las luces'}
          onClick={() => {
            const theme = isDarkMode ? 'light' : 'dark';
            localStorage.setItem('theme', theme);
            dispatchTheme({ type: theme });
          }}
        >
          {isDarkMode ? <MoonIcon /> : <SunIcon />}
        </button>
      </div>
      {/* Desktop create actions */}
      <div className="Header-item position-relative flex-order-4 d-none d-md-flex">
        <details
          className="details-overlay details-reset"
          open={openCreateActions}
        >
          <summary
            role="button"
            className="Header-link"
            aria-label="Crear nuevo…"
            aria-haspopup="menu"
            onClick={(event) => {
              event.preventDefault();
              setOpenCreateActions((prev) => !prev);
            }}
          >
            <PlusIcon />
            <span className="dropdown-caret ml-1" />
          </summary>
          <div className="dropdown-menu dropdown-menu-sw" role="menu">
            <Link
              role="menuitem"
              to="/organizations/new"
              className="dropdown-item"
              onClick={() => setOpenCreateActions(false)}
            >
              Nueva organización
            </Link>
            <Link
              role="menuitem"
              to="/teams/new"
              className="dropdown-item"
              onClick={() => setOpenCreateActions(false)}
            >
              Nuevo equipo
            </Link>
            <Link
              role="menuitem"
              to="/users/new"
              className="dropdown-item"
              onClick={() => setOpenCreateActions(false)}
            >
              Nuevo usuario
            </Link>
          </div>
        </details>
      </div>
      {/* Desktop user actions */}
      <div className="Header-item position-relative mr-0 d-none d-md-flex">
        <details
          className="details-overlay details-reset"
          open={openUserActions}
        >
          <summary
            role="button"
            className="Header-link"
            aria-label="Ver perfil y más"
            aria-haspopup="menu"
            onClick={(event) => {
              event.preventDefault();
              setOpenUserActions((prev) => !prev);
            }}
          >
            <UserAvatar url={viewer.avatarURL} username={viewer.name} />
            <span className="dropdown-caret ml-1" />
          </summary>
          <div
            className="dropdown-menu dropdown-menu-sw"
            role="menu"
            style={{ width: 180 }}
          >
            <Link
              role="menuitem"
              to={`/users/${viewer.id}`}
              className="dropdown-item"
              onClick={() => setOpenUserActions(false)}
            >
              Mi perfil
            </Link>
            <Link
              role="menuitem"
              to={`/users/${viewer.id}/settings`}
              className="dropdown-item"
              onClick={() => setOpenUserActions(false)}
            >
              Configuración
            </Link>
            <div role="none" className="dropdown-divider" />
            <Link
              role="menuitem"
              to="/auth/signout"
              className="dropdown-item"
              onClick={() => setOpenUserActions(false)}
            >
              Cerrar sesión
            </Link>
          </div>
        </details>
      </div>
    </header>
  );
}

import { Link } from 'react-router-dom';
import { BriefcaseIcon, KeyIcon } from '@primer/octicons-react';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLazyQuery } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';

export default function OrganizationListItem({ organization, loading }) {
  const { addToast } = useToasts();

  const onError = useCallback(
    (error) => addToast(error, { appearance: 'error', autoDismiss: true }),
    [],
  );

  const [loadCredentials, { data }] = useLazyQuery({
    url: loading ? undefined : `/organizations`,
    onError,
  });

  useEffect(() => {
    if (organization && organization.id) {
      loadCredentials({}, `/${organization.id}/credentials`);
    }
  }, [organization, loadCredentials]);

  return (
    <div className="Box-row Box-row--focus-gray p-0 mt-0 selectable organization-list-item">
      <div className="d-flex Box-row--drag-hide position-relative">
        {!loading && (
          <div className="flex-shrink-0 pt-2 pl-3">
            <span className="tooltipped tooltipped-e" aria-label="Organización">
              <BriefcaseIcon />
            </span>
          </div>
        )}
        <div className="flex-auto min-width-0 lh-condensed p-2 pr-3 pr-md-2">
          {loading ? (
            <div>
              <Skeleton height={10} variant="rect" className="rounded-1 mb-2" />
            </div>
          ) : (
            <Link
              to={`/organizations/${organization.id}`}
              className="Link--primary v-align-middle no-underline h4"
            >
              {organization.name}
            </Link>
          )}
          <div className="mt-1 text-small color-fg-muted">
            {loading ? (
              <Skeleton
                height={10}
                width="60%"
                variant="rect"
                className="rounded-1"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.11)' }}
              />
            ) : (
              <span>
                {organization.description
                  ? `${organization.description} •`
                  : ''}{' '}
                Creado{' '}
                {organization.author ? `por ${organization.author.name}` : ''}{' '}
                {moment(organization.createdAt).fromNow()}
              </span>
            )}
          </div>
        </div>
        <div className="flex-shrink-0 col-3 pt-2 text-right pr-3 no-wrap d-flex hide-sm">
          <span className="ml-2 flex-1 flex-shrink-0"></span>
          <span className="ml-2 flex-1 flex-shrink-0"></span>
          <span className="ml-2 flex-1 flex-shrink-0">
            {data && (
              <Link
                to={`/organizations/${organization.id}`}
                className="Link--muted"
              >
                <KeyIcon verticalAlign="middle" />
                <span
                  className="text-small text-bold ml-1"
                  style={{ lineHeight: '18px' }}
                >
                  {data.credentialsCount}
                </span>
              </Link>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

OrganizationListItem.propTypes = {
  organization: PropTypes.object,
  loading: PropTypes.bool,
};

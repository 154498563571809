import { useAccount } from 'context/account';
import { useQuery } from 'hooks';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { CircularProgress } from 'components';
import moment from 'moment';
import { BriefcaseIcon, GearIcon, PeopleIcon } from '@primer/octicons-react';

import TeamOrganizationsRoute from './organizations.route';
import TeamMembersRoute from './members.route';
import TeamSettingsRoute from './settings.route';

export default function TeamViewRoute() {
  const routeParams = useParams();
  const { addToast } = useToasts();
  const { user: viewer } = useAccount();

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [addToast],
  );

  const { data, loading, error, refetch } = useQuery({
    url: `/teams/${routeParams.teamId}`,
    skip: !routeParams.teamId,
    onError,
  });

  if (loading) {
    return (
      <div className="d-flex flex-1 py-3">
        <div className="d-flex flex-items-center flex-justify-center flex-1 container-lg p-responsive">
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (error) {
    return null;
  }

  return (
    <div className="flex-1">
      <Helmet>
        <title>
          {data.name} • {process.env.REACT_APP_NAME} Wallet
        </title>
      </Helmet>
      <div className="color-bg-default pt-4 border-bottom">
        <div className="container-lg p-responsive">
          <div className="d-flex flex-items-center mb-2">
            <div className="d-flex flex-items-center mr-3 color-fg-muted">
              <PeopleIcon size="medium" />
            </div>
            <div className="flex-auto min-width-0 width-fit mr-3">
              <h3 className="f3">{data.name}</h3>
              <p className="f6 color-fg-muted mb-0">
                {data.description || 'Sin descripción'} • Creado{' '}
                {data.author && `por ${data.author.name}`}{' '}
                {moment(data.createdAt).fromNow()}
              </p>
            </div>
            <ul
              className="pagehead-actions flex-shrink-0 d-none d-md-inline"
              style={{ padding: '2px 0' }}
            ></ul>
          </div>
          <nav
            className="overflow-hidden UnderlineNav"
            style={{ marginBottom: -1 }}
          >
            <ul className="UnderlineNav-body list-style-none">
              <li className="d-flex">
                <NavLink
                  exact
                  to={`/teams/${data.id}`}
                  className="UnderlineNav-item hx_underlinenav-item no-wrap"
                >
                  <BriefcaseIcon className="UnderlineNav-octicon d-none d-sm-inline" />
                  <span>Organizaciones</span>
                  <span title={data.credentialsCount} className="Counter">
                    {data.credentialsCount}
                  </span>
                </NavLink>
              </li>
              <li className="d-flex">
                <NavLink
                  to={`/teams/${data.id}/access`}
                  className="UnderlineNav-item hx_underlinenav-item no-wrap"
                >
                  <PeopleIcon className="UnderlineNav-octicon d-none d-sm-inline" />
                  <span>Miembros</span>
                  <span title={data.members.length} className="Counter">
                    {data.members.length}
                  </span>
                </NavLink>
              </li>
              {(viewer.role === 'admin' || data.author?.id === viewer.id) && (
                <li className="d-flex">
                  <NavLink
                    to={`/teams/${data.id}/settings`}
                    className="UnderlineNav-item hx_underlinenav-item no-wrap"
                  >
                    <GearIcon className="UnderlineNav-octicon d-none d-sm-inline" />
                    <span>Ajustes</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <div className="py-3">
        <div className="container-lg p-responsive">
          <Switch>
            <Route
              exact
              path="/teams/:teamId"
              render={(props) => (
                <TeamOrganizationsRoute {...props} team={data} />
              )}
            />
            <Route
              path="/teams/:teamId/access"
              render={(props) => (
                <TeamMembersRoute {...props} team={data} refetch={refetch} />
              )}
            />
            <Route
              path="/teams/:teamId/settings"
              render={(props) => (
                <TeamSettingsRoute {...props} team={data} refetch={refetch} />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

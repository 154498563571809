import { useMemo } from 'react';
import { Avatar, Badge, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px var(--color-canvas-default)`,
  },
})(Badge);

const SmallAvatar = withStyles({
  root: {
    width: 26,
    height: 26,
    border: `none`,
    fontSize: '16px',
    fontWeight: 500,
  },
})(Avatar);

const MediumAvatar = withStyles({
  root: {
    width: 36,
    height: 36,
    border: `none`,
    fontSize: '16px',
    fontWeight: 500,
  },
})(Avatar);

const LargeAvatar = withStyles({
  root: {
    width: 46,
    height: 46,
    border: `none`,
    fontSize: '16px',
    fontWeight: 500,
  },
})(Avatar);

const SettingsAvatar = withStyles({
  root: {
    width: 150,
    height: 150,
    border: `none`,
    fontSize: '42px',
    fontWeight: 500,
  },
})(Avatar);

export default function UserAvatar({
  url,
  username,
  size = 'small',
  hideOnlineIndicator = false,
}) {
  const AvatarComponent = useMemo(() => {
    switch (size) {
      case 'small':
        return SmallAvatar;
      case 'medium':
        return MediumAvatar;
      case 'large':
        return LargeAvatar;
      case 'settings':
        return SettingsAvatar;
      default:
        return Avatar;
    }
  }, [size]);

  return hideOnlineIndicator ? (
    <AvatarComponent src={url} alt={username}>
      {username.charAt(0)}
    </AvatarComponent>
  ) : (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
    >
      <AvatarComponent src={url} alt={username}>
        {username.charAt(0)}
      </AvatarComponent>
    </StyledBadge>
  );
}

UserAvatar.propTypes = {
  url: PropTypes.string,
  username: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'settings']),
  hideOnlineIndicator: PropTypes.bool,
};

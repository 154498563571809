import { useEffect, useMemo, useCallback, useReducer } from 'react';
import queryString from 'query-string';
import { fetch } from 'utils';

const emptyObject = {};
const voidFunction = () => {};

const reducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case 'success':
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.data,
      };
    case 'merge':
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.mergeFunction(state.data, action.data),
      };
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

/**
 * Fetch data from the server and handles authorization.
 * @param {Object} options - Query hook options
 * @param {String} [options.url] - Query endpoint added to API base URL
 * @param {Object} [options.options] - An object coinaining fetch options
 * @param {String} [options.options.method] - Method to use when fetching
 * @param {Object} [options.options.headers] - An object containing the call headers
 * @param {Object} [options.variables] - An object containing all of the variables your query requires to execute
 * @param {Boolean} [options.skip] - If true, the query is not executed.
 * @param {Function} [options.onCompleted] - A callback function that's called when your query successfully completes with no errors
 * @param {Function} [options.onError] - A callback function that's called when the query encounters errors
 * @returns {Object} A result object containing your query result plus some helpful fuctions for refetching and pagination
 */
export default function useQuery({
  url = '',
  options = emptyObject,
  variables = emptyObject,
  skip = false,
  onCompleted = voidFunction,
  onError = voidFunction,
}) {
  const [{ data, loading, error }, dispatch] = useReducer(reducer, {
    loading: !skip,
    error: undefined,
    data: undefined,
  });

  const fetchURL = useMemo(
    () =>
      queryString.stringifyUrl({
        url: `${process.env.REACT_APP_API_URL}${
          url.startsWith('/') ? url : `/${url}`
        }`,
        query: variables,
      }),
    [url, variables],
  );

  const fetchOptions = useMemo(
    () => ({
      method: 'GET',
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json',
      },
    }),
    [options],
  );

  /**
   * A function that helps you fetch the next set of results for a paginated list field
   * @param {Object} variables - An object containing all of the variables your query requires to execute
   * @param {Function} updateQuery - A function to update the data with the previous value and the response
   */
  const fetchMore = useCallback(
    async (refetchVariables = emptyObject, updateQuery) => {
      if (typeof updateQuery !== 'function') {
        throw new Error(
          'updateQuery function must be given when using fetchMore()',
        );
      }
      dispatch({ type: 'loading' });
      const refetchURL = queryString.stringifyUrl({
        url: `${process.env.REACT_APP_API_URL}${
          url.startsWith('/') ? url : `/${url}`
        }`,
        query: refetchVariables,
      });
      try {
        const response = await fetch(refetchURL, fetchOptions);
        dispatch({
          type: 'merge',
          mergeFunction: updateQuery,
          data: response.body,
        });
        // onCompleted(response.body);
      } catch (err) {
        const message = err.body ? err.body.message : err.message;
        dispatch({ type: 'error', error: message });
        onError(message);
      }
    },
    [fetchOptions, onCompleted, onError],
  );

  // A function that enables you to re-execute the query
  const refetch = useCallback(async () => {
    // dispatch({ type: 'loading' });
    try {
      const response = await fetch(fetchURL, fetchOptions);
      dispatch({ type: 'success', data: response.body });
      onCompleted(response.body);
    } catch (err) {
      const message = err.body ? err.body.message : err.message;
      dispatch({ type: 'error', error: message });
      onError(message);
    }
  }, [fetchURL, fetchOptions, onCompleted, onError]);

  const fetchData = useCallback(async () => {
    dispatch({ type: 'loading' });
    try {
      const response = await fetch(fetchURL, fetchOptions);
      dispatch({ type: 'success', data: response.body });
      onCompleted(response.body);
    } catch (err) {
      const message = err.body ? err.body.message : err.message;
      dispatch({ type: 'error', error: message });
      onError(message);
    }
  }, [fetchURL, fetchOptions, onCompleted, onError]);

  useEffect(async () => {
    if (skip === false) {
      fetchData();
    }
  }, [fetchData, skip]);

  return { data, loading, error, refetch, fetchMore };
}

/* eslint-disable indent */
import { useEffect, useState } from 'react';
import {
  CredentialRequestsSortFilter,
  CredentialRequestsUserFilter,
  DatePickerFilter,
  Pagination,
  UserAvatar,
} from 'components';
import { useQuery } from 'hooks';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import {
  BriefcaseIcon,
  ClockIcon,
  KeyIcon,
  PersonIcon,
} from '@primer/octicons-react';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import Chart from 'react-apexcharts';

const loadingArray = [
  'loading-1',
  'loading-2',
  'loading-3',
  'loading-4',
  'loading-5',
  'loading-6',
  'loading-7',
  'loading-8',
  'loading-9',
  'loading-10',
];

const emptyGraphData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

export default function CredentialRequestsRoute() {
  const [page, setPage] = useState(1);
  const [userFilter, setUserFilter] = useState(undefined);
  const [createdBefore, setCreatedBefore] = useState(undefined);
  const [createdAfter, setCreatedAfter] = useState(undefined);
  const [sortFilter, setSortFilter] = useState('createdAt:desc');
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setPage(1);
  }, [userFilter, createdBefore, createdAfter, sortFilter]);

  const { data, loading } = useQuery({
    url: `/admin/${selectedTab === 1 ? 'grouped-' : ''}credential-requests`,
    variables: {
      user: selectedTab === 0 ? userFilter : undefined,
      createdAfter: createdAfter
        ? createdAfter.startOf('day').toISOString()
        : undefined,
      createdBefore: createdBefore
        ? createdBefore.endOf('day').toISOString()
        : undefined,
      page,
      limit: 30,
      sortBy: sortFilter,
    },
  });

  const { data: graphData } = useQuery({
    url: '/admin/graph-credential-requests',
  });

  return (
    <div className="flex-1 py-3">
      <Helmet>
        <title>
          Solicitudes de credendiales • {process.env.REACT_APP_NAME} Wallet
        </title>
      </Helmet>
      <div className="container-lg p-responsive">
        <div className="Box d-none d-md-block mb-3">
          <div className="Box-body pt-3 pb-0 pl-0 pr-3">
            <Chart
              options={{
                chart: {
                  id: 'credential-requests-chart',
                  toolbar: { show: false },
                },
                theme: {
                  mode: 'light',
                  palette: 'palette1',
                  // monochrome: {
                  //   enabled: true,
                  //   color: '#ff7700',
                  // },
                },
                stroke: { curve: 'smooth', lineCap: 'butt', width: 1.5 },
                plotOptions: {
                  area: { fillTo: 'end' },
                  bar: {
                    horizontal: false,
                    columnWidth: '26px',
                    borderRadius: 3,
                  },
                },
                grid: {
                  strokeDashArray: 4,
                  borderColor: 'var(--color-border-default)',
                },
                dataLabels: { enabled: false },
                tooltip: {
                  x: { show: false },
                  marker: { show: true },
                  followCursor: true,
                  shared: true,
                  onDatasetHover: { highlightDataSeries: true },
                  inverseOrder: true,
                },
                legend: {
                  show: true,
                  labels: { colors: 'var(--color-fg-subtle)' },
                  position: 'top',
                  fontFamily: 'inherit',
                  itemMargin: { horizontal: 10, vertical: 0 },
                },
                yaxis: {
                  axisBorder: { show: false },
                  axisTicks: { show: false },
                  labels: {
                    show: true,
                    align: 'right',
                    style: {
                      colors: 'var(--color-fg-subtle)',
                      fontFamily: 'inherit',
                      fontSize: '13px',
                    },
                  },
                },
                xaxis: {
                  axisBorder: { show: false },
                  axisTicks: { show: false },
                  labels: {
                    show: true,
                    style: {
                      colors: 'var(--color-fg-subtle)',
                      fontFamily: 'inherit',
                      fontSize: '13px',
                    },
                  },
                  categories: [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre',
                  ],
                },
              }}
              series={[
                {
                  name: 'Solicitudes del año pasado',
                  data: graphData?.previous || emptyGraphData,
                },
                {
                  name: 'Solicitudes del año en curso',
                  data: graphData?.current || emptyGraphData,
                },
              ]}
              type="area"
              height={200}
            />
          </div>
        </div>
        <nav className="overflow-hidden UnderlineNav mb-3">
          <ul className="UnderlineNav-body list-style-none">
            <li className="d-flex">
              <button
                type="button"
                className="UnderlineNav-item hx_underlinenav-item no-wrap"
                aria-current={selectedTab === 0 ? 'page' : undefined}
                onClick={() => setSelectedTab(0)}
              >
                <ClockIcon className="UnderlineNav-octicon d-none d-sm-inline" />
                <span>Registro de solicitudes</span>
              </button>
              <button
                type="button"
                className="UnderlineNav-item hx_underlinenav-item no-wrap"
                aria-current={selectedTab === 1 ? 'page' : undefined}
                onClick={() => {
                  setSelectedTab(1);
                  setPage(1);
                }}
              >
                <PersonIcon className="UnderlineNav-octicon d-none d-sm-inline" />
                <span>Agrupado por usuario</span>
              </button>
            </li>
          </ul>
        </nav>
        <div className="Box hx_Box--firstRowRounded0">
          <div className="Box-header d-flex flex-justify-between">
            <div className="table-list-filters flex-auto d-flex min-width-0">
              <div className="flex-auto d-none d-lg-block no-wrap">
                <h2 className="Box-title">
                  Histórico de solicitudes{' '}
                  {!loading && (
                    <div className="Counter ml-1">{data.totalResults}</div>
                  )}
                </h2>
              </div>
              <div className="table-list-header-toggle no-wrap d-flex flex-auto flex-justify-between flex-sm-justify-start flex-lg-justify-end">
                {selectedTab === 0 && (
                  <CredentialRequestsUserFilter
                    value={userFilter}
                    onChange={setUserFilter}
                  />
                )}
                <DatePickerFilter
                  label="Fecha desde"
                  value={createdAfter ? createdAfter.startOf('day') : undefined}
                  onChange={setCreatedAfter}
                  minDate={moment('2020-01-01')}
                  maxDate={
                    createdBefore && createdBefore.isBefore(moment())
                      ? createdBefore
                      : moment()
                  }
                />
                <DatePickerFilter
                  label="Fecha hasta"
                  value={createdBefore ? createdBefore.endOf('day') : undefined}
                  onChange={setCreatedBefore}
                  minDate={createdAfter || moment('2020-01-01')}
                  maxDate={moment()}
                />
                {selectedTab === 0 && (
                  <CredentialRequestsSortFilter
                    value={sortFilter}
                    onChange={setSortFilter}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="credentials-list">
            <table className="width-full">
              <thead>
                <tr>
                  <th colSpan={2}>Usuario</th>
                  {selectedTab === 0 && (
                    <>
                      <th colSpan={2}>Organización</th>
                      <th colSpan={2}>Credencial</th>
                      <th>Fecha</th>
                    </>
                  )}
                  {selectedTab === 1 && (
                    <th className="text-center">Solicitudes</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {loading &&
                  loadingArray.map((key) => (
                    <tr key={key}>
                      <td width={26}>
                        <Skeleton variant="circle" width={26} height={26} />
                      </td>
                      <td colSpan={selectedTab === 0 ? 6 : 3}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="70%" />
                      </td>
                    </tr>
                  ))}
                {!loading &&
                  data &&
                  Array.from(
                    selectedTab === 0
                      ? data?.results?.filter((r) => r.credential) || []
                      : data,
                  )
                    .sort((a, b) =>
                      selectedTab === 1
                        ? a.user.name.localeCompare(b.user.name)
                        : 0,
                    )
                    .map((request) => (
                      <tr
                        key={selectedTab === 0 ? request.id : request._id}
                        className="credential-list-item"
                      >
                        <td width={26}>
                          <UserAvatar
                            url={request.user.avatarURL}
                            username={request.user.name}
                          />
                        </td>
                        <td className="no-wrap">
                          <Link
                            to={`/users/${request.user.id}`}
                            className="Link--primary text-bold"
                          >
                            {request.user.name}
                          </Link>
                          <div className="color-fg-subtle">
                            {request.user.email}
                          </div>
                        </td>
                        {selectedTab === 0 && (
                          <td width={16}>
                            <BriefcaseIcon className="color-fg-muted" />
                          </td>
                        )}
                        {selectedTab === 0 && (
                          <>
                            <td>
                              <Link
                                to={`/organizations/${request.credential.organization.id}`}
                                className="Link--primary text-bold"
                              >
                                {request.credential.organization.name}
                              </Link>
                              <div className="color-fg-subtle">
                                {request.credential.organization.description ||
                                  'Sin descripción'}
                              </div>
                            </td>
                            <td width={16}>
                              <KeyIcon className="color-fg-muted" />
                            </td>
                            <td>
                              <div className="text-bold">
                                {request.credential.login}
                              </div>
                              <div className="color-fg-subtle">
                                {request.credential.description}
                              </div>
                            </td>
                            <td>{moment(request.createdAt).calendar()}</td>
                          </>
                        )}
                        {selectedTab === 1 && (
                          <td className="text-center">{request.requests}</td>
                        )}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        {!loading && selectedTab === 0 && (
          <Pagination
            pageInfo={{ currentPage: data.page, pageCount: data.totalPages }}
            onPageChange={setPage}
          />
        )}
      </div>
    </div>
  );
}

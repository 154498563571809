import { Switch, Route } from 'react-router-dom';

import UserListRoute from './list.route';
import UserCreateRoute from './create.route';
import UserViewRoute from './view.route';

export default function UserRoutes() {
  return (
    <Switch>
      <Route exact path="/users" component={UserListRoute} />
      <Route path="/users/new" component={UserCreateRoute} />
      <Route path="/users/:userId" component={UserViewRoute} />
    </Switch>
  );
}

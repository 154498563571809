/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'hooks';
import { useToasts } from 'react-toast-notifications';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { SearchIcon, SyncIcon } from '@primer/octicons-react';
import { LabelListItem, LabelSortFilter, Pagination } from 'components';
import { Collapse } from '@material-ui/core';
import randomColor from 'randomcolor';
import { fontColorContrast, isRegexColor } from 'utils';
import { useDebounce } from 'use-debounce';

export default function OrganizationLabelsRoute({ organization }) {
  const { addToast } = useToasts();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 250);
  const [page, setPage] = useState(1);
  const [sortFilter, setSortFilter] = useState('name:asc');
  const [createMode, setCreateMode] = useState(false);
  const nameInput = useRef();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState(randomColor().toUpperCase());

  useEffect(() => {
    setPage(1);
  }, [debouncedSearch]);

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [addToast],
  );

  const { data, loading, error, refetch } = useQuery({
    url: '/labels',
    variables: {
      page,
      limit: 20,
      sortBy: sortFilter,
      name: debouncedSearch.trim().length ? debouncedSearch.trim() : undefined,
    },
    onError,
  });

  const onCreateLabel = useCallback(() => {
    addToast('Etiqueta creada con éxito', {
      appearance: 'success',
      autoDismiss: true,
    });
    setName('');
    setDescription('');
    setColor(randomColor().toUpperCase());
    setCreateMode(false);
    refetch();
  }, []);

  const [createLabel, { loading: creatingLabel }] = useMutation({
    url: '/labels',
    onCompleted: onCreateLabel,
    onError,
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!isRegexColor(color)) {
      return addToast(`${color} no es un color hexadecimal válido`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    createLabel({
      name,
      description: description.trim().length ? description.trim() : undefined,
      color,
    });
  };

  if (error) {
    return null;
  }

  return (
    <div className="labels-content">
      <Helmet>
        <title>
          {organization.name}: Etiquetas • {process.env.REACT_APP_NAME} Wallet
        </title>
      </Helmet>
      <Collapse
        in={createMode}
        onEntered={() => {
          if (nameInput.current) {
            nameInput.current.focus();
          }
        }}
      >
        <form
          className="px-3 py-2 mb-3 color-bg-default border color-border-default rounded"
          onSubmit={handleFormSubmit}
        >
          <div className="form-group mt-2 mb-1">
            <span
              className="Label"
              style={{
                color,
                borderColor: color,
              }}
            >
              {name.length > 0 ? name : 'Nueva Etiqueta'}
            </span>
          </div>
          <div className="clearfix d-flex flex-md-row flex-column flex-md-items-end flex-items-start mb-n2">
            <dl className="form-group col-md-3 col-12 pr-md-3 pr-0 my-2 my-md-3">
              <dt className="d-flex flex-justify-between flex-items-center">
                <label htmlFor="create-label-name" className="f5">
                  Nombre de la etiqueta
                </label>
              </dt>
              <dd>
                <input
                  ref={nameInput}
                  type="text"
                  id="create-label-name"
                  className="form-control width-full"
                  placeholder="Nombre de la etiqueta"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                  maxLength={50}
                  autoComplete="off"
                  spellCheck={false}
                  disabled={creatingLabel}
                  required
                />
              </dd>
            </dl>
            <dl className="form-group my-2 my-md-3 col-lg-4 col-md-3 col-12 pr-md-3 pr-0 flex-auto">
              <dt className="d-flex flex-justify-between flex-items-center">
                <label htmlFor="create-label-description" className="f5">
                  Descripción
                </label>
              </dt>
              <dd>
                <input
                  type="text"
                  id="create-label-description"
                  className="form-control width-full"
                  placeholder="Descripción (opcional)"
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                  maxLength={100}
                  autoComplete="off"
                  spellCheck={false}
                  disabled={creatingLabel}
                />
              </dd>
            </dl>
            <dl className="form-group my-2 my-md-3 col-md-2 col-12">
              <dt>
                <label htmlFor="create-label-color" className="f5">
                  Color
                </label>
              </dt>
              <dd className="d-flex">
                <button
                  type="button"
                  className="IssueLabel hx_IssueLabel flex-shrink-0 btn-link rounded-1 tooltipped tooltipped-w mr-2"
                  aria-label="Generar color"
                  onClick={() => setColor(randomColor().toUpperCase())}
                  disabled={creatingLabel}
                  style={{
                    color: fontColorContrast(color),
                    backgroundColor: color,
                  }}
                >
                  <SyncIcon />
                </button>
                <div className="position-relative flex-1">
                  <input
                    type="text"
                    id="create-label-color"
                    className="form-control input-monospace pb-1 mr-0 width-full"
                    value={color.length > 0 ? color : `#${color}`}
                    onChange={({ target }) =>
                      setColor(target.value.toUpperCase())
                    }
                    maxLength={7}
                    autoComplete="off"
                    spellCheck={false}
                    disabled={creatingLabel}
                    required
                  />
                </div>
              </dd>
            </dl>
            <div className="form-group my-2 my-md-3 ml-md-5 ml-0 col-lg-3 col-md-4 col-12 d-flex flex-md-justify-end flex-justify-start">
              <button
                type="button"
                className="flex-md-order-1 flex-order-2 btn mr-0 mr-md-2"
                onClick={() => setCreateMode(false)}
                disabled={creatingLabel}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="flex-order-1 flex-md-order-2 btn-primary btn mr-2 mr-md-0"
                disabled={creatingLabel}
              >
                {creatingLabel ? 'Guardando…' : 'Guardar'}
              </button>
            </div>
          </div>
        </form>
      </Collapse>
      <div className="subnav d-flex mb-3 flex-row">
        <div className="flex-auto min-width-0">
          <div className="subnav-search ml-0">
            <SearchIcon className="subnav-search-icon" />
            <input
              type="search"
              className="form-control subnav-search-input subnav-search-input-wide width-full"
              placeholder="Buscar etiquetas por su nombre…"
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              spellCheck={false}
              autoFocus
            />
          </div>
        </div>
        <div className="ml-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setCreateMode(true)}
            disabled={createMode}
          >
            Nueva <span className="d-none d-sm-inline-block">etiqueta</span>
          </button>
        </div>
      </div>
      <div className="Box mt-3 hx_Box--firstRowRounded0">
        <div className="Box-header d-flex flex-justify-between">
          <h3 className="Box-title">Etiquetas</h3>
          <div className="table-list-header-toggle no-wrap d-flex flex-auto flex-justify-end">
            <LabelSortFilter value={sortFilter} onChange={setSortFilter} />
          </div>
        </div>
        <div className="organization-list">
          {!loading && !data?.results?.length && (
            <div className="bg-primary blankslate blankslate-spacious text-center position-relative rounded-2">
              <img
                src="https://ghicons.github.com/assets/images/blue/png/Code%20hosting.png"
                alt="Workplace icon"
                className="d-block mb-3 mx-auto grayscale"
                draggable={false}
              />
              <h3 className="mb-1 color-fg-muted">
                No hay resultados que coincidan con su búsqueda
              </h3>
              <p className="container-sm color-fg-muted">
                Las etiquetas le ayudan a identificar y clasificar las
                credenciales de una organización. Puede aplicar etiquetas
                personalizadas a las credenciales.
              </p>
            </div>
          )}
          {Array.from(loading ? new Array(5) : data.results).map(
            (label, index) => (
              <LabelListItem
                key={loading ? `label-${index}` : label.id}
                loading={loading}
                label={label}
                onDelete={() => refetch()}
              />
            ),
          )}
        </div>
      </div>
      {!loading && data && (
        <Pagination
          pageInfo={{ currentPage: data.page, pageCount: data.totalPages }}
          onPageChange={setPage}
        />
      )}
    </div>
  );
}

OrganizationLabelsRoute.propTypes = {
  organization: PropTypes.object.isRequired,
};

/* eslint-disable indent */
import { PeopleIcon, TrashIcon } from '@primer/octicons-react';
import { UserAvatar } from 'components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function OrganizationMemberListItem({
  member,
  onRemove,
  isTeam = false,
  isAuthor = false,
}) {
  return (
    <div className="Box-row Box-row--focus-gray p-0 mt-0 selectable member-list-item">
      <div className="d-flex Box-row--drag-hide position-relative">
        <div className="flex-shrink-0 pl-3 pr-1 d-flex flex-items-center">
          <span
            className="tooltipped tooltipped-e"
            aria-label={isTeam ? 'Equipo' : 'Usuario'}
          >
            {isTeam ? (
              <PeopleIcon size={26} />
            ) : (
              <UserAvatar url={member.avatarURL} username={member.name} />
            )}
          </span>
        </div>
        <div className="flex-auto min-width-0 lh-condensed p-2 pr-3 pr-md-2">
          <Link
            to={isTeam ? `/teams/${member.id}` : `/users/${member.id}`}
            className="Link--primary v-align-middle no-underline h4"
          >
            {member.name}
          </Link>
          <div className="mt-1 text-small color-fg-muted">
            <span>
              {isTeam ? (
                <>
                  {member.description || 'Sin descripción'} ·{' '}
                  <span
                    className="tooltipped tooltipped-multiline tooltipped-n"
                    aria-label={
                      member.members.length > 1
                        ? `${member.members
                            .slice(0, -1)
                            .map((m) => m.name)
                            .join(', ')} y ${
                            member.members[member.members.length - 1].name
                          }`
                        : member.members.map((m) => m.name).join(', ')
                    }
                  >
                    {member.members.length} miembro
                    {member.members.length !== 1 ? 's' : ''}
                  </span>
                </>
              ) : (
                member.email
              )}
            </span>
          </div>
        </div>
        <div className="flex-shrink-0 col-3 text-right pr-3 no-wrap d-flex hide-sm">
          <span className="ml-2 flex-1 flex-shrink-0"></span>
          <span className="ml-2 flex-1 flex-shrink-0"></span>
          <span className="ml-2 flex-1 flex-shrink-0 d-flex flex-items-center flex-justify-end">
            {!isAuthor && (
              <button
                type="button"
                className="btn-octicon btn-octicon-danger tooltipped tooltipped-n"
                aria-label="Eliminar"
                onClick={() => onRemove(member)}
              >
                <TrashIcon />
              </button>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

OrganizationMemberListItem.propTypes = {
  member: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  isTeam: PropTypes.bool,
  isAuthor: PropTypes.bool,
};

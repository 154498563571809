import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from 'components';

import AdminRoutes from 'routes/admin';
import AuthRoutes from 'routes/auth';
import HomeRoute from 'routes/home';
import OrganizationRoutes from 'routes/organizations';
import TeamRoutes from 'routes/teams';
import UserRoutes from 'routes/users';
import UnknownRoute from 'routes/unknown';
import PasswordResetRoute from 'routes/auth/password-reset.route';

export default function ApplicationRoutes() {
  return (
    <Switch>
      <Route path="/auth" component={AuthRoutes} />
      <Route path="/password-reset" component={PasswordResetRoute} />
      <PrivateRoute exact path="/" component={HomeRoute} />
      <PrivateRoute path="/organizations" component={OrganizationRoutes} />
      <PrivateRoute path="/teams" component={TeamRoutes} />
      <PrivateRoute path="/users" component={UserRoutes} />
      <PrivateRoute path="/admin" component={AdminRoutes} />
      <PrivateRoute component={UnknownRoute} />
    </Switch>
  );
}

import { ApplicationLoader } from 'components';
import { useQuery } from 'hooks';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

export default function AdminDashboardRoute() {
  const { data, loading } = useQuery({
    url: '/admin/dashboard',
  });

  if (loading) return <ApplicationLoader />;

  return (
    <div className="flex-1">
      <Helmet>
        <title>Administración • {process.env.REACT_APP_NAME} Wallet</title>
      </Helmet>
      <div className="container-lg p-responsive mt-6">
        <ul className="d-flex flex-wrap flex-justify-start flex-items-stretch list-style-none gutter my-4">
          <li className="col-12 col-sm-6 col-md-6 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/admin/credential-requests"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded color-fg-muted">
                  <span className="h3-mktg">
                    {data.countPreviousMonthCredentialRequests}
                  </span>{' '}
                  / mes pasado
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-1 mt-2">
                  Solicitudes de credenciales
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Número de solicitudes que se realizaron de las credenciales en
                  las organizaciones por los usuarios
                </p>
              </Link>
            </div>
          </li>
          <li className="col-12 col-sm-6 col-md-6 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/admin/credential-requests"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded color-fg-muted">
                  <span className="h3-mktg">
                    {data.countCurrentMonthCredentialRequests}
                  </span>{' '}
                  / mes en curso
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-1 mt-2">
                  Solicitudes de credenciales
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Número de solicitudes que se realizaron de las credenciales en
                  las organizaciones por los usuarios
                </p>
              </Link>
            </div>
          </li>
          <li className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/organizations"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded color-fg-muted">
                  <span className="h3-mktg">{data.countOrganizations}</span>
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-1 mt-2">
                  Organizaci{data.countOrganizations !== 1 ? 'ones' : 'ón'}
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Número de organizaciones creadas en la plataforma
                </p>
              </Link>
            </div>
          </li>
          <li className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/teams"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded color-fg-muted">
                  <span className="h3-mktg">{data.countTeams}</span>
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-1 mt-2">
                  Equipo{data.countTeams !== 1 && 's'}
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Número de equipos creados en la plataforma
                </p>
              </Link>
            </div>
          </li>
          <li className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/users"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded color-fg-muted">
                  <span className="h3-mktg">{data.countUsers}</span>
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-1 mt-2">
                  Usuario{data.countUsers !== 1 && 's'}
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Número de usuarios creados en la plataforma
                </p>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

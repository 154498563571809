import { useCallback, useEffect, useMemo, useState } from 'react';
import WhiteLogo from 'images/logo-white.png';
import { ShieldLockIcon } from '@primer/octicons-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'hooks';

export default function PasswordResetRoute() {
  const { addToast } = useToasts();
  const { search } = useLocation();
  const history = useHistory();
  const [emailAddress, setEmailAdress] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(false);

  const token = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('token');
  }, [search]);

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [addToast],
  );

  const onEmailSent = useCallback(() => {
    setIsMailSent(true);
    addToast('Se ha enviado un correo electrónico con instrucciones', {
      appearance: 'success',
      autoDismiss: true,
    });
  }, [addToast]);

  const [sendEmail, { loading: sendingEmail }] = useMutation({
    url: '/auth/forgot-password',
    options: { method: 'POST' },
    onCompleted: onEmailSent,
    onError,
  });

  const onPasswordReset = useCallback(() => {
    addToast('La contraseña ha sido actualizada', {
      appearance: 'success',
      autoDismiss: true,
    });
    history.push('/auth/signin');
  }, []);

  const [resetPassword, { loading: resettingPassword }] = useMutation({
    url: `/auth/reset-password?token=${token}`,
    options: { method: 'POST' },
    onCompleted: onPasswordReset,
    onError,
  });

  useEffect(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsValidEmail(emailRegex.test(emailAddress));
  }, [emailAddress]);

  useEffect(() => {
    setIsValidPassword(
      newPassword.match(/\d/) &&
        newPassword.match(/[a-zA-Z]/) &&
        newPassword.length >= 8,
    );
  }, [newPassword]);

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    if (!isValidEmail) {
      addToast('El email no es válido', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    sendEmail({ email: emailAddress });
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      addToast('Las contraseñas no coinciden', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (!isValidPassword) {
      addToast('La contraseña no es válida', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    resetPassword({ password: newPassword });
  };

  if (token !== null) {
    return (
      <div className="flex-1 d-flex flex-items-center flex-justify-center position-relative">
        <div
          className="container-sm d-flex flex-column flex-items-center signin-content anim-fade-in"
          style={{ animationDuration: '1s' }}
        >
          <img
            src={WhiteLogo}
            className="user-select-none"
            alt="Sitelicon Ecommerce Services"
            width={200}
            draggable={false}
          />
          <div
            className="Box color-shadow-medium border-top-0 border-left-0 border-bottom-0 border-right-0 anim-fade-up"
            style={{ animationDelay: '250ms', animationDuration: '250ms' }}
          >
            <div className="Box-body signin-box">
              <form onSubmit={handleResetPassword}>
                <div className="text-center" style={{ color: '#ff7700' }}>
                  <ShieldLockIcon size="large" />
                </div>
                <h1 className="text-center">
                  {process.env.REACT_APP_NAME}
                  <span style={{ marginLeft: 4 }}>Wallet</span>
                </h1>
                <p className="text-center color-fg-muted">
                  Mantenga sus datos seguros, de forma fácil y sencilla.
                </p>
                <div className="form-group">
                  <div className="form-group-header">
                    <label htmlFor="new-password">Nueva contraseña</label>
                  </div>
                  <div className="form-group-body">
                    <input
                      type="password"
                      id="new-password"
                      className="form-control"
                      placeholder="Escriba su nueva contraseña"
                      value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                      autoComplete="off"
                      required
                    />
                    <p className="note">
                      Asegurese de que su nueva contraseña tenga al menos 8
                      caracteres incluyendo un número y una letra.
                    </p>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-header">
                    <label htmlFor="confirm-password">
                      Confirmar contraseña
                    </label>
                  </div>
                  <div className="form-group-body">
                    <input
                      type="password"
                      id="confirm-password"
                      className="form-control"
                      placeholder="Confirme su nueva contraseña"
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
                <div className="form-actions">
                  <button
                    type="submit"
                    className="btn-mktg width-full"
                    disabled={!isValidPassword || resettingPassword}
                  >
                    {resettingPassword
                      ? 'Restableceiendo contraseña...'
                      : 'Restablecer contraseña'}
                  </button>
                  <p className="mt-5 text-center">
                    ¿No es lo que buscaba?{' '}
                    <button
                      type="button"
                      className="btn-link"
                      onClick={() => history.goBack()}
                    >
                      Volver a la página anterior
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="signin-bg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1903 556"
            fill="#f6f8fa"
          >
            <path d="M753.1,434.2c110.6,63.7,277.7,70.6,373.4,15.4L1905,0v555.9H0V0.2L753.1,434.2z"></path>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 d-flex flex-items-center flex-justify-center position-relative">
      <div
        className="container-sm d-flex flex-column flex-items-center signin-content anim-fade-in"
        style={{ animationDuration: '1s' }}
      >
        <img
          src={WhiteLogo}
          className="user-select-none"
          alt="Sitelicon Ecommerce Services"
          width={200}
          draggable={false}
        />
        <div
          className="Box color-shadow-medium border-top-0 border-left-0 border-bottom-0 border-right-0 anim-fade-up"
          style={{ animationDelay: '250ms', animationDuration: '250ms' }}
        >
          <div className="Box-body signin-box">
            <form onSubmit={handleEmailSubmit}>
              <div className="text-center" style={{ color: '#ff7700' }}>
                <ShieldLockIcon size="large" />
              </div>
              <h1 className="text-center">
                {process.env.REACT_APP_NAME}
                <span style={{ marginLeft: 4 }}>Wallet</span>
              </h1>
              <p className="text-center color-fg-muted">
                Mantenga sus datos seguros, de forma fácil y sencilla.
              </p>
              {isMailSent ? (
                <>
                  <p className="mt-3 text-bold">
                    Por favor, siga las intrucciones del correo electrónico que
                    le hemos enviado para restablecer su contraseña.
                  </p>
                  <div className="form-actions">
                    <p className="mt-3 text-center">
                      ¿No es lo que buscaba?{' '}
                      <button
                        type="button"
                        className="btn-link"
                        onClick={() => history.goBack()}
                      >
                        Volver a la página anterior
                      </button>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group">
                    <div className="form-group-header">
                      <label htmlFor="emailAddress">
                        Introduzca la dirección de correo electrónico de su
                        cuenta de usuario y le enviaremos un enlace para
                        restablecer la contraseña.
                      </label>
                    </div>
                    <div className="form-group-body mt-3">
                      <input
                        type="email"
                        className="form-control input-block"
                        id="emailAddress"
                        value={emailAddress}
                        onChange={({ target }) => setEmailAdress(target.value)}
                        autoComplete="off"
                        spellCheck={false}
                        autoFocus
                        required
                      />
                    </div>
                  </div>
                  <div className="form-actions">
                    <button
                      type="submit"
                      className="btn-mktg width-full"
                      disabled={!isValidEmail || sendingEmail}
                    >
                      {sendingEmail
                        ? 'Enviando correo electrónico de restablecimiento…'
                        : 'Enviar correo electrónico de restablecimiento'}
                    </button>
                    <p className="mt-5 text-center">
                      ¿No es lo que buscaba?{' '}
                      <button
                        type="button"
                        className="btn-link"
                        onClick={() => history.goBack()}
                      >
                        Volver a la página anterior
                      </button>
                    </p>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="signin-bg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1903 556"
          fill="#f6f8fa"
        >
          <path d="M753.1,434.2c110.6,63.7,277.7,70.6,373.4,15.4L1905,0v555.9H0V0.2L753.1,434.2z"></path>
        </svg>
      </div>
    </div>
  );
}

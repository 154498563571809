import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useLazyQuery, useMutation } from 'hooks';
import {
  PasteIcon,
  LinkExternalIcon,
  TrashIcon,
  PencilIcon,
} from '@primer/octicons-react';
import PropTypes from 'prop-types';
import { CredentialEditorDialog } from 'components';

export default function CredentialListItem({ credential, refetchCredentials }) {
  const { addToast } = useToasts();
  const [loginLabel, setLoginLabel] = useState('Copiar');
  const [passwordLabel, setPasswordLabel] = useState('Copiar');
  const [editorMode, setEditorMode] = useState(false);

  const copyLogin = () => {
    navigator.clipboard.writeText(credential.login);
    setLoginLabel('¡Copiado!');
    setTimeout(() => setLoginLabel('Copiar'), 2000);
  };

  const [getPassword, { loading }] = useLazyQuery({
    url: '/credentials',
    onCompleted: (res) => {
      navigator.clipboard.writeText(res.password);
      setPasswordLabel('¡Copiado!');
      setTimeout(() => setPasswordLabel('Copiar'), 2000);
    },
    onError: (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
  });

  const [deleteCredential, { loading: deletingCredential }] = useMutation({
    url: `/credentials/${credential.id}`,
    options: { method: 'DELETE' },
    onCompleted: () => {
      addToast('Credencial eliminada', {
        appearance: 'success',
        autoDismiss: true,
      });
      refetchCredentials();
    },
    onError: (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
  });

  const handleDelete = () => {
    if (
      window.confirm(
        '¿Está seguro? Tras eliminar una credencial no se puede recuperar.',
      )
    ) {
      deleteCredential();
    }
  };

  return (
    <tr key={credential.id} className="credential-list-item">
      <td className="text-center no-wrap" width={1}>
        {credential.label && (
          <span
            className="Label"
            style={{
              color: credential.label.color,
              borderColor: credential.label.color,
            }}
          >
            {credential.label.name}
          </span>
        )}
      </td>
      <td className="no-wrap">
        <button
          type="button"
          className="btn-octicon m-0 tooltipped tooltipped-n tooltipped-no-delay"
          aria-label={loginLabel}
          onClick={copyLogin}
        >
          <PasteIcon />
        </button>
        {credential.login}
      </td>
      <td className="no-wrap">
        <button
          type="button"
          className="btn-octicon m-0 tooltipped tooltipped-n tooltipped-no-delay"
          aria-label={passwordLabel}
          onClick={() => getPassword({}, `/${credential.id}`)}
          disabled={loading}
        >
          <PasteIcon />
        </button>
        <span className="hidden-password">••••••••••••••</span>
      </td>
      <td>{credential.description}</td>
      <td className="text-right no-wrap" width={1}>
        {credential.url && (
          <a
            href={credential.url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-octicon m-0 tooltipped tooltipped-n"
            aria-label="Abrir enlace externo"
          >
            <LinkExternalIcon />
          </a>
        )}
        <button
          type="button"
          className="btn-octicon tooltipped tooltipped-n"
          aria-label="Editar"
          onClick={() => setEditorMode(true)}
        >
          <PencilIcon />
        </button>
        <button
          type="button"
          className="btn-octicon btn-octicon-danger tooltipped tooltipped-n"
          aria-label="Eliminar"
          onClick={handleDelete}
          disabled={deletingCredential}
        >
          <TrashIcon />
        </button>
      </td>
      <CredentialEditorDialog
        open={editorMode}
        onClose={() => setEditorMode(false)}
        credential={credential}
        refetchCredentials={refetchCredentials}
      />
    </tr>
  );
}

CredentialListItem.propTypes = {
  credential: PropTypes.object.isRequired,
  refetchCredentials: PropTypes.func.isRequired,
};

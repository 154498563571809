import { useMutation } from 'hooks';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';

export default function PasswordSettingsRoute({ user }) {
  const { addToast } = useToasts();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onError = useCallback((error) =>
    addToast(error, { appearance: 'error', autoDismiss: true }),
  );

  const onCompleted = useCallback(() => {
    addToast('La contraseña ha sido actualizada', {
      appearance: 'success',
      autoDismiss: true,
    });
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
  });

  const [changePassword, { loading }] = useMutation({
    url: `/auth/change-password`,
    options: { method: 'POST' },
    onError,
    onCompleted,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      addToast('Las contraseñas no coinciden', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    changePassword({
      userId: user.id,
      oldPassword: password,
      newPassword,
    });
  };

  return (
    <div>
      <div className="Subhead hx_Subhead--responsive">
        <h2 className="Subhead-heading">Cambiar contraseña</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="form-group-header">
            <label htmlFor="password">Contraseña actual</label>
          </div>
          <div className="form-group-body">
            <input
              type="password"
              className="form-control"
              id="password"
              name="old-password"
              placeholder="Contraseña actual"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              autoComplete="off"
              spellCheck={false}
              disabled={loading}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-group-header">
            <label htmlFor="new-password">Nueva contraseña</label>
          </div>
          <div className="form-group-body">
            <input
              type="password"
              className="form-control"
              id="new-password"
              name="new-password"
              placeholder="Nueva contraseña"
              value={newPassword}
              onChange={({ target }) => setNewPassword(target.value)}
              autoComplete="off"
              spellCheck={false}
              disabled={loading}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-group-header">
            <label htmlFor="confirm-password">Confirmar nueva contraseña</label>
          </div>
          <div className="form-group-body">
            <input
              type="password"
              className="form-control"
              id="confirm-password"
              name="confirm-new-password"
              placeholder="Confirmar nueva contraseña"
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
              autoComplete="off"
              spellCheck={false}
              disabled={loading}
              required
            />
          </div>
        </div>
        <p className="note mb-3">
          Asegurese de que tiene al menos 15 caracteres.
          <br />O al menos 8 caracteres incluyendo un número y una letra.
        </p>
        <p>
          <button
            type="submit"
            className="btn btn-primary mr-2"
            disabled={loading}
          >
            {loading ? 'Actualizando contraseña…' : 'Actualizar contraseña'}
          </button>
          <Link to="/password-reset" className="btn btn-invisible">
            He olvidado mi contraseña
          </Link>
        </p>
      </form>
    </div>
  );
}

PasswordSettingsRoute.propTypes = {
  user: PropTypes.object.isRequired,
};

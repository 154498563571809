import { useMutation } from 'hooks';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { isEmail } from 'utils';

export default function UserCreateRoute() {
  const history = useHistory();
  const { addToast } = useToasts();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [createUser, { loading }] = useMutation({
    url: '/users',
    options: { method: 'POST' },
    onCompleted: (user) => {
      addToast('Cuenta creada', { appearance: 'success', autoDismiss: true });
      history.push(`/users/${user.id}`);
    },
    onError: (error) =>
      addToast(error, { appearance: 'error', autoDismiss: true }),
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!isEmail(email)) {
      return addToast('La dirección de correo no es válida', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    if (confirmPassword !== password) {
      return addToast('Las contraseñas no coinciden', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    createUser({ name, email, password });
  };

  return (
    <div className="flex-1 py-3">
      <Helmet>
        <title>Usuarios • {process.env.REACT_APP_NAME} Wallet</title>
      </Helmet>
      <div className="container-lg p-responsive">
        <div className="Box">
          <div className="Box-body">
            <div className="Subhead">
              <div className="Subhead-heading">Crear un nuevo usuario</div>
              <div className="Subhead-description">
                Los usuarios son cuentas de acceso a la plataforma.
              </div>
            </div>
            <form onSubmit={handleFormSubmit}>
              <div className="d-flex px-3 gutter-condensed">
                <dl className="col-md-6 form-group mt-0">
                  <dt>
                    <label htmlFor="user-name">Nombre y apellidos</label>
                  </dt>
                  <dd>
                    <input
                      type="text"
                      id="user-name"
                      name="new-fullname"
                      className="form-control"
                      placeholder="Nombre y apellidos"
                      value={name}
                      onChange={({ target }) => setName(target.value)}
                      autoComplete="new-fullname"
                      spellCheck={false}
                      autoFocus
                      required
                    />
                  </dd>
                </dl>
                <dl className="form-group col-md-6 mt-0">
                  <dt>
                    <label htmlFor="user-email">Dirección de correo</label>
                  </dt>
                  <dd>
                    <input
                      type="email"
                      id="user-email"
                      name="new-email"
                      className="form-control"
                      placeholder="Dirección de correo"
                      value={email}
                      onChange={({ target }) => setEmail(target.value)}
                      autoComplete="new-email"
                      spellCheck={false}
                      required
                    />
                  </dd>
                </dl>
              </div>
              <div className="d-flex px-3 gutter-condensed">
                <dl className="col-md-6 form-group mt-0">
                  <dt>
                    <label htmlFor="user-password">Contraseña</label>
                  </dt>
                  <dd>
                    <input
                      type="password"
                      id="user-password"
                      name="new-password"
                      className="form-control"
                      placeholder="Contraseña"
                      value={password}
                      onChange={({ target }) => setPassword(target.value)}
                      autoComplete="new-password"
                      spellCheck={false}
                      required
                    />
                  </dd>
                </dl>
                <dl className="form-group col-md-6 mt-0">
                  <dt>
                    <label htmlFor="user-confirm-password">
                      Repetir contraseña
                    </label>
                  </dt>
                  <dd>
                    <input
                      type="password"
                      id="user-confirm-password"
                      name="repeat-passoword"
                      className="form-control"
                      placeholder="Repetir contraseña"
                      value={confirmPassword}
                      onChange={({ target }) =>
                        setConfirmPassword(target.value)
                      }
                      autoComplete="off"
                      spellCheck={false}
                      required
                    />
                  </dd>
                </dl>
              </div>
              <div className="form-actions border-top pt-3 mt-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Creando usuario…' : 'Crear usuario'}
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={() => history.goBack()}
                  disabled={loading}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

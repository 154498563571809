import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'var(--color-fg-default) !important',
        },
        caption: {
          color: 'var(--color-fg-muted) !important',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: 'var(--color-fg-default)',
          '&.Mui-disabled': {
            color: 'var(--color-fg-subtle) !important',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'var(--color-fg-default)',
          '&.Mui-disabled': {
            color: 'var(--color-fg-subtle) !important',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: 'var(--color-fg-default) !important',
          background: 'var(--color-bg-default) !important',
          '&.Mui-disabled': {
            color: 'var(--color-fg-subtle) !important',
          },
          '&.Mui-selected': {
            color: 'var(--color-btn-primary-text) !important',
            backgroundColor: 'var(--color-btn-primary-bg) !important',
          },
        },
        today: {
          borderColor: 'var(--color-border-default) !important',
        },
      },
    },
  },
});

export default theme;

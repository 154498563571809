import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from 'hooks';
import {
  Modal,
  Backdrop,
  CircularProgress,
  Fade,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  BriefcaseIcon,
  MentionIcon,
  PeopleIcon,
  ReplyIcon,
  SearchIcon,
} from '@primer/octicons-react';
import { useTheme } from 'context/theme';
import { withStyles } from '@material-ui/styles';
import scrollIntoView from 'scroll-into-view';

const StyledCircularProgress = withStyles({
  colorPrimary: {
    position: 'absolute',
    left: 0,
    color: '#57606a',
  },
  colorSecondary: {
    color: '#d0d0d0',
  },
})(CircularProgress);

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(32, 38, 45, 0.7)',
    backdropFilter: 'blur(2px)',
  },
});

const escapeRegex = (str) => str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export default function QuickSwitcher({ open = false, onClose = () => {} }) {
  const classes = useStyles();
  const history = useHistory();
  const { isDarkMode } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [maxResults, setMaxResults] = useState(0);
  const [data, setData] = useState(undefined);

  const onSearchSuccess = useCallback((response) => {
    setData(response);
    setSelectedIndex(0);
    const userCount = response?.users?.length || 0;
    const organizationsCount = response?.organizations?.length || 0;
    const teamsCount = response?.teams?.length || 0;
    setMaxResults(userCount + organizationsCount + teamsCount);
  }, []);

  const [search, { loading }] = useLazyQuery({
    url: '/search',
    onCompleted: onSearchSuccess,
  });

  const query = useMemo(() => escapeRegex(searchQuery), [searchQuery]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      setData(undefined);
    } else if (searchQuery.length > 0) {
      const timeout = setTimeout(() => search({ query, limit: 5 }), 250);
      return () => clearTimeout(timeout);
    }
  }, [searchQuery, search]);

  const handleClose = () => {
    setSearchQuery('');
    setMaxResults(0);
    onClose();
  };

  const handleInputKeyDown = (event) => {
    switch (event.key) {
      case 'Escape':
        if (searchQuery.length > 0) {
          event.stopPropagation();
        }
        break;
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();
        if (!data || maxResults === 0) break;
        if (selectedIndex < data.organizations.length) {
          history.push(
            `/organizations/${data.organizations[selectedIndex].id}`,
          );
          handleClose();
          break;
        }
        if (selectedIndex < data.organizations.length + data.teams.length) {
          history.push(
            `/teams/${
              data.teams[selectedIndex - data.organizations.length].id
            }`,
          );
          handleClose();
          break;
        }
        history.push(
          `/users/${
            data.users[
              selectedIndex - data.organizations.length - data.teams.length
            ].id
          }`,
        );
        handleClose();
        break;
      case 'ArrowUp':
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex((prev) => {
          const index = prev === 0 ? maxResults - 1 : prev - 1;
          scrollIntoView(document.getElementById(`suggestion-item-${index}`), {
            time: 150,
          });
          return index;
        });
        break;
      case 'ArrowDown':
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex((prev) => {
          const index = prev === maxResults - 1 ? 0 : prev + 1;
          scrollIntoView(document.getElementById(`suggestion-item-${index}`), {
            time: 150,
          });
          return index;
        });
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 250, classes: { root: classes.backdrop } }}
      closeAfterTransition
    >
      <Fade in={open}>
        <div
          className="suggestions-wrapper d-flex flex-column flex-items-center flex-justify-center width-full py-4 p-responsive"
          style={{ outline: 'none' }}
        >
          <div
            className="search-results"
            data-color-mode={isDarkMode ? 'dark' : 'light'}
            data-light-theme={isDarkMode ? null : 'light'}
            data-dark-theme={isDarkMode ? 'dark' : null}
          >
            <div className="d-flex flex-items-center py-3 px-4">
              <label
                id="suggestions-label"
                htmlFor="suggestions-input"
                className="color-fg-muted position-relative m-0"
                style={{ top: 3 }}
              >
                <SearchIcon size={26} />
              </label>
              <input
                role="combobox"
                type="search"
                id="suggestions-input"
                className="form-control flex-auto px-3 border-0 box-shadow-none f3"
                placeholder="¿A dónde te gustaría ir?"
                value={searchQuery}
                onChange={({ target }) => setSearchQuery(target.value)}
                onKeyDown={handleInputKeyDown}
                aria-autocomplete="both"
                aria-haspopup="true"
                aria-labelledby="suggestions-label"
                aria-expanded={maxResults > 0}
                aria-activedescendant={`suggestion-item-${selectedIndex}`}
                aria-controls="suggestions-list"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
                autoFocus
              />
              {loading && (
                <div className="position-relative pr-3 d-flex flex-items-center">
                  <StyledCircularProgress
                    variant="determinate"
                    color="secondary"
                    size={20}
                    thickness={4}
                    value={100}
                  />
                  <StyledCircularProgress
                    variant="indeterminate"
                    size={20}
                    thickness={4}
                  />
                </div>
              )}
              <button
                type="button"
                className="search-results-close"
                onClick={handleClose}
              >
                esc
              </button>
            </div>
            <div
              className="search-results-container scroller auto scrollerBase disableScrollAnchor border-top"
              style={{ overflow: 'hidden scroll' }}
            >
              {data?.organizations?.length > 0 && (
                <>
                  <div className="search-result-source">Organizaciones</div>
                  <ul
                    role="listbox"
                    id="suggestions-list"
                    className="p-0 m-0 jump-to-suggestions-results-container"
                  >
                    {data.organizations.map((organization, index) => (
                      <li
                        key={organization.id}
                        id={`suggestion-item-${index}`}
                        role="option"
                        className="d-flex flex-justify-start flex-items-center p-0 f5 navigation-item navigation-focus"
                        aria-selected={selectedIndex === index}
                        aria-labelledby="suggestions-label"
                        onTouchStart={() => setSelectedIndex(index)}
                        onMouseMove={() => setSelectedIndex(index)}
                      >
                        <Link
                          to={`/organizations/${organization.id}`}
                          className="no-underline d-flex flex-auto flex-items-center jump-to-suggestions-path"
                          onClick={handleClose}
                        >
                          <div className="jump-to-octicon flex-shrink-0 text-center">
                            <BriefcaseIcon
                              size="medium"
                              className="flex-shrink-0"
                            />
                          </div>
                          <div
                            className="jump-to-suggestion-name ml-3 flex-auto overflow-hidden text-left no-wrap"
                            aria-label={organization.name}
                          >
                            <div className="d-flex flex-items-center text-bold">
                              {organization.name}
                            </div>
                            <div className="f6 css-truncate css-truncate-target width-fit">
                              {organization.description || (
                                <span className="text-italic">
                                  Sin descripción
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="jump-to-octicon jump-to-octicon-link">
                            <ReplyIcon />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {data?.teams?.length > 0 && (
                <>
                  <div className="search-result-source">Equipos</div>
                  <ul
                    role="listbox"
                    id="suggestions-list"
                    className="p-0 m-0 jump-to-suggestions-results-container"
                  >
                    {data.teams.map((team, index) => (
                      <li
                        key={team.id}
                        id={`suggestion-item-${index}`}
                        role="option"
                        className="d-flex flex-justify-start flex-items-center p-0 f5 navigation-item navigation-focus"
                        aria-selected={
                          selectedIndex === index + data.organizations.length
                        }
                        aria-labelledby="suggestions-label"
                        onTouchStart={() =>
                          setSelectedIndex(index + data.organizations.length)
                        }
                        onMouseMove={() =>
                          setSelectedIndex(index + data.organizations.length)
                        }
                      >
                        <Link
                          to={`/teams/${team.id}`}
                          className="no-underline d-flex flex-auto flex-items-center jump-to-suggestions-path"
                          onClick={handleClose}
                        >
                          <div className="jump-to-octicon flex-shrink-0 text-center">
                            <PeopleIcon
                              size="medium"
                              className="flex-shrink-0"
                            />
                          </div>
                          <div
                            className="jump-to-suggestion-name ml-3 flex-auto overflow-hidden text-left no-wrap"
                            aria-label={team.name}
                          >
                            <div className="d-flex flex-items-center text-bold">
                              {team.name}
                            </div>
                            <div className="f6 css-truncate css-truncate-target width-fit">
                              {team.description || (
                                <span className="text-italic">
                                  Sin descripción
                                </span>
                              )}{' '}
                              · {team.members.length} miembro
                              {team.members.length !== 1 && 's'}
                            </div>
                          </div>
                          <div className="jump-to-octicon jump-to-octicon-link">
                            <ReplyIcon />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {data?.users?.length > 0 && (
                <>
                  <div className="search-result-source">Usuarios</div>
                  <ul
                    role="listbox"
                    id="suggestions-list"
                    className="p-0 m-0 jump-to-suggestions-results-container"
                  >
                    {data.users.map((user, index) => (
                      <li
                        key={user.id}
                        role="option"
                        id={`suggestion-item-${
                          index + data.organizations.length + data.teams.length
                        }`}
                        className="d-flex flex-justify-start flex-items-center p-0 f5 navigation-item navigation-focus"
                        aria-selected={
                          selectedIndex ===
                          index + data.organizations.length + data.teams.length
                        }
                        aria-labelledby="suggestions-label"
                        onTouchStart={() =>
                          setSelectedIndex(
                            index +
                              data.organizations.length +
                              data.teams.length,
                          )
                        }
                        onMouseMove={() =>
                          setSelectedIndex(
                            index +
                              data.organizations.length +
                              data.teams.length,
                          )
                        }
                      >
                        <Link
                          to={`/users/${user.id}`}
                          className="no-underline d-flex flex-auto flex-items-center jump-to-suggestions-path"
                          onClick={handleClose}
                        >
                          <div className="jump-to-octicon flex-shrink-0 text-center">
                            <MentionIcon
                              size="medium"
                              className="flex-shrink-0"
                            />
                          </div>
                          <div
                            className="jump-to-suggestion-name ml-3 flex-auto overflow-hidden text-left no-wrap css-truncate css-truncate-target"
                            aria-label={user.name}
                          >
                            <div className="text-bold">{user.name}</div>
                            <div className="f6">{user.email}</div>
                          </div>
                          <div className="jump-to-octicon jump-to-octicon-link">
                            <ReplyIcon />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {data && maxResults === 0 && (
                <div className="blankslate">
                  <img
                    src="https://ghicons.github.com/assets/images/blue/png/Explore.png"
                    className="grayscale mb-2"
                    draggable={false}
                    alt="No match"
                  />
                  <h3 className="mb-1">No hemos encontrado resultados</h3>
                  <p className="f6 color-fg-muted">
                    Prueba a buscar algo distinto o más específico.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

QuickSwitcher.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

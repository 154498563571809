import { useCallback, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { KebabHorizontalIcon, SyncIcon } from '@primer/octicons-react';
import PropTypes from 'prop-types';
import { fontColorContrast, isRegexColor } from 'utils';
import randomcolor from 'randomcolor';
import { useMutation } from 'hooks';
import { useToasts } from 'react-toast-notifications';

export default function LabelListItem({ label, loading, onDelete }) {
  const { addToast } = useToasts();
  const [editionMode, setEditionMode] = useState(false);
  const [name, setName] = useState(loading ? '' : label.name);
  const [description, setDescription] = useState(
    loading ? '' : label.description,
  );
  const [color, setColor] = useState(loading ? '' : label.color);

  const handleCancelEdit = (event) => {
    event.preventDefault();
    setName(label.name);
    setDescription(label.description);
    setColor(label.color);
    setEditionMode(false);
  };

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [],
  );

  const onUpdateLabel = useCallback((response) => {
    setName(response.name);
    setDescription(response.description);
    setColor(response.color);
    setEditionMode(false);
    addToast('Etiqueta actualizada con éxito', {
      appearance: 'success',
      autoDismiss: true,
    });
  }, []);

  const onDeleteLabel = useCallback(() => {
    addToast(`La etiqueta ${label.name} se ha eliminado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
    onDelete();
  }, []);

  const [deleteLabel, { loading: deletingLabel }] = useMutation({
    url: loading ? undefined : `/labels/${label.id}`,
    options: { method: 'DELETE' },
    onCompleted: onDeleteLabel,
    onError,
  });

  const [updateLabel, { loading: updatingLabel }] = useMutation({
    url: loading ? undefined : `/labels/${label.id}`,
    options: { method: 'PATCH' },
    onCompleted: onUpdateLabel,
    onError,
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!isRegexColor(color)) {
      return addToast(`${color} is not a valid hexadecimal color`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    updateLabel({ name, description, color });
  };

  const handleDeleteLabel = (event) => {
    event.preventDefault();
    if (
      window.confirm(
        `¿Está seguro de que quiere eliminar la etiqueta ${label.name}? Se eliminará de cualquier credencial que lleve esta etiqueta.`,
      )
    ) {
      deleteLabel();
    }
  };

  return (
    <div className="Box-row Box-row--focus-gray p-3 m-0 selectable label-list-item d-flex flex-justify-between flex-md-items-baseline flex-items-center flex-wrap">
      <div className="col-md-3 col-9">
        {loading ? (
          <Skeleton
            height={10}
            width="60%"
            variant="rect"
            className="rounded-1"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.11)' }}
          />
        ) : (
          <span
            className="Label"
            style={{
              color,
              borderColor: color,
            }}
          >
            {name}
          </span>
        )}
      </div>
      <div className="d-md-block d-none col-4 f6 color-fg-muted pr-3">
        {!editionMode && (
          <div className="text-small color-fg-muted">
            {loading ? (
              <Skeleton
                height={10}
                width="100%"
                variant="rect"
                className="rounded-1"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.11)' }}
              />
            ) : (
              <span>{description}</span>
            )}
          </div>
        )}
      </div>
      <div className="col-3 f6 color-fg-muted pr-3 d-md-block d-none"></div>
      <div className="col-md-2 col-3 f6 d-flex flex-justify-end">
        <div className="BtnGroup d-block d-lg-none">
          <details className="dropdown details-reset details-overlay BtnGroup-item">
            <summary
              role="button"
              aria-haspopup="true"
              data-view-component="true"
              className="btn-outline btn-sm btn"
            >
              <KebabHorizontalIcon />
            </summary>
            <ul className="dropdown-menu dropdown-menu-sw">
              {!editionMode && (
                <li>
                  <button
                    type="button"
                    className="dropdown-item btn-link"
                    onClick={() => setEditionMode(true)}
                  >
                    Editar
                  </button>
                </li>
              )}
              <li>
                <button
                  type="button"
                  className="dropdown-item btn-link"
                  onClick={handleDeleteLabel}
                  disabled={updatingLabel || deletingLabel}
                >
                  Eliminar
                </button>
              </li>
            </ul>
          </details>
        </div>
        <div className="d-lg-block d-none">
          {!editionMode && (
            <button
              type="button"
              className="Link--secondary btn-link ml-3"
              onClick={() => setEditionMode(true)}
            >
              Editar
            </button>
          )}
          <button
            type="button"
            className="btn-link Link--secondary ml-3"
            onClick={handleDeleteLabel}
            disabled={updatingLabel || deletingLabel}
          >
            Eliminar
          </button>
        </div>
      </div>
      {editionMode && (
        <form className="width-full" onSubmit={handleFormSubmit}>
          <div className="clearfix d-flex flex-md-row flex-column flex-md-items-end flex-items-start mb-n2 mt-2">
            <dl className="form-group col-md-3 col-12 pr-md-3 pr-0 my-2 my-md-3">
              <dt className="mb-0 d-flex flex-justify-between flex-items-center">
                <label htmlFor="label-name" className="f5">
                  Nombre de la etiqueta
                </label>
              </dt>
              <dd>
                <input
                  type="text"
                  id="label-name"
                  className="form-control width-full"
                  placeholder="Nombre de la etiqueta"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                  maxLength={50}
                  autoComplete="off"
                  spellCheck={false}
                  disabled={updatingLabel || deletingLabel}
                  required
                />
              </dd>
            </dl>
            <dl className="form-group my-2 my-md-3 col-lg-4 col-md-3 col-12 pr-md-3 pr-0 flex-auto">
              <dt className="d-flex flex-justify-between flex-items-center mb-0">
                <label htmlFor="label-description" className="f5">
                  Descripción
                </label>
              </dt>
              <dd>
                <input
                  type="text"
                  id="label-description"
                  className="form-control width-full"
                  placeholder="Descripción (opcional)"
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                  maxLength={100}
                  autoComplete="off"
                  spellCheck={false}
                  disabled={updatingLabel || deletingLabel}
                />
              </dd>
            </dl>
            <dl className="form-group my-2 my-md-3 col-md-2 col-12">
              <dt className="mb-0">
                <label htmlFor="label-color" className="f5">
                  Color
                </label>
              </dt>
              <dd className="d-flex">
                <button
                  type="button"
                  className="IssueLabel hx_IssueLabel flex-shrink-0 btn-link rounded-1 tooltipped tooltipped-w mr-2"
                  aria-label="Generar color"
                  onClick={() => setColor(randomcolor().toUpperCase())}
                  disabled={updatingLabel || deletingLabel}
                  style={{
                    color: fontColorContrast(color),
                    backgroundColor: color,
                  }}
                >
                  <SyncIcon />
                </button>
                <div className="position-relative flex-1">
                  <input
                    type="text"
                    id="label-color"
                    className="form-control input-monospace pb-1 mr-0 width-full"
                    value={color.length > 0 ? color : `#${color}`}
                    onChange={({ target }) =>
                      setColor(target.value.toUpperCase())
                    }
                    maxLength={7}
                    autoComplete="off"
                    spellCheck={false}
                    disabled={updatingLabel || deletingLabel}
                    required
                  />
                </div>
              </dd>
            </dl>
            <div className="form-group my-2 my-md-3 ml-md-5 ml-0 col-lg-3 col-md-4 col-12 d-flex flex-md-justify-end flex-justify-start">
              <button
                type="button"
                className="flex-md-order-1 flex-order-2 btn mr-0 mr-md-2"
                onClick={handleCancelEdit}
                disabled={updatingLabel || deletingLabel}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="flex-order-1 flex-md-order-2 btn-primary btn mr-2 mr-md-0"
                disabled={updatingLabel || deletingLabel}
              >
                {updatingLabel ? 'Guardando…' : 'Guardar'}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

LabelListItem.propTypes = {
  label: PropTypes.object,
  loading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

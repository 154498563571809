import { LinearProgress, withStyles } from '@material-ui/core';

const StyledLinearProgress = withStyles(() => ({
  colorPrimary: {
    backgroundColor: 'rgba(255, 119, 0, 0.4)',
  },
  barColorPrimary: {
    backgroundColor: '#ff7700',
  },
}))(LinearProgress);

export default StyledLinearProgress;

import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  CredentialRequestsSortFilter,
  DatePickerFilter,
  Pagination,
} from 'components';
import { useQuery } from 'hooks';
import { Link } from 'react-router-dom';
import { BriefcaseIcon, KeyIcon } from '@primer/octicons-react';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';

const loadingArray = [
  'loading-1',
  'loading-2',
  'loading-3',
  'loading-4',
  'loading-5',
  'loading-6',
  'loading-7',
  'loading-8',
  'loading-9',
  'loading-10',
];

export default function LogSettingsRoute({ user }) {
  const [page, setPage] = useState(1);
  const [createdBefore, setCreatedBefore] = useState(undefined);
  const [createdAfter, setCreatedAfter] = useState(undefined);
  const [sortFilter, setSortFilter] = useState('createdAt:desc');

  const { data, loading } = useQuery({
    url: `/admin/credential-requests`,
    variables: {
      user: user.id,
      createdAfter: createdAfter
        ? createdAfter.startOf('day').toISOString()
        : undefined,
      createdBefore: createdBefore
        ? createdBefore.endOf('day').toISOString()
        : undefined,
      page,
      limit: 30,
      sortBy: sortFilter,
    },
  });

  return (
    <div>
      <div className="Subhead hx_Subhead--responsive">
        <h2 className="Subhead-heading">Registro de seguridad</h2>
      </div>
      <div className="Box hx_Box--firstRowRounded0">
        <div className="Box-header d-flex flex-justify-between">
          <div className="table-list-filters flex-auto d-flex min-width-0">
            <div className="flex-auto d-none d-lg-block no-wrap">
              <h2 className="Box-title">
                Histórico de solicitudes{' '}
                {!loading && (
                  <div className="Counter ml-1">{data.totalResults}</div>
                )}
              </h2>
            </div>
            <div className="table-list-header-toggle no-wrap d-flex flex-auto flex-justify-between flex-sm-justify-start flex-lg-justify-end">
              <DatePickerFilter
                label="Fecha desde"
                value={createdAfter ? createdAfter.startOf('day') : undefined}
                onChange={setCreatedAfter}
                minDate={moment('2020-01-01')}
                maxDate={
                  createdBefore && createdBefore.isBefore(moment())
                    ? createdBefore
                    : moment()
                }
              />
              <DatePickerFilter
                label="Fecha hasta"
                value={createdBefore ? createdBefore.endOf('day') : undefined}
                onChange={setCreatedBefore}
                minDate={createdAfter || moment('2020-01-01')}
                maxDate={moment()}
              />
              <CredentialRequestsSortFilter
                value={sortFilter}
                onChange={setSortFilter}
              />
            </div>
          </div>
        </div>
        <div className="credentials-list">
          <table className="width-full">
            <thead>
              <tr>
                <th colSpan={2}>Organización</th>
                <th colSpan={2}>Credencial</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
              {loading &&
                loadingArray.map((key) => (
                  <tr key={key}>
                    <td colSpan={5}>
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="70%" />
                    </td>
                  </tr>
                ))}
              {!loading &&
                data &&
                Array.from(data?.results || []).map((request) => (
                  <tr key={request.id} className="credential-list-item">
                    <td width={16}>
                      <BriefcaseIcon className="color-fg-muted" />
                    </td>
                    <td>
                      <Link
                        to={`/organizations/${request.credential.organization.id}`}
                        className="Link--primary text-bold"
                      >
                        {request.credential.organization.name}
                      </Link>
                      <div className="color-fg-subtle">
                        {request.credential.organization.description ||
                          'Sin descripción'}
                      </div>
                    </td>
                    <td width={16}>
                      <KeyIcon className="color-fg-muted" />
                    </td>
                    <td>
                      <div className="text-bold">
                        {request.credential.login}
                      </div>
                      <div className="color-fg-subtle">
                        {request.credential.description}
                      </div>
                    </td>
                    <td>{moment(request.createdAt).calendar()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {!loading && (
        <Pagination
          pageInfo={{ currentPage: data.page, pageCount: data.totalPages }}
          onPageChange={setPage}
        />
      )}
    </div>
  );
}

LogSettingsRoute.propTypes = {
  user: PropTypes.object.isRequired,
};

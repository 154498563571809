import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { UserAvatar } from 'components';
import PropTypes from 'prop-types';

export default function UserListItem({ user, loading }) {
  return (
    <div className="Box-row Box-row--focus-gray p-0 mt-0 selectable organization-list-item">
      <div className="d-flex Box-row--drag-hide position-relative">
        {!loading && (
          <div className="flex-shrink-0 pl-3 pr-1 d-flex flex-items-center">
            <UserAvatar url={user.avatarURL} username={user.name} />
          </div>
        )}
        <div className="flex-auto min-width-0 lh-condensed p-2 pr-3 pr-md-2">
          {loading ? (
            <div>
              <Skeleton height={10} variant="rect" className="rounded-1 mb-2" />
            </div>
          ) : (
            <Link
              to={`/users/${user.id}`}
              className="Link--primary v-align-middle no-underline h4"
            >
              {user.name}
            </Link>
          )}
          <div className="mt-1 text-small color-fg-muted">
            {loading ? (
              <Skeleton
                height={10}
                width="60%"
                variant="rect"
                className="rounded-1"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.11)' }}
              />
            ) : (
              <span>{user.email}</span>
            )}
          </div>
        </div>
        <div className="flex-shrink-0 col-3 pt-2 text-right pr-3 no-wrap d-flex hide-sm">
          <span className="ml-2 flex-1 flex-shrink-0"></span>
          <span className="ml-2 flex-1 flex-shrink-0"></span>
          <span className="ml-2 flex-1 flex-shrink-0"></span>
        </div>
      </div>
    </div>
  );
}

UserListItem.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
};

import { useHistory } from 'react-router-dom';

export default function UnknownRoute() {
  const history = useHistory();

  return (
    <div className="d-flex flex-1 py-3">
      <div className="d-flex flex-column flex-items-center flex-justify-center flex-1 container-lg p-responsive">
        <div className="color-fg-muted f1">404</div>
        <h2 className="h2">Página no encontrada</h2>
        <p>
          La página que está buscando no existe o ha dejado de existir.{' '}
          <button
            type="button"
            className="btn-link"
            onClick={() => history.goBack()}
          >
            Volver a la página anterior
          </button>
          .
        </p>
      </div>
    </div>
  );
}

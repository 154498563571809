/* eslint-disable no-control-regex */
import moment from 'moment';

export const isEmail = (value) =>
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(
    value,
  );

export const isSecurePassword = (value) =>
  !(!value.match(/\d/) || !value.match(/[a-zA-Z]/));

export const isToday = (value) => {
  const today = moment().startOf('day');
  return moment(value).isSame(today, 'day');
};

export const isYesterday = (value) => {
  const yesterday = moment().subtract(1, 'day').startOf('day');
  return moment(value).isSame(yesterday, 'day');
};

export const isTommorrow = (value) => {
  const tommorrow = moment().add(1, 'day').startOf('day');
  return moment(value).isSame(tommorrow, 'day');
};

export const isAfterToday = (value) => {
  const today = moment().startOf('day');
  return moment(value).isAfter(today, 'day');
};

export const isBeforeToday = (value) => {
  const today = moment().startOf('day');
  return moment(value).isBefore(today, 'day');
};

export const isSameWeek = (value) => {
  const week = moment().startOf('week');
  return moment(value).isSame(week, 'week');
};

export const isSameMonth = (value) => {
  const month = moment().startOf('month');
  return moment(value).isSame(month, 'month');
};

export const isSameYear = (value) => {
  const year = moment().startOf('year');
  return moment(value).isSame(year, 'year');
};

export const isRegexColor = (value) => /^#(?:[0-9a-f]{3}){1,2}$/i.test(value);

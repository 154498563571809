import { useAccount } from 'context/account';
import { Link } from 'react-router-dom';
import {
  BriefcaseIcon,
  PeopleIcon,
  MentionIcon,
  OrganizationIcon,
  KeyIcon,
  CreditCardIcon,
} from '@primer/octicons-react';
import { Helmet } from 'react-helmet-async';

const getWelcomeMessage = () => {
  const currentHour = new Date().getHours();
  if (currentHour > 5 && currentHour <= 12) {
    return 'Buenos días';
  }
  if (currentHour > 5 && currentHour <= 20) {
    return 'Buenas tardes';
  }
  return 'Buenas noches';
};

export default function HomeRoute() {
  const { user } = useAccount();

  return (
    <div className="flex-1">
      <Helmet>
        <title>Inicio • {process.env.REACT_APP_NAME} Wallet</title>
      </Helmet>
      <div className="color-bg-default border-bottom">
        <div className="container-lg p-responsive text-center py-6">
          <h1 className="h1">{`${getWelcomeMessage()} ${
            user.name.split(' ')[0]
          }`}</h1>
          <p className="f4 color-fg-muted col-md-6 mx-auto">
            Navege entre las distintas secciones para obtener credenciales,
            consulte la información de sus compañeros de equipo o gestione los
            usuarios de la plataforma.
          </p>
        </div>
      </div>
      <div className="container-lg p-responsive mt-6">
        <ul className="d-flex flex-wrap flex-justify-start flex-items-stretch list-style-none gutter my-4">
          <li className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/organizations"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded mb-3 color-fg-muted">
                  <BriefcaseIcon size="large" />
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-0 mt-1">
                  Organizaciones
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Espacios de trabajo para almacenar credenciales de forma
                  segura
                </p>
              </Link>
            </div>
          </li>
          <li className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/teams"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded mb-3 color-fg-muted">
                  <PeopleIcon size="large" />
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-0 mt-1">
                  Equipos
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Grupos de miembros de usuarios que reflejan la estructura de
                  su empresa
                </p>
              </Link>
            </div>
          </li>
          <li className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/users"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded mb-3 color-fg-muted">
                  <MentionIcon size="large" />
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-0 mt-1">
                  Usuarios
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Gestione los usuarios y los permisos de acceso a la plataforma
                </p>
              </Link>
            </div>
          </li>
          <li className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/admin"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded mb-3 color-fg-muted">
                  <OrganizationIcon size="large" />
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-0 mt-1">
                  Administración
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Revise y gestione el uso de la plataforma
                </p>
              </Link>
            </div>
          </li>
          <li className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded mb-3 color-fg-muted">
                  <KeyIcon size="large" />
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-0 mt-1">
                  Reconocimiento facial
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Próximamente se habilitará Face Id para el acceso a sus datos
                  sensibles
                </p>
              </Link>
            </div>
          </li>
          <li className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="topic-box position-relative hover-grow height-full text-center border color-border-muted rounded color-bg-default p-5">
              <Link
                to="/"
                className="no-underline d-flex flex-column flex-justify-center"
              >
                <span className="mx-auto rounded mb-3 color-fg-muted">
                  <CreditCardIcon size="large" />
                </span>
                <p className="f3 lh-condensed text-center Link--primary mb-0 mt-1">
                  Datos bancarios
                </p>
                <p className="f5 color-fg-muted text-center mb-0 mt-1">
                  Próximamente podrá almacenar y acceder a sus datos bancarios
                  de forma segura
                </p>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

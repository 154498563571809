import { useAccount } from 'context/account';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'context/theme';

export default function AuthRoute({ component: Component, ...rest }) {
  const { isAuthenticated } = useAccount();
  const { isDarkMode } = useTheme();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <div
            className="d-flex flex-1 flex-column"
            data-color-mode={isDarkMode ? 'dark' : 'light'}
            data-light-theme={isDarkMode ? null : 'light'}
            data-dark-theme={isDarkMode ? 'dark' : null}
          >
            <Component {...props} {...rest} />
          </div>
        )
      }
    />
  );
}

AuthRoute.propTypes = {
  component: PropTypes.any,
};

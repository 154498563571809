import { Switch } from 'react-router-dom';
import { AuthRoute, PrivateRoute } from 'components';

import SigninRoute from './signin.route';
import SignoutRoute from './signout.route';

export default function AuthRoutes() {
  return (
    <Switch>
      <AuthRoute path="/auth/signin" component={SigninRoute} />
      <PrivateRoute path="/auth/signout" component={SignoutRoute} />
    </Switch>
  );
}

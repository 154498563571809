/* eslint-disable indent */
import { useCallback, useState } from 'react';
import { useLazyQuery } from 'hooks';
import { CircularProgress, UserAvatar } from 'components';
import { Modal, Backdrop, Fade, makeStyles } from '@material-ui/core';
import {
  MailIcon,
  PeopleIcon,
  SearchIcon,
  XIcon,
} from '@primer/octicons-react';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(32, 38, 45, 0.7)',
    backdropFilter: 'blur(2px)',
  },
});

export default function AddOrganizationMemberModal({
  organization,
  open = false,
  onClose = () => {},
  onAddUser,
  onAddTeam,
  disabled = false,
}) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);

  const handleClose = () => {
    setSearch('');
    setUsers([]);
    setTeams([]);
    onClose();
  };

  const onSearchUsersCompleted = useCallback(
    (response) => setUsers(response.results),
    [],
  );

  const onSearchTeamsCompleted = useCallback(
    (response) => setTeams(response.results),
    [],
  );

  const [searchUsers, { loading: loadingUsers }] = useLazyQuery({
    url: '/users',
    onCompleted: onSearchUsersCompleted,
  });

  const [searchTeams, { loading: loadingTeams }] = useLazyQuery({
    url: '/teams',
    onCompleted: onSearchTeamsCompleted,
  });

  const onSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value.trim());
    if (value.trim().length > 0) {
      searchUsers({
        search: value.trim(),
        excludeIds: organization.users.map((u) => u.id),
        sortBy: 'name:asc',
        page: 1,
        limit: 5,
      });
      searchTeams({
        name: value.trim(),
        excludeIds: organization.teams.map((t) => t.id),
        sortBy: 'name:asc',
        page: 1,
        limit: 5,
      });
    } else {
      setUsers([]);
      setTeams([]);
    }
  };

  const loading = loadingUsers || loadingTeams;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 250, classes: { root: classes.backdrop } }}
      closeAfterTransition
    >
      <Fade in={open}>
        <div
          className="d-flex flex-column flex-items-center flex-justify-center width-full py-4 p-responsive"
          style={{ outline: 'none' }}
        >
          <div className="Box container-sm width-full">
            <div className="Box-body p-0 position-relative">
              <button
                type="button"
                className="btn-octicon position-absolute right-0 top-0 p-3"
                onClick={handleClose}
                style={{ zIndex: 1 }}
              >
                <XIcon />
              </button>
              <div className="blankslate py-4">
                <MailIcon className="blankslate-icon" size="medium" />
                <h3 className="h3">Agregar miembros a {organization.name}</h3>
                <p className="mt-2 mb-0">
                  Al añadir un usuario o equipo, los miembros tendrán acceso a
                  todas las credenciales de <strong>{organization.name}</strong>
                  . Este permiso puede ser revocado en cualquier momento por un
                  administrador o el propietario de la organización.
                </p>
              </div>
            </div>
            <div className="Box-row">
              <div className="subnav-search ml-0 position-relative">
                {loading ? (
                  <CircularProgress
                    className="subnav-search-icon"
                    size={16}
                    thickness={5}
                  />
                ) : (
                  <SearchIcon className="subnav-search-icon" />
                )}
                <input
                  type="search"
                  className="form-control subnav-search-input subnav-search-input-wide width-full"
                  placeholder="Buscar usuarios o equipos por su nombre o email…"
                  value={search}
                  onChange={onSearchChange}
                  spellCheck={false}
                  autoFocus
                />
              </div>
            </div>
            {!loading && search.length > 0 && !users.length && !teams.length && (
              <div className="Box-row">
                <div className="text-center">
                  <p className="m-0">
                    No se encontraron usuarios o equipos que coincidan con su
                    búsqueda.
                  </p>
                </div>
              </div>
            )}
            <div
              className="scroller auto scrollerBase disableScrollAnchor"
              style={{
                overflow: 'hidden scroll',
                maxHeight: 'calc(100vh - 300px)',
              }}
            >
              {teams.length > 0 &&
                teams.map((team) => (
                  <div key={team.id} className="Box-row">
                    <div className="d-flex flex-items-center">
                      <PeopleIcon size="medium" />
                      <div className="flex-1 ml-3">
                        <div className="text-bold">{team.name}</div>
                        <div className="text-gray text-small">
                          {team.description || 'Sin descripción'} ·{' '}
                          <span
                            className="tooltipped tooltipped-multiline tooltipped-n"
                            aria-label={
                              team.members.length > 1
                                ? `${team.members
                                    .slice(0, -1)
                                    .map((member) => member.name)
                                    .join(', ')} y ${
                                    team.members[team.members.length - 1].name
                                  }`
                                : team.members
                                    .map((member) => member.name)
                                    .join(', ')
                            }
                          >
                            {team.members.length} miembro
                            {team.members.length !== 1 ? 's' : ''}
                          </span>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => onAddTeam(team)}
                        disabled={disabled}
                      >
                        Añadir equipo
                      </button>
                    </div>
                  </div>
                ))}
              {users.length > 0 &&
                users.map((user) => (
                  <div key={user.id} className="Box-row">
                    <div className="d-flex flex-items-center">
                      <UserAvatar url={user.avatarURL} username={user.name} />
                      <div className="flex-1 ml-3">
                        <div className="text-bold">{user.name}</div>
                        <div className="text-gray text-small">{user.email}</div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => onAddUser(user)}
                        disabled={disabled}
                      >
                        Añadir usuario
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

AddOrganizationMemberModal.propTypes = {
  organization: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAddUser: PropTypes.func.isRequired,
  onAddTeam: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

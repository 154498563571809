/* eslint-disable indent */
import { SearchIcon, TrashIcon } from '@primer/octicons-react';
import { AddTeamMemberModal, Pagination, UserAvatar } from 'components';
import { useAccount } from 'context/account';
import { useMutation } from 'hooks';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useDebounce } from 'use-debounce/lib';

export default function TeamMembersRoute({ team, refetch }) {
  const history = useHistory();
  const { user: viewer } = useAccount();
  const { addToast } = useToasts();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 250);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const totalPages = useMemo(() => {
    const total = team.members.length;
    return Math.ceil(total / 20);
  }, [team.members.length]);

  useEffect(() => {
    setPage(1);
  }, [debouncedSearch]);

  const onUpdateSuccess = useCallback(() => {
    addToast('Equipo actualizado', {
      appearance: 'success',
      autoDismiss: true,
    });
    refetch();
    history.push(`/teams/${team.id}/access`);
  }, [refetch]);

  const onError = useCallback((err) => {
    addToast(err, { appearance: 'error', autoDismiss: true });
  }, []);

  const [updateTeam, { loading: updating }] = useMutation({
    url: `/teams/${team.id}`,
    options: { method: 'PATCH' },
    onCompleted: onUpdateSuccess,
    onError,
  });

  const members = useMemo(
    () =>
      team.members.filter(
        (member) =>
          member.name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          member.email.toLowerCase().includes(debouncedSearch.toLowerCase()),
      ),
    [team.members, debouncedSearch],
  );

  const handleAddMember = (user) => {
    updateTeam({ members: [...team.members.map((m) => m.id), user.id] });
  };

  const handleRemoveMember = (member) => {
    if (
      window.confirm(
        `¿Estás seguro de querer eliminar el acceso a ${member.name}?`,
      )
    ) {
      updateTeam({
        members: team.members
          .filter((m) => m.id !== member.id)
          .map((m) => m.id),
      });
    }
  };

  return (
    <div>
      <div className="subnav d-flex mb-3 flex-row">
        <div className="flex-auto min-width-0">
          <div className="subnav-search ml-0">
            <SearchIcon className="subnav-search-icon" />
            <input
              type="search"
              className="form-control subnav-search-input subnav-search-input-wide width-full"
              placeholder="Buscar miembros por su nombre o email…"
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              spellCheck={false}
              autoFocus
            />
          </div>
        </div>
        <div className="ml-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setOpenModal(true)}
          >
            Agregar <span className="d-none d-sm-inline-block">miembro</span>
          </button>
          <AddTeamMemberModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            onAdd={handleAddMember}
            team={team}
            disabled={updating}
          />
        </div>
      </div>
      <div className="Box mt-3 hx_Box--firstRowRounded0">
        <div className="Box-header d-flex flex-justify-between">
          <h3 className="Box-title">Miembros</h3>
        </div>
        <div className="organization-list">
          {!members.length && (
            <div className="bg-primary blankslate blankslate-spacious text-center position-relative rounded-2">
              <img
                src="https://ghicons.github.com/assets/images/blue/png/Code%20hosting.png"
                alt="Workplace icon"
                className="d-block mb-3 mx-auto grayscale"
                draggable={false}
              />
              <h3 className="mb-1 color-fg-muted">
                No hay resultados que coincidan con su búsqueda
              </h3>
              <p className="container-sm color-fg-muted">
                Controla de forma flexible el acceso a organizaciones de los
                miembros del equipo
              </p>
            </div>
          )}
          {members.map((member) => (
            <div className="Box-row px-3 py-2" key={member.id}>
              <div className="d-flex flex-items-center">
                <UserAvatar url={member.avatarURL} username={member.name} />
                <div className="ml-3 flex-1">
                  <Link
                    to={`/users/${member.id}`}
                    className="Link--primary text-bold no-underline"
                  >
                    {member.name}
                  </Link>
                  <div className="color-fg-subtle">{member.email}</div>
                </div>
                {(viewer.role === 'admin' || team.author?.id === viewer.id) &&
                  member.id !== viewer.id && (
                    <button
                      type="button"
                      className="btn-octicon btn-octicon-danger tooltipped tooltipped-nw"
                      aria-label="Eliminar miembro"
                      onClick={() => handleRemoveMember(member)}
                    >
                      <TrashIcon />
                    </button>
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination
        pageInfo={{ currentPage: page, pageCount: totalPages }}
        onPageChange={setPage}
      />
    </div>
  );
}

TeamMembersRoute.propTypes = {
  team: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

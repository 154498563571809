import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useQuery } from 'hooks';
import { CheckIcon, XIcon } from '@primer/octicons-react';
import PropTypes from 'prop-types';
import { UserAvatar } from 'components';
import { Skeleton } from '@material-ui/lab';
import { useDebounce } from 'use-debounce';

export default function CredentialRequestsUserFilter({ value, onChange }) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 250);
  const inputRef = useRef();

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open, inputRef]);

  useHotkeys('esc', () => setOpen(false));

  const { data, loading } = useQuery({
    url: '/users',
    variables: {
      search:
        debouncedQuery.trim().length > 0 ? debouncedQuery.trim() : undefined,
      page: 1,
      limit: 1000,
      sortBy: 'name:asc',
    },
  });

  return (
    <details
      open={open}
      className="details-reset details-overlay d-inline-block position-relative px-3"
    >
      <summary
        role="button"
        className="btn-link"
        aria-haspopup="menu"
        onClick={(event) => {
          event.preventDefault();
          setOpen((prev) => !prev);
        }}
      >
        Usuario <span className="dropdown-caret hide-sm" />
      </summary>
      <div role="menu" className="SelectMenu SelectMenu--hasFilter right-lg-0">
        <div className="SelectMenu-modal">
          <header className="SelectMenu-header">
            <span className="SelectMenu-title">Filtrar por usuario</span>
            <button
              type="button"
              className="SelectMenu-closeButton"
              onClick={() => setOpen(false)}
            >
              <XIcon />
            </button>
          </header>
          <div className="SelectMenu-filter">
            <input
              ref={inputRef}
              className="SelectMenu-input form-control"
              type="text"
              placeholder="Filtrar por nombre o email"
              aria-label="Filtrar por nombre o email"
              value={query}
              onChange={({ target }) => setQuery(target.value)}
              spellCheck={false}
              autoFocus
            />
          </div>
          <div className="SelectMenu-list select-menu-list">
            {loading &&
              Array.from(new Array(3)).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`loading-${i}`} className="SelectMenu-item">
                  <CheckIcon
                    className="SelectMenu-icon SelectMenu-icon--check"
                    width={12}
                    height={16}
                  />
                  <Skeleton
                    className="flex-shrink-0"
                    variant="circle"
                    width={26}
                    height={26}
                  />
                  <div className="ml-2 flex-1">
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="70%" />
                  </div>
                </div>
              ))}
            {!loading && (
              <>
                <div
                  className="SelectMenu-item"
                  role="menuitemradio"
                  aria-checked={value === undefined}
                  onClick={() => {
                    onChange(undefined);
                    setOpen(false);
                  }}
                >
                  <CheckIcon
                    className="SelectMenu-icon SelectMenu-icon--check"
                    width={12}
                    height={16}
                  />
                  <span className="text-bold">Cualquier usuario</span>
                </div>
                {data.results.map((user) => (
                  <div
                    key={user.id}
                    className="SelectMenu-item"
                    role="menuitemradio"
                    aria-checked={value === user.id}
                    onClick={() => {
                      onChange(user.id);
                      setOpen(false);
                    }}
                  >
                    <CheckIcon
                      className="SelectMenu-icon SelectMenu-icon--check"
                      width={12}
                      height={16}
                    />
                    <UserAvatar url={user.avatarURL} username={user.name} />
                    <div className="ml-2">
                      <div className="text-bold">{user.name}</div>
                      <div className="color-fg-muted">{user.email}</div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </details>
  );
}

CredentialRequestsUserFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

import { useState } from 'react';
import { styled } from '@mui/material';
import { CalendarPicker } from '@mui/lab';
import { XIcon } from '@primer/octicons-react';
import PropTypes from 'prop-types';

const StyledDatePicker = styled(CalendarPicker)({
  width: 'auto',
});

export default function DatePickerFilter({
  value,
  onChange,
  label = 'Fecha',
  minDate,
  maxDate,
}) {
  const [open, setOpen] = useState(false);

  return (
    <details
      open={open}
      className="details-reset details-overlay d-inline-block position-relative px-3"
    >
      <summary
        role="button"
        className="btn-link"
        aria-haspopup="menu"
        onClick={(event) => {
          event.preventDefault();
          setOpen((prev) => !prev);
        }}
      >
        {label} <span className="dropdown-caret hide-sm" />
      </summary>
      <div role="menu" className="SelectMenu SelectMenu--hasFilter right-lg-0">
        <div className="SelectMenu-modal">
          <header className="SelectMenu-header">
            <span className="SelectMenu-title">Filtrar por fecha</span>
            <button
              type="button"
              className="SelectMenu-closeButton"
              onClick={() => setOpen(false)}
            >
              <XIcon />
            </button>
          </header>
          <div className="SelectMenu-filter">
            <input
              type="text"
              className="SelectMenu-input form-control text-center"
              placeholder="dd/mm/yyyy"
              value={value ? value.format('DD/MM/YYYY HH:mm') : ''}
              readOnly
            />
            {value && (
              <button
                type="button"
                className="btn btn-block mt-1"
                onClick={() => onChange(undefined)}
              >
                Eliminar fecha
              </button>
            )}
          </div>
          <div className="SelectMenu-list">
            <StyledDatePicker
              date={value}
              onChange={(date) => onChange(date)}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
        </div>
      </div>
    </details>
  );
}

DatePickerFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
};

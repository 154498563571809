import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLazyQuery, useMutation } from 'hooks';
import { SearchIcon, TrashIcon } from '@primer/octicons-react';
import { Popper } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { useAccount } from 'context/account';
import { UserAvatar } from 'components';

export default function CreateTeamRoute() {
  const history = useHistory();
  const { user: viewer } = useAccount();
  const { addToast } = useToasts();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [search, setSearch] = useState('');
  const [members, setMembers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [searchUsers, { data }] = useLazyQuery({
    url: '/users',
  });

  const onCreateTeam = useCallback((team) => {
    addToast('Equipo creado correctamente', {
      appearance: 'success',
      autoDismiss: true,
    });
    history.push(`/teams/${team.id}`);
  }, []);

  const onError = useCallback(
    (error) => addToast(error, { appearance: 'error', autoDismiss: true }),
    [],
  );

  const [createTeam, { loading }] = useMutation({
    url: '/teams',
    options: { method: 'POST' },
    onCompleted: onCreateTeam,
    onError,
  });

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
    setAnchorEl(value.length ? event.currentTarget : null);
    if (value.length > 0) {
      searchUsers({
        search: value,
        sortBy: 'name:asc',
        page: 1,
        limit: 10,
      });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (members.length === 0) {
      addToast('Debe seleccionar al menos un miembro', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    createTeam({
      author: viewer.id,
      name,
      description:
        description.trim().length > 0 ? description.trim() : undefined,
      members: members.map((member) => member.id),
    });
  };

  return (
    <div className="flex-1 py-3">
      <Helmet>
        <title>Crear equipo • {process.env.REACT_APP_NAME} Wallet</title>
      </Helmet>
      <div className="container-lg p-responsive">
        <div className="Box">
          <div className="Box-body">
            <div className="Subhead">
              <div className="Subhead-heading">Crear un nuevo equipo</div>
              <div className="Subhead-description">
                Grupos de miembros de usuarios que reflejan la estructura de su
                empresa.
              </div>
            </div>
            <form onSubmit={handleFormSubmit}>
              <div className="d-flex px-3 gutter-condensed flex-wrap">
                <dl className="col-md-6 form-group mt-0">
                  <dt>
                    <label htmlFor="team-name">Nombre</label>
                  </dt>
                  <dd>
                    <input
                      type="text"
                      id="team-name"
                      name="new-teamname"
                      className="form-control m-0"
                      placeholder="Nombre del equipo"
                      value={name}
                      onChange={({ target }) => setName(target.value)}
                      autoComplete="new-teamname"
                      spellCheck={false}
                      autoFocus
                      required
                    />
                  </dd>
                </dl>
                <dl className="col-md-6 form-group mt-0">
                  <dt>
                    <label htmlFor="team-description">
                      Descripción (Opcional)
                    </label>
                  </dt>
                  <dd>
                    <input
                      type="text"
                      id="team-description"
                      name="new-teamdescription"
                      className="form-control m-0 width-full"
                      placeholder="Descripcion del equipo"
                      value={description}
                      onChange={({ target }) => setDescription(target.value)}
                      autoComplete="new-teamdescription"
                      spellCheck={false}
                    />
                  </dd>
                </dl>
                <div className="col-12 mb-3">
                  <div className="Box">
                    <div className="Box-header">
                      <h3 className="Box-title">Miembros</h3>
                    </div>
                    {!members.length && (
                      <div className="Box-row p-0">
                        <div className="blankslate">
                          <h3 className="mb-1 color-fg-subtle">
                            No hay miembros en este equipo
                          </h3>
                          <p className="container-sm color-fg-muted">
                            Controla de forma flexible el acceso a
                            organizaciones de los miembros del equipo
                          </p>
                        </div>
                      </div>
                    )}
                    {members.map((member) => (
                      <div className="Box-row" key={member.id}>
                        <div className="d-flex flex-items-center">
                          <UserAvatar
                            url={member.avatarURL}
                            username={member.name}
                          />
                          <div className="ml-3 flex-1">
                            <div className="text-bold">{member.name}</div>
                            <div className="color-fg-subtle">
                              {member.email}
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn-octicon btn-octicon-danger"
                            onClick={() =>
                              setMembers((prev) =>
                                prev.filter((m) => m.id !== member.id),
                              )
                            }
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="Box-footer color-bg-subtle">
                      <div className="subnav-search ml-0">
                        <SearchIcon className="subnav-search-icon" />
                        <input
                          type="search"
                          id="search-input"
                          className="form-control subnav-search-input subnav-search-input-wide width-full"
                          placeholder="Buscar usuarios por nombre y apellidos o dirección de correo"
                          value={search}
                          onChange={handleSearchChange}
                          autoComplete="off"
                          spellCheck={false}
                        />
                        <Popper
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          className="autocomplete-results"
                          placement="bottom-start"
                          disablePortal
                        >
                          {data?.results?.filter(
                            (u) => !members.some((m) => m.id === u.id),
                          ).length === 0 && (
                            <div className="autocomplete-item px-3 py-2 disabled">
                              {search}{' '}
                              <span className="text-normal">
                                no es un usuario de tu empresa o ya está
                                incluido
                              </span>
                            </div>
                          )}
                          {data?.results
                            .filter((u) => !members.some((m) => m.id === u.id))
                            .map((user) => (
                              <div
                                key={user.id}
                                role="button"
                                className="autocomplete-item d-flex flex-items-center px-3 py-2"
                                onClick={() => {
                                  setSearch('');
                                  setAnchorEl(null);
                                  setMembers((prev) => [
                                    ...prev.filter((m) => m.id !== user.id),
                                    user,
                                  ]);
                                }}
                              >
                                <UserAvatar
                                  url={user.avatarURL}
                                  username={user.name}
                                />
                                <div className="ml-2">
                                  <div>{user.name}</div>
                                  <div className="text-normal">
                                    {user.email}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Popper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-actions border-top pt-3 mt-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Creando equipo…' : 'Crear equipo'}
                </button>
                <button
                  type="button"
                  className="btn"
                  disabled={loading}
                  onClick={() => history.goBack()}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

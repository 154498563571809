/* eslint-disable react/prop-types */
import { useAccount } from 'context/account';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ApplicationLoader, Header, Sidebar } from 'components';
import { useTheme } from 'context/theme';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { isAuthenticated, user } = useAccount();
  const { isDarkMode } = useTheme();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: {
                  from:
                    props.location.pathname === '/auth/signout'
                      ? '/'
                      : props.location,
                },
              }}
            />
          );
        }
        if (!user) {
          return <ApplicationLoader />;
        }
        return (
          <div
            className="d-flex flex-column flex-1"
            data-color-mode={isDarkMode ? 'dark' : 'light'}
            data-light-theme={isDarkMode ? null : 'light'}
            data-dark-theme={isDarkMode ? 'dark' : null}
          >
            <Header />
            <div className="d-md-flex color-bg-inset flex-1">
              <Sidebar />
              <div
                className="d-flex flex-column flex-1 scroller auto scrollerBase disableScrollAnchor"
                style={{
                  height: 'calc(100vh - 58px)',
                  overflow: 'hidden scroll',
                  paddingRight: 0,
                }}
              >
                <Component {...props} {...rest} />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

import { Switch, Route } from 'react-router-dom';
import OrganizationListRoute from './list.route';
import OrganizationCreateRoute from './create.route';
import OrganizationViewRoute from './view.route';

export default function OrganizationRoutes() {
  return (
    <Switch>
      <Route exact path="/organizations" component={OrganizationListRoute} />
      <Route path="/organizations/new" component={OrganizationCreateRoute} />
      <Route
        path="/organizations/:organizationId"
        component={OrganizationViewRoute}
      />
    </Switch>
  );
}

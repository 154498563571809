import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';

// eslint-disable-next-line no-unused-vars
export default function NoificationsSettingsRoute({ user, refetch }) {
  const { addToast } = useToasts();

  return (
    <div>
      <div className="Subhead hx_Subhead--responsive">
        <h2 className="Subhead-heading">Notificaciones</h2>
      </div>
      <p>
        Elija cómo recibir las notificaciones. Estos ajustes de notificación se
        aplican a las cosas a las que tiene acceso.
      </p>
      <div className="Box my-3">
        <div className="Box-row">
          <h4>Alertas de organización</h4>
          <p className="note">
            Reciba automáticamente notificaciones cuando alguien solicite acceso
            a las credenciales de una organización.
          </p>
          <div className="form-checkbox my-1">
            <label
              htmlFor="org_settings_email_1"
              className="mr-4 v-align-middle"
            >
              <input
                type="checkbox"
                id="org_settings_email_1"
                className="form-checkbox-details-trigger"
                onClick={() =>
                  addToast('Opción no disponible actualmente', {
                    appearance: 'warning',
                    autoDismiss: true,
                  })
                }
                checked
              />{' '}
              Email
            </label>
          </div>
        </div>
        <div className="Box-row">
          <h4>Alertas de equipo</h4>
          <p className="note">
            Reciba automáticamente notificaciones cuando se una a un equipo o el
            equipo consiga acceso a una nueva organización.
          </p>
          <div className="form-checkbox my-1">
            <label
              htmlFor="team_settings_email_1"
              className="mr-4 v-align-middle"
            >
              <input
                type="checkbox"
                id="team_settings_email_1"
                className="form-checkbox-details-trigger"
                onClick={() =>
                  addToast('Opción no disponible actualmente', {
                    appearance: 'warning',
                    autoDismiss: true,
                  })
                }
                checked
              />{' '}
              Email
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

NoificationsSettingsRoute.propTypes = {
  user: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MoonIcon, SunIcon } from '@primer/octicons-react';
import LightThemePreview from 'images/light_preview.svg';
import DarkThemePreview from 'images/dark_dimmed_preview.svg';
import { useTheme, useThemeDispatch } from 'context/theme';
import { useAccount } from 'context/account';
import { useMutation } from 'hooks';
import { useToasts } from 'react-toast-notifications';

export default function ThemeSettingsRoute({ user, refetch }) {
  const { addToast } = useToasts();
  const { user: viewer } = useAccount();
  const { isDarkMode } = useTheme();
  const dispatchTheme = useThemeDispatch();

  const onError = useCallback((error) => {
    addToast(error, {
      appearance: 'error',
      autoDismiss: true,
    });
  });

  const onCompleted = useCallback(() => {
    addToast('Preferencias actualizadas', {
      appearance: 'success',
      autoDismiss: true,
    });
    refetch();
  });

  const [updateUser, { loading }] = useMutation({
    url: `/users/${user.id}`,
    options: { method: 'PATCH' },
    onError,
    onCompleted,
  });

  const switchToDarkMode = () => {
    if (user.theme === 'dark') return;
    if (viewer.id === user.id) {
      localStorage.setItem('theme', 'dark');
      dispatchTheme({ type: 'dark' });
    }
    updateUser({
      theme: 'dark',
      themeMode: 'single',
    });
  };

  const switchToLightMode = () => {
    if (user.theme === 'light') return;
    if (viewer.id === user.id) {
      localStorage.setItem('theme', 'light');
      dispatchTheme({ type: 'light' });
    }
    updateUser({
      theme: 'light',
      themeMode: 'single',
    });
  };

  return (
    <div>
      <div className="Subhead hx_Subhead--responsive">
        <h2 className="Subhead-heading">Preferencias de aspecto</h2>
      </div>
      <p className="mb-3">
        Elige el aspecto de Wallet para ti. Selecciona un solo tema, o
        sincroniza con tu sistema y cambia automáticamente entre los temas de
        día y de noche.
      </p>
      <h3 id="theme-mode-heading" className="h5">
        Modo de aspecto
      </h3>
      <div className="d-flex flex-items-center mt-2 mb-4">
        <select
          className="form-select mr-2"
          aria-labelledby="theme-mode-heading"
          value={user.themeMode}
          onChange={({ target }) => updateUser({ themeMode: target.value })}
          disabled={loading}
        >
          <option value="single">Seleccionar tema</option>
          <option value="auto">Sincronizar con el sistema</option>
        </select>
        <span
          className="flex-auto color-fg-muted f6"
          hidden={user.themeMode !== 'single'}
        >
          Wallet usará el tema seleccionado
        </span>
        <span
          className="flex-auto color-fg-muted f6"
          hidden={user.themeMode !== 'auto'}
        >
          El tema de Wallet coincidirá con la configuración activa de tu sistema
        </span>
      </div>
      {user.themeMode === 'single' && (
        <div className="d-flex flex-column flex-md-row mb-3">
          <div className="col-md-5 mr-0 mr-md-3 mb-3 mb-md-0">
            <div className={user.theme === 'light' ? 'Box Box--blue' : 'Box'}>
              <div className="Box-header d-flex">
                <h4 className="h5 flex-auto">
                  <SunIcon /> Tema de día
                </h4>
                {!isDarkMode && (
                  <span className="Label Label--info" title="Modo activo">
                    Modo activo
                  </span>
                )}
              </div>
              <div className="Box-body">
                <div className="color-fg-muted mb-3 f6">
                  Este tema estará activo cuando su sistema esté en “modo
                  claro”.
                </div>
                <div
                  role="button"
                  className="border rounded-2"
                  onClick={switchToLightMode}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="border-bottom rounded-top-2 overflow-hidden">
                    <img
                      src={LightThemePreview}
                      className="d-block width-full"
                      alt="Modo día"
                      draggable={false}
                    />
                  </div>
                  <div className="py-2 px-3 text-bold">Modo día</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className={user.theme === 'dark' ? 'Box Box--blue' : 'Box'}>
              <div className="Box-header d-flex">
                <h4 className="h5 flex-auto">
                  <MoonIcon /> Tema de noche
                </h4>
                {isDarkMode && (
                  <span className="Label Label--info" title="Modo activo">
                    Modo activo
                  </span>
                )}
              </div>
              <div className="Box-body">
                <div className="color-fg-muted mb-3 f6">
                  Este tema estará activo cuando su sistema esté en “modo
                  oscuro”.
                </div>
                <div
                  role="button"
                  className="border rounded-2"
                  onClick={switchToDarkMode}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="border-bottom rounded-top-2 overflow-hidden">
                    <img
                      src={DarkThemePreview}
                      className="d-block width-full"
                      alt="Modo día"
                      draggable={false}
                    />
                  </div>
                  <div className="py-2 px-3 text-bold">Modo noche</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

ThemeSettingsRoute.propTypes = {
  user: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

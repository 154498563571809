import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Popper } from '@material-ui/core';

const useStyles = makeStyles({
  popper: {
    margin: 0,
    padding: 8,
    maxWidth: 'calc(100vw - 32px)',
    zIndex: 36,
    '&[x-placement*="left"]': {
      left: '-4px !important',
    },
    '&[x-placement*="right"]': {
      left: '4px !important',
    },
    '&[x-placement="top-start"]': {
      marginLeft: -14,
    },
    '&[x-placement="bottom-start"]': {
      marginLeft: -14,
    },
    '&[x-placement="top-end"]': {
      left: '32px !important',
    },
    '&[x-placement="top-end"] $arrow': {
      '&::before': {
        left: '-32px !important',
      },
      '&::after': {
        left: '-32px !important',
      },
    },
    '&[x-placement="bottom-end"]': {
      left: '32px !important',
    },
    '&[x-placement="bottom-end"] $arrow': {
      '&::before': {
        left: '-32px !important',
      },
      '&::after': {
        left: '-32px !important',
      },
    },
    '&[x-placement="left-start"]': {
      marginTop: -16,
    },
    '&[x-placement="left-end"]': {
      marginBottom: -16,
    },
    '&[x-placement="right-start"]': {
      marginTop: -16,
    },
    '&[x-placement="right-end"]': {
      marginBottom: -16,
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      '&::before': {
        top: 1,
        left: 0,
        marginLeft: -9,
        borderWidth: '0 8px 8px 8px',
        borderColor: `transparent transparent rgba(27,31,35,0.15) transparent`,
      },
      '&::after': {
        top: 2,
        left: 0,
        marginLeft: -8,
        borderWidth: '0 7px 7px 7px',
        borderColor: `transparent transparent #fff transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      '&::before': {
        bottom: 1,
        left: 0,
        marginLeft: -9,
        borderWidth: '8px 8px 0 8px',
        borderColor: `rgba(27,31,35,0.15) transparent transparent transparent`,
      },
      '&::after': {
        bottom: 2,
        left: 0,
        marginLeft: -8,
        borderWidth: '7px 7px 0 7px',
        borderColor: `#fff transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      '&::before': {
        left: 1,
        marginTop: -9,
        borderWidth: '8px 8px 8px 0',
        borderColor: `transparent rgba(27,31,35,0.15) transparent transparent`,
      },
      '&::after': {
        left: 2,
        marginTop: -8,
        borderWidth: '7px 7px 7px 0',
        borderColor: `transparent rgba(27,31,35,0.15) transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      '&::before': {
        left: -9,
        marginTop: -9,
        borderWidth: '8px 0 8px 8px',
        borderColor: `transparent transparent transparent rgba(27,31,35,0.15)`,
      },
      '&::after': {
        left: -9,
        marginTop: -8,
        borderWidth: '7px 0 7px 7px',
        borderColor: `transparent transparent transparent #fff`,
      },
    },
    '&[x-placement="top"]': {
      left: 140,
    },
  },
  arrow: {
    position: 'absolute',
    zIndex: 1,
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '50%',
      border: '8px solid transparent',
    },
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '50%',
      border: '7px solid transparent',
    },
  },
});

export default function Popover({
  children,
  open,
  anchorEl,
  placement,
  disablePortal,
  preventOverflow,
  disableArrow,
  flip,
}) {
  const [arrowRef, setArrowRef] = useState(null);
  const classes = useStyles();

  return (
    <Popper
      open={open}
      anchorEl={anchorEl.current}
      placement={placement}
      disablePortal={disablePortal}
      className={classes.popper}
      modifiers={{
        flip: { enabled: flip },
        arrow: { enabled: !disableArrow, element: arrowRef },
        preventOverflow: {
          enabled: preventOverflow !== 'disabled',
          boundariesElement:
            preventOverflow === 'disabled' ? 'scrollParent' : preventOverflow,
        },
      }}
    >
      <span ref={setArrowRef} className={classes.arrow} />
      <div className="popover">{children}</div>
    </Popper>
  );
}

Popover.defaultProps = {
  placement: 'bottom',
  disablePortal: false,
  preventOverflow: 'scrollParent',
  flip: true,
};

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  open: PropTypes.bool,
  anchorEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  disablePortal: PropTypes.bool,
  preventOverflow: PropTypes.oneOf([
    'disabled',
    'scrollParent',
    'viewport',
    'window',
  ]),
  flip: PropTypes.bool,
  disableArrow: PropTypes.bool,
};

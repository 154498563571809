import { useEffect } from 'react';
import { useLazyQuery } from 'hooks';
import { useAccountDispatch } from 'context/account';
import { Helmet } from 'react-helmet-async';

export default function SignoutRoute() {
  const dispatch = useAccountDispatch();
  const [signOutUser] = useLazyQuery({
    url: '/auth/logout',
    options: {
      method: 'POST',
      body: JSON.stringify({
        refreshToken: localStorage.getItem('refreshToken'),
      }),
    },
    onCompleted: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiresAt');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('refreshTokenExpiresAt');
      localStorage.removeItem('viewerId');
      dispatch({ type: 'signout' });
    },
    onError: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiresAt');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('refreshTokenExpiresAt');
      localStorage.removeItem('viewerId');
      dispatch({ type: 'signout' });
    },
  });

  useEffect(() => {
    signOutUser();
  }, []);

  return (
    <div className="d-flex flex-1 flex-column flex-items-center flex-justify-center">
      <Helmet>Cerrar sesión • {process.env.REACT_APP_NAME} Wallet</Helmet>
      <div className="branch-name mt-2">
        <span>Avisando a tu equipo que te desconectas</span>
        <span className="AnimatedEllipsis"></span>
      </div>
      <div className="branch-name mt-2">
        <span>Desconectando los sistemas</span>
        <span className="AnimatedEllipsis"></span>
      </div>
      <div className="branch-name mt-2">
        <span>Cerrando tu sesión</span>
        <span className="AnimatedEllipsis"></span>
      </div>
    </div>
  );
}

/* eslint-disable indent */
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from 'hooks';
import { useToasts } from 'react-toast-notifications';
import { AlertIcon, TrashIcon, XIcon } from '@primer/octicons-react';
import { useHistory } from 'react-router';
import { Popper } from '@material-ui/core';
import { UserAvatar } from 'components';
import { Skeleton } from '@material-ui/lab';

export default function TeamSettingsRoute({ team, refetch }) {
  const history = useHistory();
  const { addToast } = useToasts();
  const [name, setName] = useState(team.name);
  const [teamNameConfirm, setTeamNameConfirm] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [newAuthor, setNewAuthor] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userQuery, setUserQuery] = useState('');

  const onRenameTeam = useCallback(() => {
    addToast('El equipo se ha renombrado con éxito', {
      appearance: 'success',
      autoDismiss: true,
    });
    refetch();
  }, []);

  const onError = useCallback((err) =>
    addToast(err, { appearance: 'error', autoDismiss: true }),
  );

  const [renameOrganization, { loading: renamingTeam }] = useMutation({
    url: `/teams/${team.id}`,
    options: { method: 'PATCH' },
    onCompleted: onRenameTeam,
    onError,
  });

  const handleNameSubmit = (event) => {
    event.preventDefault();
    renameOrganization({ name });
  };

  const onDeletedTeam = useCallback(() => {
    addToast('Equipo eliminado con éxito', {
      appearance: 'success',
      autoDismiss: true,
    });
    history.push('/teams');
  }, []);

  const [deleteTeam, { loading: deletingTeam }] = useMutation({
    url: `/teams/${team.id}`,
    options: { method: 'DELETE' },
    onCompleted: onDeletedTeam,
    onError,
  });

  const handleDeleteTeam = (event) => {
    event.preventDefault();
    deleteTeam();
  };

  const [searchUsers, { data: usersData, loading: searchingUsers }] =
    useLazyQuery({
      url: '/users',
    });

  const handleUserQueryChange = (event) => {
    const { value } = event.target;
    setUserQuery(value);
    setAnchorEl(value.length ? event.currentTarget : null);
    if (value.length > 0) {
      searchUsers({
        search: value,
        sortBy: 'name:asc',
        page: 1,
        limit: 5,
      });
    }
  };

  const onTransferTeam = useCallback(() => {
    addToast('Equipo transferida con éxito', {
      appearance: 'success',
      autoDismiss: true,
    });
    refetch();
  }, []);

  const [transferTeam, { loading: transferingTeam }] = useMutation({
    url: `/teams/${team.id}`,
    options: { method: 'PATCH' },
    onCompleted: onTransferTeam,
    onError,
  });

  const handleTeamTransfership = (event) => {
    event.preventDefault();
    if (!newAuthor) {
      return addToast('Selecciona un usuario', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    if (newAuthor.id === team.author?.id) {
      return addToast(
        'El usuario seleccionado es el mismo que el autor de la organización',
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
    transferTeam({ author: newAuthor.id });
  };

  return (
    <div className="settings-content">
      <div className="Box">
        <div className="Box-body">
          <div className="Subhead hx_Subhead--responsive">
            <h2 className="Subhead-heading">Ajustes</h2>
          </div>
          <form className="d-flex" onSubmit={handleNameSubmit}>
            <dl className="form-group d-inline-block my-0">
              <dt className="input-label">
                <label htmlFor="organization-name">Nombre del equipo</label>
              </dt>
              <dd>
                <input
                  type="text"
                  id="organization-name"
                  className="form-control"
                  placeholder="Nombre del equpo"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                  maxLength={100}
                  autoComplete="off"
                  spellCheck={false}
                  disabled={renamingTeam}
                  required
                />
              </dd>
            </dl>
            <button
              type="submit"
              className="flex-self-end btn"
              disabled={renamingTeam || team.name === name}
            >
              {renamingTeam ? 'Renombrando…' : 'Renombrar'}
            </button>
          </form>
          <div className="Subhead hx_Subhead--responsive Subhead--spacious border-bottom-0 mb-0">
            <h2 className="Subhead-heading">Zona de peligro</h2>
          </div>
          <div className="Box color-border-danger">
            <ul>
              <li className="Box-row d-flex flex-items-center">
                <details
                  open={transferDialogOpen}
                  className="details-reset details-overlay details-overlay-dark flex-md-order-1 flex-order-2"
                >
                  <summary
                    role="button"
                    className="boxed-action btn-danger btn float-none float-md-right ml-0 ml-md-3 mt-2 mt-md-0"
                    onClick={(event) => {
                      event.preventDefault();
                      setTransferDialogOpen((prev) => !prev);
                    }}
                  >
                    Transferir equipo
                  </summary>
                  <div
                    role="dialog"
                    className="Box Box--overlay d-flex flex-column anim-fade-in fast"
                    aria-label="Transferir equipo"
                    aria-modal="true"
                  >
                    <div className="Box-header">
                      <button
                        type="button"
                        className="Box-btn-octicon btn-octicon float-right"
                        aria-label="Cerrar dialogo"
                        onClick={() => setTransferDialogOpen(false)}
                      >
                        <XIcon />
                      </button>
                      <div className="Box-title">Transferir equipo</div>
                    </div>
                    <div className="flash flash-warn flash-full">
                      <AlertIcon />
                      Si el nuevo propietario no pertenece al equipo será
                      añadido como miebro.
                    </div>
                    <div className="Box-body">
                      <form onSubmit={handleTeamTransfership}>
                        <p>
                          Tras transferir el equipo perderás los privilegios de
                          administrador en este equipo.
                        </p>
                        <p className="text-bold">
                          Nuevo propietario del equipo
                        </p>
                        <p>
                          {!newAuthor && (
                            <input
                              type="search"
                              className="form-control input-block"
                              placeholder="Buscar usuario por nombre o dirección de correo"
                              value={userQuery}
                              onChange={handleUserQueryChange}
                              autoComplete="off"
                              spellCheck={false}
                              disabled={transferingTeam}
                            />
                          )}
                          {!!newAuthor && (
                            <div className="border rounded-2 p-3 d-flex flex-items-center">
                              <UserAvatar
                                url={newAuthor.avatarURL}
                                username={newAuthor.name}
                              />
                              <div className="ml-2 flex-1 lh-condensed">
                                <div className="text-bold">
                                  {newAuthor.name}
                                </div>
                                <div className="color-fg-muted text-small">
                                  {newAuthor.email}
                                </div>
                              </div>
                              <button
                                type="button"
                                className="btn-octicon btn-octicon-danger"
                                onClick={() => setNewAuthor(null)}
                              >
                                <TrashIcon />
                              </button>
                            </div>
                          )}
                          <Popper
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            className="autocomplete-results"
                            placement="bottom"
                            disablePortal
                          >
                            {!searchingUsers &&
                              usersData?.results?.filter(
                                (u) => u.id !== team.author.id,
                              ).length === 0 && (
                                <div className="autocomplete-item px-3 py-2 disabled">
                                  {userQuery}{' '}
                                  <span className="text-normal">
                                    no es un usuario de tu empresa o es el
                                    propietario actual
                                  </span>
                                </div>
                              )}
                            {!searchingUsers &&
                              usersData?.results
                                .filter((u) => u.id !== team.author.id)
                                .map((user) => (
                                  <div
                                    key={user.id}
                                    role="button"
                                    className="autocomplete-item d-flex flex-items-center px-3 py-2"
                                    onClick={() => {
                                      setUserQuery('');
                                      setAnchorEl(null);
                                      setNewAuthor(user);
                                    }}
                                  >
                                    <UserAvatar
                                      url={user.avatarURL}
                                      username={user.name}
                                    />
                                    <div className="ml-2">
                                      <div>{user.name}</div>
                                      <div className="text-normal">
                                        {user.email}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            {searchingUsers &&
                              Array.from(new Array(3)).map((_, i) => (
                                <div
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`searching-${i}`}
                                  className="autocomplete-item d-flex flex-items-center px-3 py-2"
                                >
                                  <div>
                                    <Skeleton
                                      variant="circle"
                                      width={26}
                                      height={26}
                                    />
                                  </div>
                                  <div className="ml-2 flex-1">
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" width="70%" />
                                  </div>
                                </div>
                              ))}
                          </Popper>
                        </p>
                        {!!newAuthor && (
                          <>
                            <p>
                              Por favor, escriba <strong>{team.name}</strong>{' '}
                              para confirmar.
                            </p>
                            <p>
                              <input
                                type="text"
                                className="form-control input-block"
                                value={teamNameConfirm}
                                onChange={({ target }) =>
                                  setTeamNameConfirm(target.value)
                                }
                                autoComplete="off"
                                spellCheck={false}
                                required
                              />
                            </p>
                            <button
                              type="submit"
                              className="btn-danger btn btn-block"
                              disabled={
                                teamNameConfirm !== team.name ||
                                transferingTeam ||
                                !newAuthor
                              }
                            >
                              {transferingTeam ? (
                                `Transfiriendo equipo…`
                              ) : (
                                <>
                                  <span className="d-md-inline-block d-none">
                                    Entiendo las consecuencias, transferir este
                                    equipo
                                  </span>
                                  <span className="d-inline-block d-md-none">
                                    Transferir este equipo
                                  </span>
                                </>
                              )}
                            </button>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </details>
                <div className="flex-auto">
                  <strong>Transferir equipo</strong>
                  <p className="mb-0">
                    Transfiera este equipo a otro usuario con la capacidad de
                    crear equipos.
                  </p>
                </div>
              </li>
              <li className="Box-row d-flex flex-items-center">
                <details
                  open={deleteDialogOpen}
                  className="details-reset details-overlay details-overlay-dark flex-md-order-1 flex-order-2"
                >
                  <summary
                    role="button"
                    className="boxed-action btn-danger btn float-none float-md-right ml-0 ml-md-3 mt-2 mt-md-0"
                    onClick={(event) => {
                      event.preventDefault();
                      setDeleteDialogOpen((prev) => !prev);
                    }}
                  >
                    Eliminar este equipo
                  </summary>
                  <div
                    role="dialog"
                    className="Box Box--overlay d-flex flex-column anim-fade-in fast"
                    aria-label="Eliminar equipo"
                    aria-modal="true"
                  >
                    <div className="Box-header">
                      <button
                        type="button"
                        className="Box-btn-octicon btn-octicon float-right"
                        aria-label="Cerrar dialogo"
                        onClick={() => setDeleteDialogOpen(false)}
                      >
                        <XIcon />
                      </button>
                      <div className="Box-title">
                        ¿Está completamente seguro?
                      </div>
                    </div>
                    <div className="flash flash-warn flash-full">
                      Si no lees esto, ocurrirán cosas inesperadas.
                    </div>
                    <div className="Box-body overflow-auto">
                      <p>
                        Esta acción <strong>no</strong> se puede deshacer. Esto
                        borrará permanentemente el equipo{' '}
                        <strong>{team.name}</strong>, sus accesos a las
                        organizaciones y eliminará todas las asociaciones de
                        miembros.
                      </p>
                      <p>
                        Por favor, escriba <strong>{team.name}</strong> para
                        confirmar.
                      </p>
                      <form onSubmit={handleDeleteTeam}>
                        <p>
                          <input
                            type="text"
                            className="form-control input-block"
                            value={teamNameConfirm}
                            onChange={({ target }) =>
                              setTeamNameConfirm(target.value)
                            }
                            autoComplete="off"
                            spellCheck={false}
                            required
                          />
                        </p>
                        <button
                          type="submit"
                          className="btn-danger btn btn-block"
                          disabled={
                            teamNameConfirm !== team.name || deletingTeam
                          }
                        >
                          {deletingTeam ? (
                            `Eliminando equipo…`
                          ) : (
                            <>
                              <span className="d-md-inline-block d-none">
                                Entiendo las consecuencias, eliminar este equipo
                              </span>
                              <span className="d-inline-block d-md-none">
                                Eliminar este equipo
                              </span>
                            </>
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </details>
                <div className="flex-auto">
                  <strong>Eliminar este equipo</strong>
                  <p className="mb-0">
                    Una vez que elimine un equipo, no hay vuelta atrás. Por
                    favor, esté seguro.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

TeamSettingsRoute.propTypes = {
  team: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

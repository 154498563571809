import { IdBadgeIcon, KeyIcon } from '@primer/octicons-react';
import { useMutation } from 'hooks';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

export default function CredentialSettingsRoute({ user, refetch }) {
  const { addToast } = useToasts();

  const onError = useCallback(
    (error) => addToast(error, { appearance: 'error', autoDismiss: true }),
    [],
  );

  const onCompleted = useCallback(() => {
    addToast('Preferencias actualizadas', {
      appearance: 'success',
      autoDismiss: true,
    });
    refetch();
  }, []);

  const [updateUser, { loading }] = useMutation({
    url: `/users/${user.id}`,
    options: { method: 'PATCH' },
    onError,
    onCompleted,
  });

  return (
    <div>
      <div className="Subhead hx_Subhead--responsive">
        <h2 className="Subhead-heading">Acceso a credenciales</h2>
      </div>
      <p className="mb-3">
        Por seguridad, cada vez que solicite acceder a un dato sensible Wallet
        solicitará verificar su identidad. Elija la forma en la que desea que
        Wallet verifique su identidad.
      </p>
      <h3 id="verify-mode-heading" className="h5">
        Solicitud de verificación
      </h3>
      <div className="d-flex flex-items-center mt-2 mb-4">
        <select
          className="form-select mr-2"
          value={user.verificationMode}
          onChange={({ target }) => {
            updateUser({ verificationMode: target.value });
          }}
          disabled={loading}
        >
          <option value="always">Siempre</option>
          <option value="hourly">Una vez por hora</option>
          <option value="daily">Una vez al día</option>
        </select>
        {user.verificationMode === 'always' && (
          <span className="flex-auto color-fg-muted f6">
            Wallet solicitará verificar su identidad siempre
          </span>
        )}
        {user.verificationMode === 'hourly' && (
          <span className="flex-auto color-fg-muted f6">
            Wallet solicitará verificar su identidad una vez por hora
          </span>
        )}
        {user.verificationMode === 'daily' && (
          <span className="flex-auto color-fg-muted f6">
            Wallet solicitará verificar su identidad una vez al día
          </span>
        )}
      </div>
      <h3 id="verify-mode-heading" className="h5 mb-2">
        Modo de verificación
      </h3>
      <div className="d-flex flex-column flex-md-row mb-3">
        <div className="col-md-5 mr-0 mr-md-3 mb-3 mb-md-0">
          <div className="Box">
            <div className="Box-header d-flex">
              <h4 className="h5 flex-auto">
                <KeyIcon className="mr-1" /> Mediante contraseña
              </h4>
              {/* <span className="Label Label--info d-block" title="Modo activo">
                Modo activo
              </span> */}
            </div>
            <div className="Box-body">
              <div className="color-fg-muted mb-3 f6">
                Solicitaremos que introduzca su contraseña de usuario cada vez
                que intente acceder a información sensible de la aplicación.
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="Box Box--blue">
            <div className="Box-header d-flex">
              <h4 className="h5 flex-auto">
                <IdBadgeIcon className="mr-1" /> Mediante FaceID
              </h4>
              <span className="Label Label--info d-block" title="Modo activo">
                Modo activo
              </span>
            </div>
            <div className="Box-body">
              <div className="color-fg-muted mb-3 f6">
                Solicitaremos verificar su rostro cuando trate de acceder a
                información sensible de la aplicación.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CredentialSettingsRoute.propTypes = {
  user: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

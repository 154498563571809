import { useCallback, useMemo, useReducer } from 'react';
import queryString from 'query-string';
import { fetch } from 'utils';

const emptyObject = {};
const voidFunction = () => {};
const reducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case 'success':
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.data,
      };
    case 'merge':
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.mergeFunction(state.data, action.data),
      };
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case 'reset':
      return {
        ...state,
        loading: false,
        error: undefined,
        data: undefined,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default function useLazyQuery({
  url = '',
  options = emptyObject,
  onCompleted = voidFunction,
  onError = voidFunction,
}) {
  const [{ data, loading, error }, dispatch] = useReducer(reducer, {
    loading: false,
    error: undefined,
    data: undefined,
  });

  const fetchOptions = useMemo(
    () => ({
      method: 'GET',
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json',
      },
    }),
    [options],
  );

  const fetchData = useCallback(
    async (queryParams = {}, extendedUrl = '') => {
      dispatch({ type: 'loading' });
      try {
        const response = await fetch(
          queryString.stringifyUrl({
            url: `${process.env.REACT_APP_API_URL}${url}${extendedUrl}`,
            query: queryParams,
          }),
          fetchOptions,
        );
        dispatch({ type: 'success', data: response.body });
        onCompleted(response.body);
      } catch (err) {
        const message = err.body ? err.body.message : err.message;
        dispatch({ type: 'error', error: message });
        onError(message);
      }
    },
    [url, fetchOptions, onCompleted, onError],
  );

  return [
    fetchData,
    { data, loading, error },
    () => dispatch({ type: 'reset' }),
  ];
}

/* eslint-disable no-param-reassign */
export const hexToDec = (hexString) => {
  const decString = hexString.replace(/[^a-f0-9]/gi, '');
  return parseInt(decString, 16);
};

const fontColorContrast = (hexColorOrRedOrArray, green, blue) => {
  // Check if the color is hexadecimal (with hash)
  const hash = /#/;
  const isHex = hash.test(hexColorOrRedOrArray);
  const isRGB = !!(green !== undefined && blue !== undefined);
  const isArray = Array.isArray(hexColorOrRedOrArray);

  // Default is a bright color
  let fontColor = '#ffffff';
  let red = 0;

  if (isHex) {
    red = hexToDec(hexColorOrRedOrArray.substr(1, 2));
    green = hexToDec(hexColorOrRedOrArray.substr(3, 2));
    blue = hexToDec(hexColorOrRedOrArray.substr(5, 2));
  } else if (isRGB) {
    red = parseInt(hexColorOrRedOrArray, 10);
    green = parseInt(green, 10);
    blue = parseInt(blue, 10);
  } else if (isArray) {
    red = parseInt(hexColorOrRedOrArray[0], 10);
    green = parseInt(hexColorOrRedOrArray[1], 10);
    blue = parseInt(hexColorOrRedOrArray[2], 10);
  } else {
    // Not a color, respond with white color
    return fontColor;
  }

  const contrast = Math.sqrt(
    red * red * 0.241 + green * green * 0.691 + blue * blue * 0.068,
  );

  if (contrast > 130) {
    fontColor = '#24292e';
  }

  return fontColor;
};

export default fontColorContrast;

import { useCallback, useEffect, useRef, useState } from 'react';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import generator from 'generate-password';
import PropTypes from 'prop-types';
import {
  EyeClosedIcon,
  EyeIcon,
  ShieldCheckIcon,
  ShieldLockIcon,
  XIcon,
} from '@primer/octicons-react';
import { useLazyQuery, useMutation } from 'hooks';
import { useToasts } from 'react-toast-notifications';
import LabelPicker from 'components/LabelPicker';

export default function CredentialEditorDialog({
  open,
  onClose,
  credential,
  refetchCredentials,
}) {
  const { addToast } = useToasts();
  const passwordInput = useRef();
  const [label, setLabel] = useState(credential.label);
  const [login, setLogin] = useState(credential.login);
  const [password, setPassword] = useState('');
  const [isSecurePassword, setIsSecurePassword] = useState(false);
  const [previewPassword, setPreviewPassword] = useState(false);
  const [description, setDescription] = useState(
    credential.description ? credential.description : '',
  );
  const [url, setUrl] = useState(credential.url ? credential.url : '');

  useEffect(() => {
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{15,}$/.test(password)) {
      setIsSecurePassword(true);
    } else {
      setIsSecurePassword(false);
    }
  }, [password]);

  useEffect(() => {
    if (
      url.length > 0 &&
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
        url,
      ) &&
      !(url.includes('http://') || url.includes('https://'))
    ) {
      setUrl((prev) => `https://${prev}`);
    }
  }, [url]);

  const onPasswordLoaded = useCallback((res) => setPassword(res.password), []);

  const onError = useCallback(
    (err) => addToast(err, { appearance: 'error', autoDismiss: true }),
    [],
  );

  useEffect(() => {
    if (open) {
      getPassword();
    }
  }, [open]);

  const [getPassword] = useLazyQuery({
    url: `/credentials/${credential.id}`,
    onCompleted: onPasswordLoaded,
    onError,
  });

  const onUpdateCredential = useCallback(() => {
    addToast('Credencial actualizada', {
      appearance: 'success',
      autoDismiss: true,
    });
    onClose();
    refetchCredentials();
  }, []);

  const [updateCredential, { loading }] = useMutation({
    url: `/credentials/${credential.id}`,
    options: { method: 'PATCH' },
    onCompleted: onUpdateCredential,
    onError,
  });

  const generateSecurePassword = () => {
    const securePassword = generator.generate({
      length: 15,
      numbers: true,
      lowercase: true,
      uppercase: true,
      excludeSimilarCharacters: true,
      symbols: '!@#$%^&',
      strict: true,
    });

    setPassword(securePassword);

    if (passwordInput.current) {
      setTimeout(() => passwordInput.current.focus(), 200);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    updateCredential({
      label: label?.id,
      login,
      password,
      description: description.length > 0 ? description : undefined,
      url: url.length > 0 ? url : undefined,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div
          role="dialog"
          className="Box Box--overlay d-flex flex-column anim-fade-in fast"
          aria-modal="true"
          style={{ width: 'auto' }}
        >
          <div className="Box-header">
            <button
              type="button"
              className="Box-btn-octicon btn-octicon float-right"
              onClick={onClose}
              aria-label="Cerrar dialogo"
            >
              <XIcon />
            </button>
            <div className="Box-title">Editar credencial</div>
          </div>
          <div className="Box-body overflow-visible">
            <form
              className="px-3 color-bg-default border color-border-default rounded"
              onSubmit={handleFormSubmit}
            >
              <div className="d-flex flex-md-row flex-wrap flex-column flex-md-items-end flex-items-start py-2">
                <dl className="form-group col-md-4 col-12 pr-md-3 pr-0 my-2">
                  <dt className="d-flex flex-justify-between flex-items-center">
                    <label htmlFor="credential-login" className="f5">
                      Login
                    </label>
                  </dt>
                  <dd>
                    <input
                      type="text"
                      id="credential-login"
                      name="update-login"
                      className="form-control width-full"
                      placeholder="Usuario o email"
                      value={login}
                      onChange={({ target }) => setLogin(target.value)}
                      autoComplete="update-login"
                      spellCheck={false}
                      autoFocus
                      required
                    />
                  </dd>
                </dl>
                <dl className="form-group col-md-4 col-12 pr-md-3 pr-0 my-2">
                  <dt className="d-flex flex-justify-between flex-items-center">
                    <label htmlFor="credential-password" className="f5">
                      Contraseña
                    </label>
                  </dt>
                  <dd className="d-flex">
                    <div className="subnav-search-context">
                      <button
                        type="button"
                        className="btn tooltipped tooltipped-s"
                        aria-label={
                          isSecurePassword
                            ? 'La contraseña ya es segura'
                            : 'Generar contraseña segura'
                        }
                        onClick={generateSecurePassword}
                        disabled={isSecurePassword}
                      >
                        {isSecurePassword ? (
                          <ShieldCheckIcon />
                        ) : (
                          <ShieldLockIcon />
                        )}
                      </button>
                    </div>
                    <div className="subnav-search width-full">
                      <input
                        ref={passwordInput}
                        type={previewPassword ? 'text' : 'password'}
                        id="credential-password"
                        name="update-password"
                        className="form-control width-full subnav-search-input pl-3 tooltipped tooltipped-n"
                        style={{ paddingRight: 32 }}
                        aria-label={password}
                        placeholder="Contraseña"
                        value={password}
                        onChange={({ target }) => setPassword(target.value)}
                        autoComplete="update-password"
                        spellCheck={false}
                        required
                      />
                      <button
                        type="button"
                        className="btn-octicon color-fg-muted position-absolute tooltipped tooltipped-n"
                        style={{ right: 6, top: 3 }}
                        aria-label={
                          previewPassword
                            ? 'Esconder contraseña'
                            : 'Visualizar contraseña'
                        }
                        onClick={() => setPreviewPassword((prev) => !prev)}
                      >
                        {previewPassword ? <EyeClosedIcon /> : <EyeIcon />}
                      </button>
                    </div>
                  </dd>
                </dl>
                <dl className="form-group col-md-4 col-12 pr-0 my-2">
                  <dt className="d-flex flex-justify-between flex-items-center">
                    <label htmlFor="credential-url" className="f5">
                      URL <span className="note">(Opcional)</span>
                    </label>
                  </dt>
                  <dd>
                    <input
                      type="text"
                      id="credential-url"
                      name="update-url"
                      className="form-control width-full"
                      placeholder="Enlace a página web"
                      value={url}
                      onChange={({ target }) => setUrl(target.value)}
                      autoComplete="update-url"
                      spellCheck={false}
                    />
                  </dd>
                </dl>
                <dl className="form-group col-md-4 col-12 pr-md-3 pr-0 my-2">
                  <dt className="d-flex flex-justify-between flex-items-center">
                    <label htmlFor="credential-description" className="f5">
                      Etiqueta
                    </label>
                  </dt>
                  <dd>
                    <LabelPicker value={label} onChange={setLabel} />
                  </dd>
                </dl>
                <dl className="form-group col-md-8 col-12 pr-0 my-2">
                  <dt className="d-flex flex-justify-between flex-items-center">
                    <label htmlFor="credential-description" className="f5">
                      Descripción <span className="note">(Opcional)</span>
                    </label>
                  </dt>
                  <dd>
                    <input
                      type="text"
                      id="credential-description"
                      name="update-description"
                      className="form-control width-full"
                      placeholder="Breve descripción"
                      value={description}
                      onChange={({ target }) => setDescription(target.value)}
                      autoComplete="update-description"
                      spellCheck={false}
                    />
                  </dd>
                </dl>
              </div>
              <div className="form-actions border-top py-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Guardando…' : 'Guardar'}
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={onClose}
                  disabled={loading}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

CredentialEditorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  credential: PropTypes.object.isRequired,
  refetchCredentials: PropTypes.func.isRequired,
};

/* eslint-disable indent */
import { Link } from 'react-router-dom';
import { PeopleIcon } from '@primer/octicons-react';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import moment from 'moment';
import { UserAvatar } from 'components';

export default function TeamListItem({ team, loading }) {
  return (
    <div className="Box-row Box-row--focus-gray p-0 mt-0 selectable organization-list-item">
      <div className="d-flex Box-row--drag-hide position-relative">
        {!loading && (
          <div className="flex-shrink-0 pl-3 pt-2">
            <span className="tooltipped tooltipped-e" aria-label="Organización">
              <PeopleIcon />
            </span>
          </div>
        )}
        <div className="flex-auto min-width-0 lh-condensed p-2 pr-3 pr-md-2">
          {loading ? (
            <div>
              <Skeleton height={10} variant="rect" className="rounded-1 mb-2" />
            </div>
          ) : (
            <Link
              to={`/teams/${team.id}`}
              className="Link--primary v-align-middle no-underline h4"
            >
              {team.name}
            </Link>
          )}
          <div className="mt-1 text-small color-fg-muted">
            {loading ? (
              <Skeleton
                height={10}
                width="60%"
                variant="rect"
                className="rounded-1"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.11)' }}
              />
            ) : (
              <span>
                {team.description ? `${team.description} •` : ''} Creado por{' '}
                {team.author.name} {moment(team.createdAt).fromNow()}
              </span>
            )}
          </div>
        </div>
        <div className="flex-shrink-0 col-3 text-right pr-3 no-wrap d-flex hide-sm">
          {/* <span className="ml-2 flex-1 flex-shrink-0"></span> */}
          <span className="ml-2 flex-1 flex-shrink-0 d-flex flex-items-center">
            {!loading && (
              <div className="AvatarStack AvatarStack--three-plus d-flex flex-items-center">
                <div
                  className="AvatarStack-body tooltipped tooltipped-nw tooltipped-multiline"
                  aria-label={
                    team.members.length > 1
                      ? `${team.members
                          .slice(0, -1)
                          .map((member) => member.name)
                          .join(', ')} y ${
                          team.members[team.members.length - 1].name
                        }`
                      : team.members.map((member) => member.name).join(', ')
                  }
                >
                  {team.members.slice(0, 3).map((member) => (
                    <div key={member.id} className="avatar">
                      <UserAvatar
                        url={member.avatarURL}
                        username={member.name}
                        hideOnlineIndicator
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </span>
          <span className="ml-2 flex-1 flex-shrink-0 d-flex flex-items-center">
            {!loading && (
              <span
                className="tooltipped tooltipped-multiline tooltipped-nw"
                aria-label={
                  team.members.length > 1
                    ? `${team.members
                        .slice(0, -1)
                        .map((member) => member.name)
                        .join(', ')} y ${
                        team.members[team.members.length - 1].name
                      }`
                    : team.members.map((member) => member.name).join(', ')
                }
              >
                {team.members.length} miembro
                {team.members.length !== 1 ? 's' : ''}
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

TeamListItem.propTypes = {
  team: PropTypes.object,
  loading: PropTypes.bool,
};
